import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'

const Search = props => {
  const history = useHistory()

  const [query, setQuery] = useState('')
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    if (query.length) {
      return
    } else if (props.clearSearchQuery && query.length) {
      setQuery('')
    }
  }, [props, query])

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSetQuery = (e) => {
    setQuery(e.target.value)
  }


  const handleSearch = () => {
    props.setSearchQuery(query)
    if (props.location !== '/property') {
      props.setLocation({ pathname: '/property' })
      history.push('/property')
    }
    if (props.clearSearchQuery) {
      props.setClearSearchQuery(false)
    }
  }

  if (props.isMobile) {
    return (
      <li className={`mobile-nav-search 
      ${searching ? 'open' : ''} 
      search-container
      `}>
        <li className={'mobile-menubar mobile-search'}    onClick={() => setSearching(!searching)}>
          <i className='fa fa-search'
         
          ></i>
        </li>
        <div className={`mobile-search-event ${!searching && 'hidden'}`}>
          <input className='search-input-mobile'
            value={query}
            onChange={(e) => handleSetQuery(e)}
            onBlur={() => setSearching(false)}
            onFocus={() => setSearching(true)}
            onKeyPress={handleKeyPress}
            name="search"
            placeholder=''
            type="text" />
        </div>
      </li>
    )
  } else if (props.isMedium) {
    return (
     <li className={`nav-search ${searching ? 'open' : ''} search-container`}>
      <span className="fa fa-search"
        onClick={() => handleSearch()}
      ></span>
      <div className="search-event">
        <input className="search-input"
          value={query}
          onChange={(e) => handleSetQuery(e)}
          onBlur={() => setSearching(false)}
          onFocus={() => setSearching(true)}
          onKeyPress={handleKeyPress}
          name="search"
          placeholder={"Search"}
          type="text" />
      </div>
    </li>
    )
  } 
  
  else return (
    <li className={`nav-search ${searching ? 'open' : ''} search-container`}>
      <span className="fa fa-search"
        onClick={() => handleSearch()}
      ></span>
      <div className="search-event">
        <input className="search-input"
          value={query}
          onChange={(e) => handleSetQuery(e)}
          onBlur={() => setSearching(false)}
          onFocus={() => setSearching(true)}
          onKeyPress={handleKeyPress}
          name="search"
          placeholder={"Search for properties"}
          type="text" />
      </div>
    </li>
  )
}

export default Search