export const openEmail = (email, isServer, subject, body) => {
  if (!isServer) {
    window.location.href =
      "mailto:" +
      email +
      "?subject=" +
      subject +
      "&body=" +
      encodeURIComponent(body);
  }
};
