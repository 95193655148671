import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { isServer } from './isServer'
import { getData } from './api'
import { Helmet } from 'react-helmet'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

const Agent = props => {
  const { name } = useParams()
  const [agent, setAgent] = useState([])
  const [id, setAgentId] = useState(null)

  const [isSalesPerson, setIsSalesPerson] = useState(true)

  useEffect(() => {
    handleGetData()
  }, [])

  function handleGetData(params) {
    let url = `agents/name/${name}`
    getData(url, handleSetData)
  }

  const handleSetData = (data, count) => {
    if (data.jobTitle === 'Finance & Admin Manager') {
      setIsSalesPerson(false)
    } else if (data.jobTitle === 'Sales Support') {
      setIsSalesPerson(false)
    }

    setAgentId(data.id)
    setAgent(data)
  }

  const openEmail = () => {
    let subject = 'Contact request'
    if (!isServer) {
      window.location.href = "mailto:" + agent.email + "?subject=" + subject
    }
  }

  const openTel = (num) => {
    if (!isServer) {
      window.location.href = "tel:" + num;
    }
  }

  return (
    <div className="main-body">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content={`${name.replace('-', ' ')} | Kellands Real Estate`} />
        <title>{`${name.replace('-', ' ')} | Kellands Real Estate`}</title>
        {/* <meta property="og:description" content="" /> */}
        {/* TO DO - Use thumbnail? */}
        <meta property="og:image" content={`${cloudFrontURL}/users/${agent.id}/images/${agent.storageKey}`} /> 
      </Helmet>
      <div className="row agent-main">
        <div className="column agent-column">
          <div className="listings-container">
            <div className="agent-listing">
              <img className="agent-img agent-main-img"
                src={`${cloudFrontURL}/users/${agent.id}/images/${agent.storageKey}`}
                alt="" />
              <div className="agent-listing-details">
                <div className="agent-listing-name">
                  {agent.displayName}
                </div>
                <div className="agent-listing-numbers">
                  <div className="row">
                    <div className="agent-listing-number-header">
                      M
                    </div>
                    <div className="lighttext"
                      onClick={() => openTel(agent.mobile)}>
                      {agent.mobile}
                    </div>
                  </div>
                  <div className="row">
                    <div className="agent-listing-number-header">
                      P
                    </div>
                    <div className="lighttext"
                      onClick={() => openTel(agent.phone)}>
                      {agent.phone}
                    </div>
                  </div>
                </div>
                <div className="lighttext" onClick={openEmail}>
                  Email {agent.firstName}
                </div>
                {isSalesPerson
                  ? <div>
                    <Link to={`/property/agentslistings/${name}/sold`}>
                      <div className="lighttext lighttext-highlight">
                        View sold listings
                      </div>
                    </Link>
                    <Link to={`/property/agentslistings/${name}/live`}>
                      <div className="lighttext">
                        View current listings
                      </div>
                    </Link>
                  </div>
                  : <div />}
              </div>
            </div>
          </div>
        </div>
        <div className="agent-info">
          <h1 className="main-page-header">
            {agent.displayName}
            {!isSalesPerson
              ? ` - ${agent.jobTitle}`
              : ''}
          </h1>
          <div className="agent-main-info"
            dangerouslySetInnerHTML={{ __html: agent.description }}>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Agent