import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { isServer } from './isServer'
import { getData } from './api'
import WhatsOnListing from './WhatsOnListing.jsx'

import { formatDate, formatTimes, getFullDateStartTime } from './formatDateTimes'

const DatedListings = props => {
  const [type, setType] = useState('')
  const [data, setData] = useState([])

  useEffect(() => {
    if (!type) {
      if (!isServer) {
        setType(window.location.pathname.split(`/`).pop())
      }
    } else {
      handleGetData(type)
    }
  }, [type])

  function handleGetData() {
    let url = ''
    type === 'openhomes'
      ? url = 'properties?openhomesonly=true&status=live&limit=100'
      : url = 'properties?sale_method=auction&status=live&limit=100'
    getData(url, (d, count) => handleFormatData(d, count, type))
  }

  const handleFormatData = (data, count, type) => {
    let formatted = []
    data.map(listing => {
      if (type === 'auctions') {
        let auctionDateFormatted = formatDate(listing.auctionDate)
        listing.auctionTime = formatTimes(listing.auctionTime)
        listing.fullAuctionDateTime = getFullDateStartTime(listing.auctionTime, listing.auctionDate)
        if (!formatted.find(f => {
          return f.auctionDate === listing.auctionDate
        })) {
          let newAuction = {
            auctionDate: listing.auctionDate,
            date: auctionDateFormatted,
            id: listing.id,
            listings: [{ ...listing }]
          }
          formatted.push(newAuction)
        } else {
          let index = formatted.map((x) => {
            return x.auctionDate
          }).indexOf(listing.auctionDate)
          formatted[index].listings.push(listing)
        }

        formatted = formatted.sort((a, b) => {
          return new Date(a.auctionDate) - new Date(b.auctionDate)
        })

        // Sort listings by auction time
        formatted.forEach(day => {
          day.listings.sort((a, b) => {
            let aTime = new Date(a.fullAuctionDateTime);
            let bTime = new Date(b.fullAuctionDateTime);
            return aTime - bTime;
          })
        })

      } else if (type === 'openhomes') {
        listing.openhomes.forEach(op => {
          op.fullDateTime = getFullDateStartTime(op.startTime, op.dayOn)
          op.startTime = formatTimes(op.startTime)
          op.endTime = formatTimes(op.endTime)

          if (!formatted.find(f => {
            return f.dayOn === op.dayOn
          })) {
            let newOpenHome = {
              date: formatDate(op.dayOn),
              dayOn: op.dayOn,
              id: op.id,
              listings: [{
                ...listing,
              }]
            }
            formatted.push(newOpenHome)
          } else {
            let index = formatted.map((x) => {
              return x.dayOn
            }).indexOf(op.dayOn)
            formatted[index].listings.push(listing)
          }
        })

        formatted = formatted.sort((a, b) => {
          return new Date(a.dayOn) - new Date(b.dayOn)
        })

        // Sort listings by time
        formatted.forEach(day => {
          day.listings.sort((a, b) => {
            // Only get openhome for listing that matches the day
            let openHomeA = a.openhomes.filter(op => op.dayOn === day.dayOn)
            let openHomeB = b.openhomes.filter(op => op.dayOn === day.dayOn)

            // What if two open homes for the same property on the same day?
            // openHomeA.forEach?
            // Will sort by first/earliest time
            let aTime = new Date(openHomeA[0].fullDateTime);
            let bTime = new Date(openHomeB[0].fullDateTime);

            return aTime - bTime;
          })
        })
      }
      return true
    })

    setData(formatted)
  }

  return (
    <div className="main-body">
      <h1 className="main-page-header dated-header">
        {type === 'openhomes' ? 'OPEN HOMES' : 'UPCOMING AUCTIONS'}
      </h1>
      <div className="print-option-container">
        <i className="fa fa-print"
          style={{ color: '#ec008c' }}>
        </i>
        <Link className="print-text" to={{
          pathname: `/${type}/printview`,
          data: data
        }}>
          Print
        </Link>
      </div>
      <div className="main-text">
        <div className="dated-listings-container column fullwidth">
          {data.length ? data.map((dateItem, idx) => {
            return (
              <div key={idx} className="column fullwidth">
                <h1 className="main-page-dated-subheader">
                  {dateItem.date}
                </h1>
                <div className="row wrap">
                  {dateItem.listings.map(listing => {
                    return (
                      // Use standard link for property view page
                      // This page is loaded on the server
                      <a href={`/property/view/${listing.url}`}
                        key={listing.id} >
                        <WhatsOnListing
                          type={type.substring(0, type.length - 1)}
                          displayType={'large'}
                          key={listing.id}
                          property={listing}
                          date={dateItem.dayOn}
                        />
                      </a>
                    )
                  }
                  )}
                </div>
              </div>
            )
          })
            : <div />}
        </div>
      </div>
    </div >
  )

}

export default DatedListings