import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { getPageContent } from './api'

const Disclaimer = props => {
  const [disclaimerContent, setDisclaimerContent] = useState('')

  useEffect(() => {
    getDisclaimerContent()
  }, [])

  const getDisclaimerContent = () => {
    getPageContent('disclaimer', (data) => setDisclaimerContent(data[0].value))
  }

  return (
    <div className="main-body column">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content="Disclaimer | Kellands Real Estate"/>
        <title>Disclaimer | Kellands Real Estate</title>
      </Helmet>
      <h1 className="main-page-header">
        Disclaimer
      </h1>
      <div className="row">
        <div className="disclaimer-left">
          <p className="about-text"
            dangerouslySetInnerHTML={{ __html: disclaimerContent }}>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer