import request from "superagent";
import { isServer } from "./isServer";

// const baseUrl = 'http://127.0.0.1:1323';
const baseUrl = "https://api.kellands.co.nz";

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function login(creds, callback, error) {
  let cid = uuidv4();
  request
    .post(`${baseUrl}/auth/token`)
    .send({ grant_type: "password", ...creds })
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-Request-ID", cid)
    .then((res) => {
      switch (res.status) {
        case 200: {
          console.log("res", res, creds);
          localStorage.setItem("token", res.body.token);
          localStorage.setItem("user", creds.username);
          localStorage.setItem("expiresat", res.body.expiresAt);
          callback(res);
          break;
        }
        case 401: {
          logout();
          error && error(res.response.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err.response.body);
    });
}

export function logout() {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .delete(`${baseUrl}/v1/admin/logout`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-Request-ID", cid)
    .set("Authorization", `Bearer ${token}`)
    .then((res) => {
      switch (res.status) {
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
    });
  localStorage.removeItem("token");
  localStorage.removeItem("expiresat");
  localStorage.removeItem("user");

  if (!isServer) {
    window.location.reload();
  }
}

export function requestForgotPassword(username, callback, error) {
  let cid = uuidv4();
  request
    .get(`${baseUrl}/v1/users/forgotpassword/${username}`)
    // .send({grant_type: 'password', ...creds})
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-Request-ID", cid)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res);
          break;
        }
        case 202: {
          callback(res);
          break;
        }
        case 401: {
          logout();
          error && error(res.response.body);
          break;
        }
        case 400: {
          error && error(res.response.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err.response.body);
    });
}

export function requestResetPassword(creds, callback, error) {
  let cid = uuidv4();
  request
    .patch(`${baseUrl}/v1/users/resetpassword`)
    .send(creds)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-Request-ID", cid)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res);
          break;
        }
        case 401: {
          logout();
          error && error(res.response.body);
          break;
        }
        case 500: {
          error && error(res.response.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err.response.body);
    });
}

export function updatePassword(userId, creds, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .patch(`${baseUrl}/v1/admin/users/${userId}/password`)
    .send(creds)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-Request-ID", cid)
    .set("Authorization", `Bearer ${token}`)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res);
          break;
        }
        case 401: {
          logout();
          error && error(res.response.body);
          break;
        }
        case 500: {
          error && error(res.response.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err.response.body);
    });
}

export function getData(url, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .get(`${baseUrl}/v1/${url}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("Access-Control-Expose-Headers", "*")
    .set("X-Request-ID", cid)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res.body, res.header["x-total-count"]);
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        case 404: {
          error && error(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
      if (err.response && err.response.statusCode === 401) {
        logout();
      }
    });
}

export function getRedirectURI(webId, callback, error) {
  let cid = uuidv4();
  request
    .get(`${baseUrl}/v1/properties/external/${webId}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Access-Control-Expose-Headers", "*")
    .set("X-Request-ID", cid)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res.body.redirectURI);
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        case 404: {
          error && error(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function postData(url, data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");

  request
    .post(`${baseUrl}/v1/${url}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res.body);
          break;
        }
        case 201: {
          callback(res.body);
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function patchData(url, data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .patch(`${baseUrl}/v1/${url}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body);
          break;
        }
        case 201: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

// Activate / Deactive user
export function patchUserStatus(id, status, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .patch(`${baseUrl}/v1/admin/users/${id}/${status}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    // .send(data)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body);
          break;
        }
        case 201: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function patchPropertyStatus(id, status, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .patch(`${baseUrl}/v1/admin/properties/${id}/statuses/${status}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    // .send(data)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body);
          break;
        }
        case 201: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function patchPropertyFeatured(id, featured, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .patch(`${baseUrl}/v1/admin/properties/${id}/featured/${featured}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body);
          break;
        }
        case 201: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function updatePropertiesData(url, data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .patch(`${baseUrl}/v1/admin/properties/${url}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body);
          break;
        }
        case 201: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function createPropertiesData(url, data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .post(`${baseUrl}/v1/admin/properties/${url}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body);
          break;
        }
        case 201: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function updateUserImage(url, data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .patch(`${baseUrl}/v1/admin/users/${url}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body);
          break;
        }
        case 201: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function deletePropertiesData(url, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .delete(`${baseUrl}/v1/admin/properties/${url}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("Access-Control-Allow-Origin", "*")
    .set("X-Request-ID", cid)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body);
          break;
        }
        case 201: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function getPreSignedUrl(data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .post(`${baseUrl}/v1/admin/pre_signed_url`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Access-Control-Allow-Origin", "*")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 500: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function uploadImg(image, imageType, preSignedUrl, callback, error) {
  fetch(preSignedUrl, {
    headers: {
      "Content-Type": `image/${imageType}`,
      "Access-Control-Allow-Origin": "*",
    },
    method: "PUT",
    body: image,
  })
    .then((res) => {
      switch (res.status) {
        case 403: {
          error && error();
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 200: {
          callback();
          break;
        }
        default:
          return false;
      }
    })
    .catch((e) => {
      error && error(e);
    });
}

export function uploadAttachment(
  attachment,
  attachmentType,
  preSignedUrl,
  callback,
  error
) {
  fetch(preSignedUrl, {
    headers: {
      "Content-Type": attachmentType,
      // TO DO - get correct content type
      "Access-Control-Allow-Origin": "*",
    },
    method: "PUT",
    body: attachment,
  })
    .then((res) => {
      switch (res.status) {
        case 403: {
          error && error();
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 200: {
          callback(res);
          break;
        }
        default:
          return false;
      }
    })
    .catch((e) => {
      error && error(e);
    });
}

export function search(type, isAdmin, query, props, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .get(
      `${baseUrl}/v1/${isAdmin ? "admin/" : ""}${type}?query=${query}${props}`
    )
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    // .send(data)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res.body, res.header["x-total-count"]);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      error && error(null);
      err = JSON.parse(JSON.stringify(err));
      // error && error(err)
    });
}

export function contactFormSubmit(data, callback, error) {
  let cid = uuidv4();
  request
    .post(`${baseUrl}/v1/feedback`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body, res.header["x-total-count"]);
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        case 422: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function getPageContent(where, callback, error) {
  let cid = uuidv4();
  request
    .get(`${baseUrl}/v1/contents/${where}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-Request-ID", cid)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res.body);
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        case 422: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function updatePageContent(id, data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .patch(`${baseUrl}/v1/admin/contents/${id}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res.body);
          break;
        }
        case 204: {
          callback(res.body);
          break;
        }
        case 403: {
          error && error(res.reponse.body);
          break;
        }
        case 422: {
          error && error(res.response.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err.response.body);
    });
}

export function sendEmail(data, emailWhere, callback, error) {
  let cid = uuidv4();
  request
    .post(`${baseUrl}/v1/${emailWhere}`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 204: {
          callback(res.body);
          break;
        }
        case 429: {
          callback(res.body);
          break;
        }
        case 403: {
          error && error(res.body);
          break;
        }
        case 422: {
          error && error(res.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err);
    });
}

export function updateAgentPositions(data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem("token");
  request
    .patch(`${baseUrl}/v1/admin/users/listing/positions`)
    .set("Accept", "application/json")
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .set("X-Request-ID", cid)
    .send(data)
    .then((res) => {
      switch (res.status) {
        case 200: {
          callback(res.body);
          break;
        }
        case 204: {
          callback(res.body);
          break;
        }
        case 401: {
          logout();
          break;
        }
        case 403: {
          error && error(res.reponse.body);
          break;
        }
        case 422: {
          error && error(res.response.body);
          break;
        }
        default:
          return false;
      }
    })
    .catch((err) => {
      err = JSON.parse(JSON.stringify(err));
      error && error(err.response.body);
    });
}
