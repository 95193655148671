import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { getData } from '../api.js'
import navOptions from './navOptions.js'

import OrderAgents from './OrderAgents.jsx'
import PagePagination from './PagePagination.jsx'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'
// const cloudFrontURL = 'https://kellands-website-assets-dev.s3.ap-southeast-2.amazonaws.com'


const Users = props => {
  const [data, setData] = useState([])
  const [isSearchData, setIsSearchData] = useState(false)
  const [limit, setLimit] = useState(12)
  const [offset, setOffset] = useState(0)
  const [dataCount, setDataCount] = useState(0)

  const [isOrdering, setIsOrdering] = useState(false)

  useEffect(() => {
    if (props.searchData) {
      setData(props.searchData)
      setDataCount(props.searchData.length)
      setIsSearchData(true)
    } else {
      isSearchData && setIsSearchData(false)
      requestDataWithFilters()
    }
  }, [offset, limit, props.searchData, isSearchData])

  const requestDataWithFilters = () => {
    if (props.dataFilter !== 'all') {
      let navInfo = navOptions.find(option => option.name === 'users');
      let filtered = navInfo.subMenus.find(subMenu => subMenu.aka === props.dataFilter)
      handleGetData(filtered.handler, filtered.aka)
    } else {
      handleGetData('status', 'active')
    }
  }

  function handleGetData(handler, spec) {
    let url = `admin/users?`
    // spec === 'agent' && (url = `admin/agents?`)
    if (handler) url += `${handler}=${spec}`;
    url += '&limit=' + limit + '&offset=' + offset
    if (spec.includes('active')) {
      url += '&role=agent'
    }
    getData(url, handleSetData)
  }

  const handleSetData = (data, count) => {
    setDataCount(count)
    setData(data)
  }

  const handleOrderSalespeople = () => {
    setIsOrdering(true)
    getData(`admin/users?&limit=${dataCount}`, (d) => setData(d))
  }

  return (
    isOrdering
      ? <OrderAgents data={data} setIsOrdering={(ordering) => setIsOrdering(ordering)} />
      : <div className="kpod-container">
        <h1 className="kpod-main-title">
          {/* {`${filter !== 'agent' ? filter + ' Salespeople' : 'Users'}`} */}
          {props.subPage ? props.subPage : 'Active Salespeople'}
        </h1>
        <div className="kpod-items-banner row">
          {!isSearchData
            ? <div className="sm-text">Showing {data.length} of {dataCount} Salespeople... </div>
            : <div className="sm-text">Showing {data.length} results for {props.searchTerm}</div>
          }
          <div className="kpod-buttons">
            {/* TO DO CHANGE PER subPages */}
            {!isSearchData &&
              <button className="kpod-button"
                onClick={() => handleOrderSalespeople(true)}>
                {props.subPage === "k-pod admin users" ? "Order Users" : "Order Salespeople"}
            </button>}
            <Link to='/account/users/edit'>
              <button className="kpod-button"> {props.subPage === "k-pod admin users" ? "Add User" : "Add Salespeople"} </button>
            </Link>
          </div>
        </div>
        <div className="kpod-items-container">
          {data.length ? data.map((user, i) => {
            return (
              <Link key={i} className="kpod-item row" to={`/account/users/edit/${user.id}`}>
                <div className="kpod-users-l">
                  <div className={`whatson-listing-img-container kpod-item-img kpod-user-img
                  ${props.displayType === 'small' && 'greyscale-img'}`}>
                    <img src={
                      `${cloudFrontURL}/users/${user.id}/images/${user.storageKey}`}
                      onError={(e) => { e.target.src = '/images/image-default-agent.png' }}
                      alt=""
                      className="kpod-item-img">
                    </img>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="none" className="image-corner">
                      <polygon points="100,100 100,0 0,100"></polygon>
                    </svg>
                    <span className="kpod-user-status">
                      {user.status}
                    </span>
                  </div>
                  {/* <div className={`kpod-statusbanner status-${user.status.replace(/\s/g, '')}`}>
                    {user.status}
                  </div> */}
                </div>
                <div className="kpod-users-r">
                  <div className="row">
                    <div className="kpod-item-title">
                      {user.displayName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="kpod-item-heading">Phone M: </div>
                    <div className="kpod-item-heading">{user.mobile}</div>
                  </div>
                  <div className="row">
                    <div className="kpod-item-heading">Phone DDI: </div>
                    <div className="kpod-item-heading">{user.phone}</div>
                  </div>
                  <div className="row">
                    <div className="kpod-item-heading">Email: </div>
                    <div className="kpod-item-heading">{user.email}</div>
                  </div>
                  <div className="row kpod-light-text">
                    <div className="kpod-item-headingsm">Job Title: </div>
                    <div className="kpod-item-heading">{user.jobTitle}</div>
                  </div>
                </div>
              </Link>
            )
          })
            : <div />}
        </div >
        {data.length && dataCount > 12 ?
          <PagePagination
            limit={limit}
            setLimit={(rpp) => setLimit(rpp)}
            offset={offset}
            setOffset={(startIdx) => setOffset(startIdx)}
            count={dataCount}
          />
          : <div />
        }
      </div >
  )
}

export default Users