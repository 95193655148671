const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const formatDate = (date, isTender = false) => {
    // date as YYYY-MM-DDT00:00:00Z

    let dateFormat = new Date(date)
    // Methods on Date Object will covert from UTC to users timezone
    // Set minutes to current minutes (UTC) + User local time UTC offset
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset())

    let dayStrPos = dateFormat.getDay() // Returns number
    let monthStrPos = dateFormat.getMonth() // Returns number
    let dayNum = dateFormat.getDate()

    let dayStr = '';
    let monthStr = months[monthStrPos]

    if (isTender) {
      dayNum = getNumberWithOrdinal(dayNum)
    } else {
      dayStr = weekdays[dayStrPos]
    }
    
    let dateStr = `${dayStr} ${dayNum} ${monthStr}`
    return dateStr
  }


  export const formatTimes = (time) => {
    // Time as HH:MM:SS
    // Format to HH:MM + AM/PM
    if ((time.includes('am') || time.includes('pm'))) {
     return time 
    } else {
     let timeParts = time.split(':');
     let amPm = ''

     // If pm
      if ((timeParts[0] - 12) >= 0) {
     // Get 12 hour time
        timeParts[0] -= 12
        amPm = 'pm'
      } else {
        amPm = 'am'
      } 

      // Fix for 12am or 12pm
      if (timeParts[0] === -12 || timeParts[0] === 0) {
        timeParts[0] = 12
      }

     let newTime = `${timeParts[0]}:${timeParts[1]}${amPm}`
     return newTime
    }
  }

  function getNumberWithOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  export const getFullDateStartTime = (startTime, date) => {
    // startTime = e.g; 12:00pm or 12:00:00
    // date = e.g; 2021-05-30T00:00:00Z

    // For sorting by date and time for openhomes and auctions
    // Takes auction / openhome date and start time, and converts to date string

    // sometimes e.g; 12:00 PM format, fix
    startTime = startTime.toLowerCase().replace(/\s+/g, '')
    let formattedStartTime = startTime
    let timeParts = formattedStartTime.split(":")
    let hours = timeParts[0]
    let minutes = timeParts[1]

    if (minutes.includes('pm')) {
      hours = (Number(hours) !== 12) ? Number(hours) + 12 : Number(hours)
      minutes = minutes.slice(0, -2) + ":00"
      formattedStartTime = hours + ":" + minutes
    } else if (minutes.includes('am')) {
      minutes = minutes.slice(0, -2) + ":00"
      formattedStartTime = hours + ":" + minutes
    } 

    if (formattedStartTime.length < 8) {
      formattedStartTime = "0" + formattedStartTime
    }

    let formattedDate = date
    formattedDate = formattedDate.split("T")
    // Returns formatted date string e.g; 2021-05-26T12:00:00Z
    return formattedDate[0] + "T" + formattedStartTime + "Z"
  }
