import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const tinymceKey = 'zu6ezef8y21gskc5v3q26mgawcya6m0obil0aeihlpc3fqkb'

const MCETextEditor = props => {

  const handleEditorChange = (content, editor) => {
    props.setDescription(content)
  }

  return (
    <Editor
      apiKey={tinymceKey}
      initialValue={props.description}
      value={props.description}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic underline | \
            bullist numlist | link | removeformat | help '
      }}
      onEditorChange={handleEditorChange}
    />
  );
}

export default MCETextEditor;
