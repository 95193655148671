import React, { useState, useEffect } from 'react'

import { login, requestForgotPassword, requestResetPassword } from '../api.js'
import { useHistory } from 'react-router'
import { Alert, AlertTitle } from '@material-ui/lab'
import IconButton from '@material-ui/core/IconButton';



const Login = props => {
  const [password, setPsw] = useState('')
  const [verifyPassword, setVerifyPsw] = useState('')
  const [username, setUser] = useState('')

  const [forgotPassword, setPasswordForgotten] = useState(false)
  const [forgotPasswordSuccess, setPasswordForgottenSuccess] = useState(false)

  const [error, setError] = useState(false)

  const [resetToken, setResetToken] = useState('')
  const [resetPasswordSuccess, setPasswordResetSuccess] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (history.location.search.includes('token')) {
      let token = history.location.search.split('=').pop()
      setResetToken(token)
    }
    if (props.loggedIn) {
      history.push('/account')
      props.setPage('home')
    }
  }, [props, history])

  useEffect(() => {
    if (forgotPassword && error) {
      setError(false)
    }
    if (forgotPasswordSuccess && error) {
      setError(false)
    }
    if (resetPasswordSuccess && error) {
      setError(false)
    }
  }, [forgotPassword, forgotPasswordSuccess, resetPasswordSuccess, error])

  const requestLogin = (() => {
    login({ username, password }, handleLogin, handleErr)
  })

  const handleLogin = ((res) => {
    if (res.status === 200) {
      props.setHasToken(true)
      props.setLogin(true)
      history.push('/account')
      props.setPage('home')
    }
  })

  const handleErr = ((err) => {
    setError(err)
  })

  const handleForgottenPassword = () => {
    requestForgotPassword(username,
      (r) => setPasswordForgottenSuccess(true),
      passwordForgottenError)
  }

  const passwordForgottenError = (error) => {
    setPasswordForgottenSuccess(false)
    setError(error)
  }

  const handleResetPassword = () => {
    if (password === verifyPassword) {
      let creds = {
        resetToken: resetToken,
        username: username,
        password: password
      }
      requestResetPassword(creds,
        (r) => setPasswordResetSuccess(true),
        passwordResetError)
    } else {
      setError({
        error: '',
        error_description: 'Passwords do not match'
      })
    }
  }

  const passwordResetError = (error) => {
    setPasswordResetSuccess(false)
    setError(error)
  }

  const resetState = () => {
    setPsw('')
    setVerifyPsw('')
    setUser('')

    setPasswordForgotten(false)
    setPasswordForgottenSuccess(false)

    setError(false)

    setResetToken('')
    setPasswordResetSuccess(false)
  }


  if (resetToken.length) {
    return (
      <div className="login-container">
        <div className="login-info">
          Reset password
        </div>
        {!resetPasswordSuccess ?
          <div>
            <div className="login-input-container">
              Username:
              <input className="kpod-form-input"
                type="text"
                placeholder="Username"
                onChange={e => setUser(e.target.value)}>
              </input>
            </div>
            <div className="login-input-container">
              New Password:
              <input className="kpod-form-input"
                type="password"
                placeholder="Password"
                onChange={e => setPsw(e.target.value)}>
              </input>
            </div>
            <div className="login-input-container">
              Verify New Password:
              <input className="kpod-form-input"
                type="password"
                placeholder="Verify password"
                onChange={e => setVerifyPsw(e.target.value)}>
              </input>
            </div>
            <div className="login-alert-container">
              {error && error.error_description ?
                <Alert severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small">
                    </IconButton >
                  }>
                  <AlertTitle className="alert-title">
                    {error.error_description}
                  </AlertTitle>
                </Alert >
                : <div />}
            </div>
            <div className="login-button-container row">
              <button className="kpod-button" onClick={handleResetPassword}>
                Reset Password
          </button>
            </div>
          </div>
          : <div className="login-container">
            <div className="login-response-text">
              Your password has successfully been updated
          </div>
            <div className="column">
              <button className="forgot-psw" onClick={resetState}>
                Return to login
              </button>
            </div>
          </div >}
      </div>
    )
  } else if (!forgotPassword) {
    return (
      <div className="login-container">
        <div className="login-info">
          Enter your Username and Password
        </div>
        <div className="login-input-container">
          Username:
          <input className="kpod-form-input"
            type="text"
            placeholder="Username"
            onChange={e => setUser(e.target.value)}>
          </input>
        </div>
        <div className="login-input-container">
          Password:
          <input className="kpod-form-input"
            type="password"
            placeholder="Password"
            onChange={e => setPsw(e.target.value)}>
          </input>
        </div>
        <div className="login-alert-container">
          {error && error.error_description ?
            <Alert severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small">
                </IconButton >
              }>
              <AlertTitle className="alert-title">
                {error.error_description}
              </AlertTitle>
            </Alert >
            : <div />}
        </div>
        <div className="login-button-container row">
          <button className="forgot-psw" onClick={() => setPasswordForgotten(true)}>
            Forgot Password?
            </button>
          <button className="kpod-button" onClick={requestLogin}>
            Login
          </button>
        </div>
      </div >
    )
  } else {
    return (
      <div className="login-container">
        <div className="login-info">
          Recover your account
        </div>
        {!forgotPasswordSuccess
          ? <div>
            <div className="login-input-container">
              Username:
            <input className="kpod-form-input"
                type="text"
                placeholder="Username"
                onChange={e => setUser(e.target.value)}>
              </input>
            </div>
            <div className="login-alert-container">
              {error && error.error_description ?
                <Alert severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small">
                    </IconButton >
                  }>
                  <AlertTitle className="alert-title">
                    {error.error_description}
                  </AlertTitle>
                </Alert >
                : <div />}
            </div>
            <div className="login-button-container">
              <button className="kpod-button" onClick={() => setPasswordForgotten(false)}>
                Cancel
          </button>
              <button className="kpod-button" onClick={handleForgottenPassword}>
                Next
          </button>
            </div>
          </div>
          : <div className="login-container">
            <div className="login-response-text">
              A recovery email has been sent
            </div>
            <div className="column">
              {/* <button className="forgot-psw" onClick={handleForgottenPassword}>
                Haven't received anything? Request another email
              </button> */}
              <button className="forgot-psw" onClick={() => setPasswordForgotten(false)}>
                Return to login
            </button>
            </div>
          </div >}
      </div>
    )
  }

}

export default Login