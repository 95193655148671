import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { createBrowserHistory } from 'history'
import { getPageContent } from './api.js'

import { getData, search } from './api.js'
import Listing from './Listing.jsx'
import PagePagination from './PagePagination.jsx'
import { isServer } from './isServer.js'
import { property } from 'lodash'


const Rentals = props => {
  const history = useHistory()
  const { id, name, status, propertyType } = useParams()
  const [data, setData] = useState([])
  const [dataCount, setDataCount] = useState(0)
  const [limit, setLimit] = useState(4)
  const [offset, setOffset] = useState(0)
  const [rentedData, setRentedData] = useState([])
  const [rentedDataCount, setRentedDataCount] = useState(0)
  const [rentedLimit, setRentedLimit] = useState(4)
  const [rentedOffset, setRentedOffset] = useState(0)
  const [metaDesc, setMetaDesc] = useState('')
  const [isMobile, setIsMobile] = useState(!isServer ? window.innerWidth <= 800 : false)


  const [rentalContent, setRentalsContent] = useState('')

  useEffect(() => {
    getRentalsContent()
  }, [])

  const getRentalsContent = () => {
    getPageContent('rentals', (data) => setRentalsContent(data[0].value))
  }

  const handleResize = () => {
    if (!isServer) {
      if (window.innerWidth <= 800) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
  }

  useEffect(() => {
    if (!isServer) {
      window.addEventListener('resize', handleResize)
    }

    let desc = 'Looking to rent property? Houses, Apartments and Townhouses for rent in Auckland & Waiheke Island.'
              handleGetData("live", limit, offset)
              handleGetData("rented,leased", rentedLimit, rentedOffset)

        setMetaDesc(desc)
  }, [offset, limit, rentedLimit, rentedOffset, props.searchQuery, propertyType])

  function handleGetData(type, limit, offset) {
    let url = `rental_properties?limit=${limit}&offset=${offset}&status=${type}`
    
    if (type === 'rented,leased') {
      url += `&order_by=sold_on&sort=desc`
      getData(url, handleSetDataRented)
    } else {
      url += `&order_by=listed_date&sort=desc`
      getData(url, handleSetData)
    }
  }


  const handleSetData = (data, count) => {
    setDataCount(count)
    setData(data)
  }

  const handleSetDataRented = (data, count) => {
    setRentedDataCount(count)
    setRentedData(data)
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  let headerTitle = "Properties For Rent"

  return (
    <div className="main-body">
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:title" 
          content={`${capitalizeFirstLetter(headerTitle)} | 'Kellands Real Estate'}`}
          />
          <title>
          {`${capitalizeFirstLetter(headerTitle)} 
          | 'Kellands Real Estate'}`}
          </title>
          <meta name="description" content={`${metaDesc}`} />
          <meta property="og:description" content={`${metaDesc}`} />
        </Helmet>
      <div className="column">
        {data.length ?
        <h1 className="main-page-header">
          {headerTitle}
        </h1> : <div></div>}
       <div />
        <div className="listings-container">
          {data.length ? data.map((property, idx) => {
            return (
              // Use standard link for property view page
              // This page is loaded on the server
              <a href={`/property/view/${property.url}`}
              className={`listing-container ${isMobile && 'listing-container-mobile'}`}
              key={property.id}>
                <Listing property={property} idx={idx} />
              </a>
            )
          })
            : <div />}
        </div>
      </div>
      {dataCount > 4 ?
        <PagePagination
          itemsPerPage={limit}
          limit={limit}
          setLimit={(rpp) => setLimit(rpp)}
          offset={offset}
          setOffset={(startIdx) => setOffset(startIdx)}
          count={dataCount}
        />
        : <div />
      }
            <div className="column">
              {rentedData.length ?
        <h1 className="main-page-header">
          Rented
        </h1> : <div></div> }
       <div />
        <div className="listings-container">
          {rentedData.length ? rentedData.map((property, idx) => {
            return (
              // Use standard link for property view page
              // This page is loaded on the server
              <a href={`/property/view/${property.url}`}
              className={`listing-container ${isMobile && 'listing-container-mobile'}`}
              key={property.id}>
                <Listing property={property} idx={idx} />
              </a>
            )
          })
            : <div />}
        </div>
      </div>
      {rentedDataCount > 4 ?
        <PagePagination
          itemsPerPage={rentedLimit}
          limit={rentedLimit}
          setLimit={(rpp) => setRentedLimit(rpp)}
          offset={rentedOffset}
          setOffset={(startIdx) => setRentedOffset(startIdx)}
          count={rentedDataCount}
        />
        : <div />
      }
       <div className="rentals-text-row">
           <div className="rentals-sub">
            <h1>Property Management</h1>
            <div className="rentals-p-container">
                <p className="rentals-multi-line rentals-p">You're not just another number, get the attention you deserve.</p>
                <p className="rentals-one-line rentals-p">You're not just another number, get the attention you deserve.</p>
            </div>
        </div>
        <div className="rentals-text">
           <div className="rentals-text-inner">
          <p className="about-text"
            dangerouslySetInnerHTML={{ __html: rentalContent }}>
          </p>
        </div>
        </div>
      </div>
    </div >
  )

}

export default Rentals