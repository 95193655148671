import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { useLocation } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ReactGA from "react-ga";

import Home from "./Home.jsx";
import Header from "./Header.jsx";
import Footer from "./Footer.jsx";
import AboutTeam from "./AboutTeam.jsx";
import Agent from "./Agent.jsx";
import Listings from "./Listings.jsx";
import DatedListings from "./DatedListings.jsx";

import Property from "./Property.jsx";
import Disclaimer from "./Disclaimer.jsx";
import ContactUs from "./ContactUs.jsx";
import EmailTo from "./EmailTo.jsx";
import REA from "./REA.jsx";
import Rentals from "./Rentals.jsx";
import Privacy from "./Privacy.jsx";
import Promotion from "./Promotions.jsx";

import PropertyPrintView from "./PropertyPrintView.jsx";
import ListingsPrintView from "./ListingsPrintView.jsx";
import WebIdRedirect from "./WebIdRedirect.jsx";
import AgentQueryListings from "./AgentQueryListings.jsx";

import Main from "./KPod/Main.jsx";

import { isServer } from "./isServer";
import { getPageContent } from "./api";

import "./css/App.css";
import "./css/App-1000-min.css";
import "./css/App-800-min.css";
import "./css/App-500-min.css";
import "./css/App-375-min.css";
import "./css/App-340-min.css";

const App = (props) => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [clearSearchQuery, setClearSearchQuery] = useState(true);

  const trackingId = "UA-49592332-1"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  !isServer && ReactGA.ga("set", "forceSSL", true);

  // ReactGA.set({
  //   // userId: auth.currentUserId(),
  //   // any data that is relevant to the user session
  //   // that you would like to track with google analytics
  // })

  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, [location]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div key='app-main-key' className='App'>
        <Switch>
          <Route exact path='/account/*' component={Main} />
          <Route exact path='/account' component={Main} />
          <Fragment>
            <div
              key='app-secondary'
              id={
                location.pathname.includes("printview") ? "printwrap" : "wrap"
              }
              className='mainwrap'
            >
              {/* <Fragment> */}
              <Route
                path='/*'
                render={() => (
                  <Header
                    setSearchQuery={setSearchQuery}
                    clearSearchQuery={clearSearchQuery}
                    setClearSearchQuery={setClearSearchQuery}
                  />
                )}
              />
              <Route exact path='/' component={Home} />
              <Route exact path='/:webId' component={WebIdRedirect} />
              <Route
                path='/property/categoryview/:propertyType'
                render={() => <Listings searchQuery={searchQuery} />}
              />
              <Route path='/property/view/:str' component={Property} />
              <Route
                path='/property/email/:type/:propertyUrl'
                exact
                component={EmailTo}
              />
              <Route
                path='/property/email/:type/:agentName/:agentEmail/:propertyId/:propertyUrl'
                component={EmailTo}
              />
              {/* Agents old urls fix */}
              <Route
                path='/property/agentslistings'
                render={() => <AgentQueryListings searchQuery={searchQuery} />}
              />
              <Route
                exact
                path='/property/agentslistings/:name/:status'
                render={() => <Listings searchQuery={searchQuery} />}
              />
              <Route
                path='/property/sold'
                render={() => <Listings searchQuery={searchQuery} />}
              />
              {/* // component={Listings} /> */}
              <Route
                exact
                path='/property'
                render={() => <Listings searchQuery={searchQuery} />}
              />
              <Route exact path='/openhomes' component={DatedListings} />
              <Route exact path='/auctions' component={DatedListings} />
              <Route exact path='/contact' component={ContactUs} />
              <Route exact path='/disclaimer' component={Disclaimer} />
              <Route exact path='/privacy' component={Privacy} />
              <Route exact path='/rea' component={REA} />
              <Route exact path='/rentals' component={Rentals} />
              <Route exact path='/promotion' component={Promotion} />
              <Route path='/ourpeople/staff/:name' component={Agent} />
              <Route exact path='/ourpeople' component={AboutTeam} />
              <Route path='/*' component={Footer} />
              {/* </Fragment> */}
              <Route
                exact
                path='/property/printview/:url'
                component={PropertyPrintView}
              />
              <Route
                exact
                path='/:type/printview'
                component={ListingsPrintView}
              />
            </div>
          </Fragment>
        </Switch>
      </div>
    </DndProvider>
  );
};

export default App;
