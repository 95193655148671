import React, { useState, useEffect } from 'react'
import { Slide } from 'react-slideshow-image'
import { Link } from 'react-router-dom'

import { isServer } from './isServer'

const Slideshow = props => {
  const [isMobile, setIsMobile] = useState(!isServer ? window.innerWidth <= 800 : false)

  const [data, setData] = useState({
    bedrooms: 0,
    bathrooms: 0,
    address: {},
    images: [],
    garages: 0,
    offstreetParking: 0,
    mainHeading: ""
  })

  const handleResize = () => {
    if (!isServer) {
      if (window.innerWidth <= 800) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
  }

  useEffect(() => {
    props.data.images && setData(props.data)
  }, [props.data])


  const properties = {
    duration: 3500,
    autoplay: true,
    transitionDuration: 500,
    arrows: true,
    infinite: true,
    easing: "ease",
    indicators: () => <div className="slide-indicator"></div>
  }

  return (
    <div className="slide-container">
      <Slide {...properties}>
        {data.images.map((image, i) => {
          return (
            <div key={i} className="each-slide">
              <a href={`/property/view/${data.url}`} className={props.propertyPage ? 'link-disabled' : ''}>
                {data.status === 'sold' || data.status === 'rented' || data.status === 'leased' ?
                  <div className="">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="none"
                      className="image-triangle triangle-lg">
                      <polygon points="0,0 100,0 50,100"></polygon>
                    </svg>
                    <span className={data.status === 'sold' ? "property-sold-lg" : 'property-sold-lg triangle-rental'}>{data.status === 'sold' ? "Sold" : "Rented"}</span>
                    <img className="slide-img" src={image} alt="" />
                  </div>
                  :
                  <img className="slide-img" src={image} alt="" />
                }
                <div className="slide-text-container">
                  {!props.propertyPage &&
                    <div className="slide-textlg">
                      {data.mainHeading}
                    </div>
                  }
                  {!props.propertyPage &&
                    <div className="slide-textsm">
                      {data.address.suburb}
                    </div>
                  }
                  {!props.propertyPage && data.showAddress &&
                    <div className="slide-textsm">
                      {data.address.subNumber ? `${data.address.subNumber}/` : ''}
                      {`${data.address.streetNumber} `}
                      {data.address.street}
                    </div>
                  }
                  {!props.propertyPage &&
                    <div className="slide-feature-container">
                      {data.bedrooms ?
                        <div className="slide-feature">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve" className="feature-container">
                            <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5" className="feature-border"></polygon>
                          </svg>
                          <div className="slide-feature-textlg">
                            {data.bedrooms}
                          </div>
                          <div className="slide-feature-textsm">
                            {isMobile
                              ? <div className="property-feature-icon">
                                <i className="fa fa-bed" />
                              </div>
                              : `Bedroom${data.bedrooms > 1 ? 's' : ''}`
                            }
                          </div>
                        </div> : <div></div>
                      }
                      {data.bathrooms ?
                        <div className="slide-feature">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve" className="feature-container">
                            <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5" className="feature-border"></polygon>
                          </svg>
                          <div className="slide-feature-textlg">
                            {data.bathrooms}
                          </div>
                          <div className="slide-feature-textsm">
                            {isMobile
                              ? <div className="property-feature-icon">
                                <img style={{ width: '14px' }} src="/images/bathroom.png" alt="bathroom" />
                              </div>
                              : `Bathroom${data.bathrooms > 1 ? 's' : ''}`
                            }
                          </div>
                        </div> : <div></div>
                      }
                      {data.garages ?
                        <div className="slide-feature">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve" className="feature-container">
                            <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5" className="feature-border"></polygon>
                          </svg>
                          <div className="slide-feature-textlg">
                            {data.garages}
                          </div>
                          <div className="slide-feature-textsm">
                            {isMobile
                              ? <div className="property-feature-icon">
                                <img src="/images/garage-parking.svg"
                                  style={{ height: '16px' }}
                                  alt="garage parking" />
                              </div>
                              : `Garage${data.garages > 1 ? 's' : ''}`}
                          </div>
                        </div> : <div></div>
                      }
                      {data.offstreetParking ?
                        <div className="slide-feature">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve" className="feature-container">
                            <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5" className="feature-border"></polygon>
                          </svg>
                          <div className="slide-feature-textlg">
                            {data.offstreetParking}
                          </div>
                          <div className="slide-feature-textsm">
                            {isMobile
                              ? <div className="property-feature-icon">
                                <i className="fa fa-car" />
                              </div>
                              : 'Offstreet'
                            }
                          </div>
                        </div> : <div></div>
                      }
                    </div>
                  }
                </div>
              </a>
            </div>
          )
        })}
      </Slide>
      {props.propertyPage ?
        <div className="slideshow-info-container column">
          <div className="property-header">
            {data.mainHeading}
          </div>
          <div className="property-subheader">
            {data.address.suburb}
          </div>
          <div className="property-subheader property-subheader-sm">
            {data.showAddress
              ? `${data.address.subNumber ? `${data.address.subNumber}/` : ''}${data.address.streetNumber} 
              ${data.address.street}`
              : <div />
            }
          </div>
          <div className="property-count-container row">
            {data.bedrooms ?
              <div className="property-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="property-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="property-feature-border">
                  </polygon>
                </svg>
                <div className="property-feature-text">
                  {data.bedrooms}
                  <div className="property-feature-icon">
                    <i className="fa fa-bed" />
                  </div>
                </div>
              </div>
              : <div />}
            {data.bathrooms ?
              <div className="property-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="property-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="property-feature-border">
                  </polygon>
                </svg>
                <div className="property-feature-text">
                  {data.bathrooms}
                  <div className="property-feature-icon">
                    {/* <i className="fa fa-bath" /> */}
                    <img style={{ width: '14px' }} src="/images/bathroom.png" alt="bathroom" />
                  </div>
                </div>
              </div>
              : <div />}
            {data.garages ?
              <div className="property-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="property-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="property-feature-border">
                  </polygon>
                </svg>
                <div className="property-feature-text">
                  {data.garages}
                  <div className="property-feature-icon">
                    <img src="/images/garage-parking.svg"
                      style={{ height: '16px' }}
                      alt="garage parking" />
                  </div>
                </div>
              </div>
              : <div />}
            {data.offstreetParking ?
              <div className="property-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="property-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="property-feature-border">
                  </polygon>
                </svg>
                <div className="property-feature-text">
                  {data.offstreetParking}
                  <div className="property-feature-icon">
                    <i className="fa fa-car" />
                  </div>
                </div>
              </div>
              : <div />}
          </div>
          <div className="column">
            {data.landArea
              ? <div className="slide-area-text">
                {`Property Size: ${data.landArea}`}
              </div>
              : <div />}
            {data.floorArea
              ? <div className="slide-area-text">
                {`Floor size: ${data.floorArea}`}
              </div>
              : <div />}
          </div>
        </div >
        : <div />
      }
    </div >
  )
}

export default Slideshow