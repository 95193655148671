import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import navOptions from './navOptions.js'

import { getData, patchPropertyStatus } from '../api'
import { isServer } from '../isServer'

import PagePagination from '../PagePagination.jsx'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'


const Properties = props => {
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState([])

  const [dataCount, setDataCount] = useState(data.length)
  // TO DO: get data count from response header
  const [limit, setLimit] = useState(12)
  const [offset, setOffset] = useState(0)
  const [filter, setFilter] = useState('lastUpdated')
  const [order, setOrder] = useState('descending')
  const [statusUpdated, setStatusUpdated] = useState(false)

  const [showUrl, setShowUrl] = useState(0)

  // useEffect(() => {
  //   !props.isSearchData && requestDataWithFilters()
  // }, [])

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
    }
    if (!props.isSearchData) {
      requestDataWithFilters()
    } else {
      setData(props.searchData)
      setDataCount(props.searchData.length)
    }
  }, [filter, order, offset, limit, props, loaded])

  useEffect(() => {
    if (statusUpdated && loaded) {
      setTimeout(() => {
        requestDataWithFilters()
      }, 3000);
    }
  }, [statusUpdated, loaded])

  const requestDataWithFilters = () => {
    if (!isServer) {
      if (props.dataFilter !== 'all') {
        let navInfo = navOptions.find(option => option.name === 'properties');
        let filtered = navInfo.subMenus.find(subMenu => subMenu.aka === props.dataFilter)
        if (filtered) { handleGetData(filtered.handler, filtered.aka) }
      } else {
        handleGetData(null, null)
      }
    }
  }

  const handleGetData = (handler, spec) => {
    let url = `admin/properties?`
    if (handler) url += `${handler}=${spec}`
    url += '&limit=' + limit + '&offset=' + offset
    // url += '&filter=' + filter + '&order=' + order
    getData(url, handleSetData)
  }


  const handleSetData = (data, count) => {
    setDataCount(count)
    setData(data)
  }

  const handleUpdateStatus = (id, status) => {
    patchPropertyStatus(id, status, () => handleUpdateStatusSuccess(id), handleUpdateStatusError)
  }

  const handleShowUrl = (propertyId) => {
    setShowUrl(propertyId)
  }

  const selectText = (e) => {
    e.target.select();
  }

  const handleUpdateStatusSuccess = (id) => {
    setStatusUpdated(id)
  }

  const handleUpdateStatusError = () => {
  }


  return (
    <div className="kpod-container">
      <h1 className="kpod-main-title">
        Properties
      </h1>
      <div className="kpod-items-banner row">
        {props.isDashboard
          ? <div className="sm-text">Last Updated Properties...</div>
          : !props.isSearchData
            ? <div className="sm-text">Showing {data.length} of {dataCount} properties...</div>
            : <div className="sm-text">Showing {data.length} results for {props.searchTerm}</div>
        }
        {!props.isDashboard &&
          <div className="kpod-properties-filters row">
            <div className="sm-text">
              Filter / Sort by
            </div>
            <select className="kpod-properties-filter" name="filter">
              <option value="lastUpdated"
                onClick={() => setFilter('lastUpdated')} // ascending?
                selected={filter === 'lastUpdated'}>
                Last Updated
              </option>
              <option value="createdAt"
                onClick={() => setFilter('createdAt')}
                selected={filter === 'createdAt'}>
                Listing Created
                </option>
              <option value="propertyId"
                onClick={() => setFilter('id')}
                selected={(filter === 'id')}>
                Property ID
            </option>
              <option value="showAddress"
                onClick={() => setFilter('suburb')}
                selected={filter === 'suburb'}>
                Suburb
            </option>
            </select>

            <select className="kpod-properties-filter" name="ascending">
              <option value="lastUpdated"
                onClick={() => setOrder('ascending')} // ascending?
                selected={order === 'ascending'}>
                Ascending
              </option>
              <option value="descending"
                onClick={() => setOrder('descending')}
                selected={order === 'descending'}>
                Descending
            </option>
            </select>
          </div>
        }
        <div className="right row">
          {props.isDashboard &&
            <div className="kpod-property-btn">
              <Link to='/account/properties'>
                <button className="kpod-button"
                  onClick={() => props.setPage('properties')}>
                  List all properties
              </button>
              </Link>
            </div>
          }
          <div className="kpod-property-btn">
            <Link to='/account/properties/edit'>
              <button className="kpod-button">
                Add Property
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="kpod-items-container">
        {data.length ? data.map((property, i) => {
          return (
            <Link key={i} className="kpod-item kpod-property-item row" to={`/account/properties/edit/${property.id}`}>
              <div key={'div' + i} className="kpod-item-l">
                <div className="column">
                  <div className={`whatson-listing-img-container  kpod-item-img kpod-property-img
                  ${props.displayType === 'small' && 'greyscale-img'}`}>
                    {property.images.length ?
                      <img className='whatson-listing-img'
                        src={property.images[0].storageKey &&
                          `${cloudFrontURL}/properties/${property.id}/images/${property.images[0].storageKey}`}
                        alt=""
                      />
                      : <div />}
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="none" className="image-corner">
                      <polygon points="100,100 100,0 0,100"></polygon>
                    </svg>
                    <span className="sale-method">
                      {property.status}
                    </span>
                  </div>
                </div>
              </div>
              <div className="kpod-item-r">
                <div className="row">
                  <div className="column kpod-item-title">
                    <div className="row kpod-property-title">
                      {property.address.subNumber ? `${property.address.subNumber}/` : ''}
                      {`${property.address.streetNumber} ${property.address.street}`}
                    </div>
                    {property.region && property.region.toUpperCase()}
                  </div>
                  {statusUpdated === property.id ?
                    <div className="kpod-publish-btn column">
                      <i className="fa fa-check-circle-o"
                        style={{
                          fontSize: '22px'
                        }}></i>
                      Published
                    </div>
                    : property.status === 'draft' ?
                      <div className="kpod-publish-btn column"
                        onClick={(e) => {
                          e.preventDefault()
                          handleUpdateStatus(property.id, "live")
                        }}>
                        <i className="fa fa-paper-plane-o"
                          style={{
                            fontSize: '22px'
                          }}></i>
                    Publish
                  </div>
                      : <div />}
                </div>
                <div className="row">
                  <div className="kpod-item-heading">Sale Method: </div>
                  <div className="kpod-item-heading">{property.saleMethod}</div>
                </div>
                <div className="row">
                  <div className="kpod-item-heading">Main Lister: </div>
                  {/* Fix - multiple agents */}
                  <div className="kpod-item-heading">{property.agents && property.agents[0].displayName}</div>
                </div>
                <div className="kpod-light-text column">
                  <div className="row">
                    <div className="kpod-item-headingsm">Property Web ID: </div>
                    <div className="kpod-item-heading">{property.reaUniqueID}</div>
                  </div>
                  <div className="row">
                    <div className="kpod-item-headingsm">Last Updated: </div>
                    <div className="kpod-item-heading">
                      {new Date(property.updatedAt).toLocaleDateString('en-GB')}
                      {new Date(property.updatedAt).toLocaleTimeString()}
                    </div>
                  </div>
                  {showUrl === property.id ?
                    <div onClick={(e) => { e.preventDefault() }}>
                      <input type="text" className="kpod-copy-input"
                        onClick={(e) => {
                          selectText(e)
                        }}
                        value={!isServer
                          ? `${window.location.href.replace(/account/g,
                            `property/view/${property.url}`)}`
                          : `/`}>
                      </input>
                    </div>
                    : <div className="kpod-copy-btn row"
                      onClick={(e) => {
                        e.preventDefault()
                        handleShowUrl(property.id)
                      }}>
                      <i className="fa fa-link"
                        style={{
                          fontSize: '20px',
                          marginRight: '5px'
                        }}></i>
                      Show URL
                      </div>}
                </div>
              </div>
            </Link>
          )
        })
          : <div />}
      </div>
      {
        data.length && dataCount > 12 && !props.isDashboard ?
          <PagePagination
            limit={limit}
            setLimit={(rpp) => setLimit(rpp)}
            offset={offset}
            setOffset={(startIdx) => setOffset(startIdx)}
            count={dataCount}
          />
          : <div />
      }
    </div >
  )
}

export default Properties