import React, { useState, useEffect, useRef, useCallback } from 'react'
import GoogleMapReact from 'google-map-react';

import './map.css'

export const MapSearch = ({ maps, map, onPlacesChanged, placeholder }) => {
  const input = useRef(null);
  const searchBox = useRef(null);

  const handleOnPlacesChanged = useCallback(() => {
    if (onPlacesChanged) {
      onPlacesChanged(searchBox.current.getPlaces());
    }
  }, [onPlacesChanged, searchBox]);

  useEffect(() => {
    if (!searchBox.current && maps) {
      searchBox.current = new maps.places.SearchBox(input.current);
      searchBox.current.addListener('places_changed', handleOnPlacesChanged);
    }

    if (map && searchBox.current) {
      searchBox.current.setBounds(map.getBounds());
    }

    return () => {
      if (maps) {
        searchBox.current = null;
        maps.event.clearInstanceListeners(searchBox);
      }
    };
  }, [handleOnPlacesChanged, map, maps]);

  return (
    <div id="map-search-input" className="map-search-input"
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}>
      <input
        ref={input}
        placeholder={placeholder}
        type="text"
        className="kpod-form-input"
        style={{
          width: '100%'
        }} />
      <span className="fa fa-search"
        style={{
          content: '\f002',
          color: '#ec008c'
        }} />
    </div>
  )
}


const MapContainer = props => {
  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)
  // const [bounds, setBounds] = useState(null)

  const [markers, setMarkers] = useState([])

  useEffect(() => {
    if (map && maps) {
      clearMarkers()
      if (props.isEdit) {
        renderMarkers(map, maps)
      }
    }
  }, [props, map, maps])

  const handleApiLoaded = (map, maps) => {
    renderMarkers(map, maps)
    setMap(map)
    setMaps(maps)
  }

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: props.lat, lng: props.lng },
      icon: '/images/GMapsMarker.png',
      map,
      title: ''
    })
    let newMarker = [marker]
    setMarkers(newMarker)
  }

  // Sets the map on all markers in the array.
  const setMapOnAll = (map) => {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }

  // Removes the markers from the map, but keeps them in the array.
  const clearMarkers = () => {
    setMapOnAll(null);
  }

  const onPlacesChanged = (places) => {
    props.updateLocation(places[0])
  }


  return (
    <div className="map-container">
      <div className="map-search-container"
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        Search
          <MapSearch
          map={map}
          maps={maps}
          placeholder={props.locDescription}
          onPlacesChanged={(place) => onPlacesChanged(place)} />
      </div>
      <div style={{
        height: '50vh',
        width: '100%',
        position: 'relative'
      }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            libraries: ['places']
          }}
          center={{ lat: props.lat, lng: props.lng }}
          defaultCenter={{ lat: props.lat, lng: props.lng }}
          zoom={props.mapZoom}
          defaultZoom={15}
          // bounds={bounds}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
        </GoogleMapReact>
      </div>
    </div >
  );
}

export default MapContainer


