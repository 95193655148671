import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { getData, uuidv4 } from './api'
import WhatsOnListing from './WhatsOnListing'
import WhatsOnMobile from './WhatsOnMobile'
import { getArrObjCopy } from './getCopies'
import { isServer } from './isServer'
import { openEmail } from './utls'
import { formatDate, formatTimes, getFullDateStartTime } from './formatDateTimes'

const getResponsiveCount = () => {
  let count = 5;
  if (!isServer) {
    if (window.innerWidth < 1000) {
      count = (Math.floor((window.innerWidth / 2) / 100))
    } else if (window.innerWidth < 470) {
      count = 1
    }
  }

  return count
}

const WhatsOn = props => {

  const [nav, setNavSelected] = useState('openhomes')

  // Open homes list, unformatted
  const [openHomeListings, setOpenHomeListings] = useState([])
  // Open homes formatted for preview - 5 results 
  const [previewOpenHomes, setPreviewOpenHomes] = useState([])

  const [auctionListings, setAuctionListings] = useState([])
  const [previewAuctions, setPreviewAuctions] = useState([])

  const [listings, setListings] = useState([])
  const [previewListings, setPreviewListings] = useState([])

  const [isMobile, setIsMobile] = useState(!isServer ? window.innerWidth <= 800 : false)

  const [listingCount, setListingCount] = useState(getResponsiveCount())
  // Maximum listings visible - dependant on screen width 

  const [page, setPage] = useState(0)
  const [pagesCount, setPagesCount] = useState(0)

  const [touchControls, setTouchControls] = useState({
    dir: 'none',
    swipeType: 'none',
    startX: 0,
    startY: 0,
    startTime: 0,
    distX: 0,
    distY: 0,
    threshold: 150, //required min distance traveled to be considered swipe
    restraint: 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime: 500, // maximum time allowed to travel that distance
    elapsedTime: 0,
    touchReleased: false
  })

  const handleResize = () => {
    let count = getResponsiveCount()
    setListingCount(count)
    if (!isServer) {
      if (window.innerWidth <= 800) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
  }

  useEffect(() => {
    if (touchControls.touchReleased) {
      if (touchControls.dir !== 'up' && touchControls.dir !== 'down') {
        if (touchControls.dir === 'left') {
          page !== pagesCount && setPage(page + 1)
        } else if (touchControls.dir === 'right') {
          page !== 0 && setPage(page - 1)
        }
      }
    }
  }, [touchControls])

  useEffect(() => {
    handleGetOpenHomes()
    if (!isServer) {
      window.addEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    listings.length && handlePreviewListings()
  }, [listings])

  useEffect(() => {
    setListingCount(getResponsiveCount())
  }, [nav])

  useEffect(() => {
    openHomeListings.length && handlePreviewOpenHomes()
  }, [openHomeListings])

  useEffect(() => {
    auctionListings.length && handlePreviewAuctions()
  }, [auctionListings])

  const handleGetOpenHomes = () => {
    setNavSelected('openhomes')
    let url = 'properties?openhomesonly=true&status=live'
    getData(url, setOpenHomeListings)
  }

  const handleGetAuctions = () => {
    setNavSelected('auctions')
    let url = 'properties?sale_method=auction&status=live'
    getData(url, setAuctionListings)
  }

  const handleGetNewListings = () => {
    setNavSelected('new')
    let url = 'properties?status=live&limit=5'
    getData(url, setListings)
  }

  const handlePreviewListings = () => {
    let newListings = getArrObjCopy(listings)

    newListings.forEach((listing, idx) => {
      listing.idx = idx;
      // listing.id = uuidv4()
    })

    if (newListings.length < listingCount) {
      setListingCount(newListings.length)
      setPagesCount(0)
    } else {
      setPagesCount(newListings.length - listingCount)
    }

    setPage(0)
    let previews = newListings
    setPreviewListings(previews)
  }

  const handlePreviewOpenHomes = () => {
    let listingsCopy = getArrObjCopy(openHomeListings)

    let openHomesFormatted = []

    listingsCopy.map((listing, idx) => {
      listing.openhomes.forEach(op => {
        if (!openHomesFormatted.find(f => {
          return f.dayOn === op.dayOn
        })) {
          let newDateItem = {
            date: formatDate(op.dayOn),
            dayOn: op.dayOn,
            id: uuidv4(),
            isDateItem: true,
          }
          openHomesFormatted.push(newDateItem)
        }

        let formattedDateTime = getFullDateStartTime(op.startTime, op.dayOn)

        let newListingItem = {
          date: formatDate(op.dayOn),
          dayOn: op.dayOn,
          fullDateTime: formattedDateTime,
          id: uuidv4(),
          isDateItem: false,
          listing: {
            ...listing,
            startTime: formatTimes(op.startTime),
            endTime: formatTimes(op.endTime)
          },
        }
        openHomesFormatted.push(newListingItem)
      })
      return true
    })

    if (openHomesFormatted.length < listingCount) {
      setPagesCount(0)
      setListingCount(openHomesFormatted.length)
    } else {
      setPagesCount(openHomesFormatted.length - listingCount)
    }

    openHomesFormatted = openHomesFormatted.sort((a, b) => {
      let aTime = new Date(a.fullDateTime ? a.fullDateTime : a.dayOn);
      let bTime = new Date(b.fullDateTime ? b.fullDateTime : b.dayOn);
      return aTime - bTime;
    })

    openHomesFormatted.forEach((op, idx) => {
      op.idx = idx;
    })

    setPage(0)
    setPreviewOpenHomes(openHomesFormatted)
  }

  const handlePreviewAuctions = () => {
    let listingsCopy = getArrObjCopy(auctionListings)
    let auctionsFormatted = []

    listingsCopy.map(listing => {
      let auctionDateFormatted = formatDate(listing.auctionDate)
      if (!auctionsFormatted.find(f => {
        return f.auctionDate === listing.auctionDate
      })) {
        let newDateItem = {
          auctionDate: listing.auctionDate,
          date: auctionDateFormatted,
          id: uuidv4(),
          isDateItem: true
        }
        auctionsFormatted.push(newDateItem)
      }

      let formattedDateTime = getFullDateStartTime(listing.auctionTime, listing.auctionDate)

      let newListingItem = {
        auctionDate: listing.auctionDate,
        date: auctionDateFormatted,
        fullDateTime: formattedDateTime,
        id: uuidv4(),
        isDateItem: false,
        listing: {
          ...listing,
          auctionTime: formatTimes(listing.auctionTime),
        },
      }

      auctionsFormatted.push(newListingItem)
      return true
    })

    if (auctionsFormatted.length < listingCount) {
      setListingCount(auctionsFormatted.length)
      setPagesCount(0)
    } else {
      setPagesCount(auctionsFormatted.length - listingCount)
    }

    auctionsFormatted = auctionsFormatted.sort((a, b) => {
      let aTime = new Date(a.fullDateTime ? a.fullDateTime : a.auctionDate);
      let bTime = new Date(b.fullDateTime ? b.fullDateTime : b.auctionDate);
      return aTime - bTime;
    })

    auctionsFormatted.forEach((auc, idx) => {
      auc.idx = idx;
    })

    setPage(0)
    setPreviewAuctions(auctionsFormatted)
  }


  const openTel = (num) => {
    if (!isServer) {
      window.location.href = "tel:" + num;
    }
  }

  if (isMobile) {
    // Fix compiler issues with separate mobile component
    return <WhatsOnMobile
      setPage={(p) => setPage(p)}
      setNavSelected={(n) => setNavSelected(n)}
      handleGetOpenHomes={() => handleGetOpenHomes()}
      handleGetAuctions={() => handleGetAuctions()}
      handleGetNewListings={() => handleGetNewListings()}
      openTel={() => openTel()}
      previewOpenHomes={previewOpenHomes}
      previewAuctions={previewAuctions}
      previewListings={previewListings}
      nav={nav}
      pagesCount={pagesCount}
      page={page}
    />
  } else {
    return (
      <div className="whatson-container">
        <div className="center-text column">
          <div className="main-page-header">
            Whats on?
        </div>
          <div className="whatson-inner column">
            <nav className="row sub-nav-container center-text">
              <ul className="navigation">
                <li>
                  <a className={`main-nav sub-nav
              ${nav === 'openhomes' ? 'selected' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleGetOpenHomes();
                    }}
                    href='/'>
                    Open Homes
                </a>
                </li>
                <li>
                  <a className={`main-nav sub-nav 
                ${nav === 'auctions' ? 'selected' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleGetAuctions();
                    }}
                    href='/'>
                    Auctions
                </a>
                </li>
                <li>
                  <a className={`main-nav sub-nav 
                ${nav === 'new' ? 'selected' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleGetNewListings();
                    }}
                    href='/'>
                    New Listings
                  </a>
                </li>
              </ul>
            </nav>
            <div id="whatson-listings-container"
              className="whatson-listings-container">
              {nav === 'openhomes' ?
                previewOpenHomes.length ?
                  <div className="transition-slide-outer row">
                    <span className={`fa fa-chevron-circle-left whatson-prevnext-icon
                    ${page === 0
                        ? 'prevnext-icon-disabled'
                        : ''}`}
                      onClick={() => setPage(page - 1)}
                      style={{ fontSize: '32px' }}>
                    </span>
                    <div className="transition-slide-inner row"
                      style={{
                        width: (194 * (listingCount)) + 'px',
                        minWidth: (194 * (listingCount)) + 'px'
                      }}>
                      <div id="openhome-transition" className="row"
                        style={{ left: (page >= 0 ? page * -194 + 'px' : '0px') }}>
                        {previewOpenHomes.map((openhome, index) => {
                          return (
                            <div key={index} className="row"
                              onTouchMove={(e) => handleTouchMove(e)}
                              onTouchStart={(e) => handleTouchStart(e)}
                              onTouchEnd={(e) => handleTouchEnd(e)}>
                              {openhome.isDateItem ?
                                <div className='whatson-item'>
                                  <div className="whatson-listing-content whatson-date-item">
                                    <div className="whatson-date-text">
                                      <div className="whatson-date-sm">
                                        {openhome.date.split(' ')[0]}
                                      </div>
                                      <div className="whatson-date-lg">
                                        {openhome.date.split(' ')[1]}
                                      </div>
                                      <div className="whatson-date-sm">
                                        {openhome.date.split(' ')[2]}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="whatson-item">
                                  <a href={`/property/view/${openhome.listing.url}`}>
                                    <WhatsOnListing
                                      displayType={'small'}
                                      type={'openhome'}
                                      key={openhome.listing.id}
                                      date={openhome.dayOn}
                                      property={openhome.listing} />
                                  </a>
                                </div>
                              }
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>
                    <span className={`fa fa-chevron-circle-right whatson-prevnext-icon
                    ${page === pagesCount
                        ? 'prevnext-icon-disabled'
                        : ''}`}
                      onClick={() => setPage(page + 1)}
                      style={{ fontSize: '32px' }}>
                    </span>
                  </div>
                  : <div className="main-text center-text">
                    Currently no items available
                  </div>
                :
                nav === 'auctions' ?
                  previewAuctions.length ?
                    <div className="transition-slide-outer row">
                      <span className={`fa fa-chevron-circle-left whatson-prevnext-icon
                      ${page === 0
                          ? 'prevnext-icon-disabled'
                          : ''}`}
                        onClick={() => setPage(page - 1)}
                        style={{ fontSize: '32px' }}>
                      </span>
                      <div className="transition-slide-inner row"
                        style={{
                          width: (194 * (listingCount) + 'px'),
                          minWidth: (194 * (listingCount)) + 'px'
                        }}>
                        <div id="auction-transition" className="row"
                          style={{ left: ((page * (-194)) + 'px') }}>
                          {previewAuctions.map((auction, index) => {
                            return (
                              <div className="row" key={index}
                                onTouchMove={(e) => handleTouchMove(e)}
                                onTouchStart={(e) => handleTouchStart(e)}
                                onTouchEnd={(e) => handleTouchEnd(e)}>
                                {auction.isDateItem ?
                                  <div className='whatson-item'>
                                    <div className="whatson-listing-content whatson-date-item">
                                      <div className="whatson-date-text">
                                        <div className="whatson-date-sm">
                                          {auction.date.split(' ')[0]}
                                        </div>
                                        <div className="whatson-date-lg">
                                          {auction.date.split(' ')[1]}
                                        </div>
                                        <div className="whatson-date-sm">
                                          {auction.date.split(' ')[2]}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div className="whatson-item">
                                    <a href={`/property/view/${auction.listing.url}`}>
                                      <WhatsOnListing
                                        displayType={'small'}
                                        type={'auction'}
                                        key={auction.listing.id}
                                        date={auction.listing.date}
                                        property={auction.listing} />
                                    </a>
                                  </div>}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <span className={`fa fa-chevron-circle-right whatson-prevnext-icon
                      ${page === pagesCount
                          ? 'prevnext-icon-disabled'
                          : ''}`}
                        onClick={() => setPage(page + 1)}
                        style={{ fontSize: '32px' }}>
                      </span>
                    </div>
                    : <div className="center-text">
                      Currently no items available
                    </div>
                  :
                  nav === 'new' &&
                    previewListings.length ?
                    <div className="transition-slide-outer row">
                      <div className="transition-slide-inner row"
                        style={{
                          width: (194 * (listingCount)) + 'px',
                          minWidth: (194 * (listingCount)) + 'px'
                        }}>
                        <div id="newlisting-transition" className="row"
                          onTouchMove={(e) => handleTouchMove(e)}
                          onTouchStart={(e) => handleTouchStart(e)}
                          onTouchEnd={(e) => handleTouchEnd(e)}
                          style={{ left: ((page * (-194)) + 'px') }} >
                          {previewListings.map((listing, index) => {
                            return (
                              <a key={index} href={`/property/view/${listing.url}`}>
                                <WhatsOnListing
                                  displayType={'small'} key={listing.id} property={listing} />
                              </a>
                            )
                          })
                          }
                        </div>
                      </div>
                    </div>
                    : <div className="center-text main-text">
                      Currently no items available
                    </div>
              }
            </div>
          </div>
          <div className="whatson-contact-row row">
            <div className="whatson-contact-info" onClick={() => openTel('+64 9 300 4001')}>
              <span className="fa-stack"
                style={{ marginRight: '5px' }}>
                <i className="fa fa-circle-thin fa-stack-2x"></i>
                <i className="fa fa-phone fa-stack-1x"></i>
              </span>
            +64 9 300 4001
          </div>
            <Link to={nav === 'new' ? '/property' : `/${nav}`}>
              <div className="whatson-viewall whatson-contact-info">
                <div className="row center-item">
                  <i className="fa fa-caret-right"
                    style={{
                      color: '#ec008c',
                      fontSize: '22px',
                      marginLeft: '-5px',
                      marginRight: '5px'
                    }}></i>
                  <div className="center-text center-item whatson-text">
                    {nav === 'openhomes'
                      ? 'View All Open Homes'
                      : nav === 'new'
                        ? 'View All New Listings'
                        : nav === 'auctions'
                          ? 'View All Auctions'
                          : ''}
                  </div>
                </div>
              </div>
            </Link>
            {/* <Link to='/contact'> */}
              <div className="whatson-contact-info" onClick={() => openEmail('info@kellands.co.nz', isServer, `Contact Request`, "")}>
                <i className="fa fa-envelope-o fa-lg"
                  style={{ marginRight: '5px' }}></i>
              Email Enquiries
            </div>
            {/* </Link> */}
          </div>
        </div>
      </div >
    )
  }
}

export default WhatsOn
