import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { sendEmail } from './api'

const EmailTo = props => {
  const { type, agentName, agentEmail, propertyId, propertyUrl } = useParams()
  // if type === agent
  const [firstName, setFirstName] = useState('')
  const [surname, setSurname] = useState('')

  // const [agentEmail, setAgentEmail] = useState(props.location.agentEmail)
  // const [propertyId, setPropertyId] = useState(props.location.propertyId)

  // if type === friend
  const [friendsName, setFriendsName] = useState('')
  const [friendsEmail, setFriendsEmail] = useState('')

  // both
  const [comments, setComments] = useState('')
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  // const [error, setError] = useState('')
  const [emailLimit, setEmailLimit] = useState(false)

  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (!comments.length && type === 'agent') {
      setComments(`Regarding Property - ${propertyId}`)
    }
  }, [propertyId, comments, type])

  const submitFormAgent = () => {
    if (validateEmail(email)) {
      let body = {
        "senderFirstName": firstName,
        "senderLastName": surname,
        "senderPhone": phone,
        "senderEmail": email,
        "agentName": agentName,
        "agentEmail": agentEmail.trim(),
        "propertyURL": `www.kellands.co.nz/property/view/${propertyUrl}`,
        "comments": comments,
      }
      sendEmail(body, 'agentemail', submitFormSuccess, handleSubmitError)
    } else {
      setEmailInvalid(true)
    }
  }

  const submitFormFriend = () => {
    if (validateEmail(email) && validateEmail(friendsEmail)) {
      let body = {
        "senderFirstName": firstName,
        "senderLastName": "",
        "senderEmail": email,
        "recipientFirstName": friendsName,
        "recipientLastName": "",
        "recipientEmail": friendsEmail,
        "propertyURL": `www.kellands.co.nz/property/view/${propertyUrl}`,
        "comments": comments
      }
      sendEmail(body, 'emailafriend', submitFormSuccess, handleSubmitError)
    } else {
      setEmailInvalid(true)
    }
  }

  const validateEmail = (email) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email)
  }

  const handleSubmitError = (error) => {
    if (error.status === 429) {
      setEmailLimit(true)
    }
    // setError(error)
  }

  const submitFormSuccess = () => {
    setSuccess(true)
  }

  if (type === 'agent' && !emailLimit) {
    return (
      <div className="main-body column">
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:title" content="Email Property | Kellands Real Estate"/>
          <title>Email Property | Kellands Real Estate</title>
        </Helmet>
        <h1 className="main-page-header">
          Let's Talk
        </h1>
        <div className="row">
          {!success ?
            <div className="contactus-form">
              <fieldset>
                <p className="emailto-inputs">
                  <label className="required" for="fname">Your Name *</label>
                  <input className="emailto-input"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    type="text" name="f_Name" id="fname" />
                </p>
                <p className="emailto-inputs">
                  <label className="required" for="sname">Your Last Name *</label>
                  <input className="emailto-input"
                    onChange={(e) => setSurname(e.target.value)}
                    value={surname}
                    type="text" name="f_Name" id="fname" />
                </p>
                <p className="emailto-inputs">
                  <label for="fcontactphone">Phone Number</label>
                  <input className="emailto-input"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    type="text" name="f_ContactPhone" id="fcontactphone" />
                </p>
                <p className="emailto-inputs">
                  <label className="required" for="femail">E-mail Address *</label>
                  <input className={`emailto-input contactus-input-${emailInvalid}`}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="text" name="f_Email" id="femail" />
                </p>
                <input type="hidden" name="f_Office" id="foffice" value="info@kellands.co.nz" />
                <p className="emailto-inputs">
                  <label className="required" for="fcomment">Comments *</label>
                  <textarea className="emailto-input"
                    onChange={(e) => setComments(e.target.value)}
                    value={comments}
                    cols="" rows="4" name="f_Comment" id="fcomment">
                  </textarea>
                </p>
              </fieldset>
              <fieldset id="submit" className="contactus-submit-container">
                <p>
                  <em>Note: fields marked with a <span>*</span> are required to submit this form.</em>
                </p>
                <div className="contactus-submit-btns">
                  <input type="submit" name="Submit"
                    onClick={() => submitFormAgent()}
                    className="contactus-form-btn"
                    value="Submit" />
                </div>
              </fieldset>
            </div>
            : <div className="contactus-form contactus-left">
              Your email has been sent.
          </div>}
        </div>
      </div>
    )
  } else if (type === 'friend' && !emailLimit) {
    return (
      <div className="main-body column">
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:title" content="Email Property | Kellands Real Estate"/>
          <title>Email Property | Kellands Real Estate</title>
        </Helmet>
        {success &&
          <div className="main-page-header contactus-subheader">
            Your email has been sent.
            </div>}
        <h1 className="main-page-header">
          Email this property to a friend
        </h1>
        <div className="row">
          <div className="contactus-form">
            <fieldset>
              <p className="emailto-inputs">
                <label className="required" for="fname">Your Name *</label>
                <input className="emailto-input"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  type="text" name="f_Name" id="fname" />
              </p>
              <p className="emailto-inputs">
                <label className="required" for="femail">Your E-mail Address *</label>
                <input className={`emailto-input contactus-input-${emailInvalid}`}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="text" name="f_Email" id="femail" />
              </p>
              <p className="emailto-inputs">
                <label className="required" for="fname">Friend's Name *</label>
                <input className="emailto-input"
                  onChange={(e) => setFriendsName(e.target.value)}
                  value={friendsName}
                  type="text" name="f_Name" id="fname" />
              </p>
              <p className="emailto-inputs">
                <label className="required" for="femail">Friend's E-mail Address *</label>
                <input className={`emailto-input contactus-input-${emailInvalid}`}
                  onChange={(e) => setFriendsEmail(e.target.value)}
                  value={friendsEmail}
                  type="text" name="f_Email" id="femail" />
              </p>
              <input type="hidden" name="f_Office" id="foffice" value="info@kellands.co.nz" />
              <p className="emailto-inputs">
                <label className="required" for="fcomment">Your Comments *</label>
                <textarea className="emailto-input"
                  onChange={(e) => setComments(e.target.value)}
                  value={comments}
                  cols="" rows="4" name="f_Comment" id="fcomment">
                </textarea>
              </p>
            </fieldset>
            <fieldset id="submit" className="contactus-submit-container">
              <p>
                <em>Note: fields marked with a <span>*</span> are required to submit this form.</em>
              </p>
              <div className="contactus-submit-btns">
                {/* <input type="reset" name="Clear"
                    onClick={() => resetForm()}
                    className="contactus-form-btn contactus-reset"
                    value="Reset" /> */}
                <input type="submit" name="Submit"
                  onClick={() => submitFormFriend()}
                  className="contactus-form-btn"
                  value="Submit" />
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="main-body column">
        <div className="main-page-header contactus-subheader">
          You have reached your email limit
        </div>
        <div className="main-page-header contactus-subheader-sm">
          In case of emergency, contact Kellands
        </div>
        <div className="emailto-emergencyph">
          <div className="contact-details">
            <p className="contactus-p">
              <strong className="bold">Phone:</strong> +64 9 300 4001
        </p>
      
            {/* <p className="contactus-p">
              <b>Email: info@kellands.co.nz</b>
            </p> */}
            <p className="contactus-p">
              &nbsp;
        </p>
          </div>
        </div>
      </div>
    )
  }
}

export default EmailTo