import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { createBrowserHistory } from 'history'

import { getData, search } from './api'
import Listing from './Listing.jsx'
import PagePagination from './PagePagination.jsx'
import { isServer } from './isServer'
import { property } from 'lodash'


// let history = createBrowserHistory()

const Listings = props => {
  const history = useHistory()
  let type = history.location.pathname.includes('sold') ? 'sold' : 'live'
  const { id, name, status, propertyType } = useParams()
  const [data, setData] = useState([])
  const [dataCount, setDataCount] = useState(0)
  const [category, setCategory] = useState(propertyType || 'all')
  const [limit, setLimit] = useState(12)
  const [offset, setOffset] = useState(0)
  const [metaDesc, setMetaDesc] = useState('')

  const [isSearched, setIsSearched] = useState(false)
  const [isMobile, setIsMobile] = useState(!isServer ? window.innerWidth <= 800 : false)
  const [mobileNavOpen, seMobileNavOpen] = useState(false)


  const handleResize = () => {
    if (!isServer) {
      if (window.innerWidth <= 800) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
  }

  useEffect(() => {
    if (!isServer) {
      window.addEventListener('resize', handleResize)
    }

    let desc = type === 'sold'
      ? 'Recently sold residential properties in Auckland and Waiheke by the team at Kellands Real Estate.'
      : 'Looking to buy property? Houses, Apartments and Townhouses for sale in Auckland & Waiheke Island.'

    if (props.searchQuery.length) {
      let encoded = encodeURI(props.searchQuery)
      search('properties', false, encoded, `&limit=${limit}&offset=${offset}`, handleSetData)
      setIsSearched(true)
      ReactGA.event({
        category: 'Search',
        action: `User searched: ${props.searchQuery.length}`,
      })
    } else {
      if (isSearched) { setIsSearched(false) }
      // On page load or refresh with params
      const pair = [
        { name: 'homes', filterBy: '', title: 'Homes For Sale | Kellands Real Estate' },
        {
          name: 'apartments',
          filterBy: 'Apartment',
          title: 'Apartments For Sale | Apartment Specialists',
          desc: 'Apartments for sale in the Auckland city fringe. Specialists in Freemans Bay, Ponsonby, Grey Lynn, Eden Terrace, Kingsland & Beaumont Quarter.'
        },
        { name: 'waiheke-island-&-coastal', filterBy: 'Waiheke Island & Coastal', title: 'Waiheke Island & Coastal For Sale | Kellands Real Estate' }
      ]
      if (propertyType) {
        let matched = pair.find(p => p.name === propertyType.toLowerCase())
        if (matched) {
          if (matched.filterBy.length) {
            handleSetCategory(propertyType, matched.filterBy)
            if (matched.desc) { desc = matched.desc }
          } else {
            handleSetCategory(propertyType, 'all')
          }
        }
      } else {
        handleSetCategory('all', 'all')
      }
    }
    setMetaDesc(desc)
  }, [offset, limit, props.searchQuery, propertyType, category])

  function handleGetData(params) {
    let url = `properties?limit=${limit}&offset=${offset}&status=${type}${params ? params : ''}`
    type === 'sold'
      ? url += `&order_by=sold_on&sort=desc`
      : url += `&order_by=listed_date&sort=desc`
    if (name && status) {
      // url = `agents/${id}/properties?status=${status}&limit=${limit}&offset=${offset}`
      // Get agent by name
      url = `agents/name/${name}/properties?status=${status}&limit=${limit}&offset=${offset}`
      if (status === 'sold') {
        url += '&order_by=sold_on&sort=desc'
      }
    }
    getData(url, handleSetData)
  }


  const handleSetData = (data, count) => {
    setDataCount(count)
    setData(data)
  }

  const handleSetCategory = (name, filterBy) => {
    if (filterBy !== 'all') {
      let encoded = encodeURIComponent(filterBy)
      handleGetData(`&category=${encoded}`)
    } else {
      handleGetData()
    }
    setCategory(name.toLowerCase())
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
/* Long ternary for title - basically does this...
  // if search -> '<count> property search results for <query>'
  // else if live / for sale... -> 
    // if !all properties... ->
      // if waiheke -> 'Waiheke Island & Coastal Properties for Sale'
      // else '<category> for Sale'
    // else 'Properties for Sale'
  // else if sold -> 'Sold Properties'
*/
  let headerTitle = name ?
    ((name[name.length - 1] !== 's'
        ? name.replace('-', ' ') + "'s "
        : name.replace('-', ' ') + "' ")
      + (status === 'live' ? "Current " : "Sold ")
      + "Listings")
    : isSearched
      ? `${dataCount} Property search results for '${props.searchQuery}'`
      : type === 'live'
        ? (category !== 'all'
          ? (category === 'waiheke-island-&-coastal'
            ? `Waiheke Island & Coastal Properties`
            : category)
          : 'Properties') + ' For Sale'
        : 'Sold Properties'

  return (
    <div className="main-body">
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:title" 
          content={`${capitalizeFirstLetter(headerTitle)} | ${category === 'apartments' 
          ? 'Apartment Specialists' : 'Kellands Real Estate'}`}
          />
          <title>
          {`${capitalizeFirstLetter(headerTitle)} 
          | ${category === 'apartments' 
          ? 'Apartment Specialists' : 'Kellands Real Estate'}`}
          </title>
          <meta name="description" content={`${metaDesc}`} />
          <meta property="og:description" content={`${metaDesc}`} />
        </Helmet>
      <div className="column">
        <h1 className="main-page-header">
          {headerTitle}
        </h1>
        {type === 'live' && !isSearched && !name ?
          !isMobile ?
            <nav className="row sub-nav-container">
              <ul className="navigation">
                {category !== 'all' &&
                  <li>
                    <Link onClick={() => handleSetCategory('all', '')}
                      to={'/property'} className={`main-nav sub-nav 
                      ${category === `all` ? 'selected' : ''}`}>
                      All
                  </Link>
                  </li>
                }
                <li>
                  <Link onClick={() => handleSetCategory('all', '')}
                    to={'/property/categoryview/homes'} className={`main-nav sub-nav 
                    ${category === `homes` ? 'selected' : ''}`}>
                    Homes
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleSetCategory('apartments', 'Apartment')}
                    to={'/property/categoryview/apartments'} className={`main-nav sub-nav 
                    ${category === `apartments` ? 'selected' : ''}`}>
                    Apartments
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleSetCategory('waiheke-island-&-coastal', 'Waiheke Island & Coastal')}
                    to={'/property/categoryview/waiheke-island-&-coastal'} className={`main-nav sub-nav 
                    ${category === `waiheke-island-&-coastal` ? 'selected' : ''}`}>
                    {`Waiheke Island & Coastal`}
                  </Link>
                </li>
              </ul>
            </nav>
            : <nav className="mobile-sub-nav-container column center-text"
              onClick={() => seMobileNavOpen(!mobileNavOpen)}
              onMouseEnter={() => seMobileNavOpen(true)}
              onMouseLeave={() => seMobileNavOpen(false)}>
              {!mobileNavOpen ?
                <div className="row mobile-sub-nav-header">
                  <i className="fa fa-bars" style={{
                    color: '#ec008c',
                    fontSize: '22px',
                    marginRight: '14px'
                  }}></i>
                  <div className="mobile-sub-nav-header-text">
                    {category === 'homes'
                      ? 'Homes'
                      : category === 'apartments'
                        ? 'Apartments'
                        : category === 'waiheke-island-&-coastal'
                          ? 'Waiheke Island & Coastal'
                          : category === 'all'
                            ? 'All'
                            : ''}
                  </div>
                </div>
                :
                <ul className="mobile-sub-nav-body column" name="filter">
                  <div className="row mobile-sub-nav-header">
                    {category === 'all' ?
                      <i className="fa fa-check" style={{
                        color: '#ec008c',
                        fontSize: '18px',
                        marginRight: '14px',
                        width: '18px'
                      }}></i>
                      : <div className="mobile-icon-placeholder" />}
                    <Link to={'/property'}>
                      <li value="All"
                        className={`mobile-sub-nav-option
                  ${category === 'all' && 'bold'}`}
                        onClick={() => handleSetCategory('all', '')}
                        selected={category === 'all'}>
                        All
                    </li>
                    </Link>
                  </div>
                  <div className="row mobile-sub-nav-header">
                    {category === 'homes' ?
                      <i className="fa fa-check" style={{
                        color: '#ec008c',
                        fontSize: '18px',
                        marginRight: '14px',
                        width: '18px'
                      }}></i>
                      : <div className="mobile-icon-placeholder" />}
                    <Link to={'/property/categoryview/homes'}>
                      <li value="Homes"
                        className={`mobile-sub-nav-option
                        ${category === 'homes' && 'bold'}`}
                        onClick={() => handleSetCategory('homes', '')}
                        selected={category === 'homes'}>
                        Homes
                    </li>
                    </Link>
                  </div>
                  <div className="row  mobile-sub-nav-header">
                    {category === 'apartments' ?
                      <i className="fa fa-check" style={{
                        color: '#ec008c',
                        fontSize: '18px',
                        marginRight: '14px',
                        width: '18px'
                      }}></i>
                      : <div className="mobile-icon-placeholder" />}
                    <Link to={'/property/categoryview/apartments'}>
                      <li value="Apartments"
                        className={`mobile-sub-nav-option
                      ${category === 'apartments' && 'bold'}`}
                        onClick={() => handleSetCategory('apartments', 'Apartment')}
                        selected={category === 'apartments'}>
                        Apartments
                    </li>
                    </Link>
                  </div>
                  <div className="row  mobile-sub-nav-header">
                    {category === 'waiheke-island-&-coastal' ?
                      <i className="fa fa-check" style={{
                        color: '#ec008c',
                        fontSize: '18px',
                        marginRight: '14px',
                        width: '18px'
                      }}></i>
                      : <div className="mobile-icon-placeholder" />}
                    <Link to={'/property/categoryview/waiheke-island-&-coastal'}>
                      <li value={`Waiheke Island & Coastal`}
                        className={`mobile-sub-nav-option
                    ${category === `waiheke-island-&-coastal` && 'bold'}`}
                        onClick={() => handleSetCategory('waiheke-island-&-coastal', 'Waiheke Island & Coastal')}
                        selected={(category === `waiheke-island-&-coastal`)}>
                        {`Waiheke Island & Coastal`}
                      </li>
                    </Link>
                  </div>
                </ul>
              }
            </nav>
          : <div />}
        <div className="listings-container">
          {data.length ? data.map((property, idx) => {
            return (
              // Use standard link for property view page
              // This page is loaded on the server
              <a href={`/property/view/${property.url}`}
              className={`listing-container ${isMobile && 'listing-container-mobile'}`}
              key={property.id}>
                <Listing property={property} idx={idx} />
              </a>
            )
          })
            : <div />}
        </div>
      </div>
      {dataCount > 12 ?
        <PagePagination
          limit={limit}
          setLimit={(rpp) => setLimit(rpp)}
          offset={offset}
          setOffset={(startIdx) => setOffset(startIdx)}
          count={dataCount}
        />
        : <div />
      }
    </div >
  )

}

// export default withStyles(styles)(Listings)
export default Listings