import React, { useState, useEffect } from 'react'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

const WhatsOnListing = props => {
  const [thumbnail, setThumbnail] = useState('')
  const [altThumbnail, setAltThumbnail] = useState('')

  useEffect(() => {
    let image = props.property.images.find(i => i.position === 1)
    // let url = `${cloudFrontURL}/properties/${props.property.id}/images/${image.storageKey}`
    // setThumbnail(url)
    let ogImage = image ? image.storageKey : ""
    let newImage;
    if (ogImage.includes('_1280-0')) {
      newImage = ogImage.replace('_1280-0', '_300-0')
    } else {
      newImage = ogImage.split('.').join('_300-0.')
    }
    setThumbnail(`${cloudFrontURL}/properties/${props.property.id}/images/${newImage}`)
    setAltThumbnail(`${cloudFrontURL}/properties/${props.property.id}/images/${ogImage}`)
  }, [props.property])

  return (
    <div className={`whatson-item-${props.displayType} column`}>
      <div className="whatson-listing-content">
        <div className={`whatson-listing-img-container 
        ${props.displayType === 'small' ? 'greyscale-img' : ''}`}>
          <img className='whatson-listing-img'
            alt={`Missing listing thumbnail`}
            // src={thumbnail}
            src={altThumbnail}
          // onError={(e) => e.target.src = altThumbnail} 
          />
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="none" className="image-corner">
            <polygon points="100,100 100,0 0,100"></polygon>
          </svg>
          <span className="sale-method">
            {props.property.saleMethod.length > 12
              ? ''
              : props.property.saleMethod}
          </span>
        </div>
        <div className={`whatson-listing-info-container `}>
          <div className="listing-text-container">
            <div className="listing-main-text">
              {props.property.address.suburb}
            </div>
            <div className="listing-sub-text">
              {`${props.property.address.subNumber ? `${props.property.address.subNumber}/` : ''}${props.property.address.streetNumber} 
                ${props.property.address.street}`}
            </div>
            {props.type === 'openhome' ?
              <div className="whatson-listing-times">
                {props.property.openhomes.map(op => {
                  if (op.dayOn === props.date) {
                    return (
                      `${op.startTime} -  ${op.endTime}`
                    )
                  }
                  return true
                })}
              </div>
              : props.type === 'auction' ?
                <div className="whatson-listing-times">
                  {props.property.auctionTime}
                </div>
                : <div />}
          </div>
          <div className="listing-count-container row">
            {props.property.bedrooms ?
              <div className="listing-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="listing-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="listing-feature-border">
                  </polygon>
                </svg>
                <div className="whatson-listing-feature-text">
                  {props.property.bedrooms}
                  <div className="listing-feature-icon">
                    <i className="fa fa-bed" />
                  </div>
                </div>
              </div> : <div></div>
            }
            {props.property.bathrooms ?
              <div className="listing-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="listing-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="listing-feature-border">
                  </polygon>
                </svg>
                <div className="whatson-listing-feature-text">
                  {props.property.bathrooms}
                  <div className="listing-feature-icon">
                    {/* <i className="fa fa-bath" /> */}
                    <img style={{ width: '12px' }} src="/images/bathroom.png"
                      alt="bathroom" />
                  </div>
                </div>
              </div> : <div></div>
            }
            {props.property.garages ?
              <div className="listing-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="listing-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="listing-feature-border">
                  </polygon>
                </svg>
                <div className="whatson-listing-feature-text">
                  {props.property.garages}
                  <div className="listing-feature-icon">
                    <img className="listing-feature-icon" src="/images/garage-parking.svg"
                      // Hacky - this icon looks pixelated at smaller height
                      style={{
                        marginTop: '-1px',
                        height: '14px',
                        width: '22px',
                        imageRendering: '-webkit-optimize-contrast !important'
                      }}
                      alt="parking" />
                  </div>
                </div>
              </div> : <div></div>
            }
            {props.property.offstreetParking ?
              <div className="listing-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="listing-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="listing-feature-border">
                  </polygon>
                </svg>
                <div className="whatson-listing-feature-text">
                  {props.property.offstreetParking}
                  <div className="listing-feature-icon">
                    <i className="fa fa-car" />
                    {/* <img className="listing-feature-icon" src="/images/print_car.gif" /> */}
                  </div>
                </div>
              </div> : <div></div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatsOnListing