import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'

import { Alert, AlertTitle } from '@material-ui/lab'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns'

import { isServer } from '../isServer'

import {
  getData,
  postData,
  patchData,
  getPreSignedUrl,
  uploadImg,
  uploadAttachment,
  patchPropertyStatus,
  updatePropertiesData,
  createPropertiesData,
  deletePropertiesData
} from '../api'

import { getObjCopy, getArrObjCopy } from '../getCopies'
import regions from './regions'

import Map from './Map.jsx'
import MCETextEditor from './MCETextEditor.jsx'

import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ImageModal from './ImageModal'
import DragDropContainer from './DragDropContainer.jsx'

let processAlerts = [];
let processCount = 0;

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'
// const cloudFrontURL = 'https://kellands-website-assets-dev.s3.ap-southeast-2.amazonaws.com'

const PropertyForm = props => {
  const { id } = useParams()
  let history = useHistory()
  // newId reset after new property form submission
  const [newId, setNewId] = useState(id)
  const [tab, setTab] = useState(1)

  const [soldOn, setSoldOn] = useState(null)
  const [creatorID, setCreatorID] = useState(1)
  const [carports, setCarports] = useState(null)
  const [openSpaces, setOpenSpaces] = useState(null)
  const [featured, setFeatured] = useState(null)

  const [categories, setCategories] = useState([{ id: 0, name: '' }])
  const [originalCategories, setOriginalCategories] = useState([{ id: 0, name: '' }])
  const [categoriesAltered, setCategoriesAltered] = useState(false)

  const [saleMethod, setSaleMethod] = useState('')

  const [price, setPrice] = useState(0)
  const [showPrice, setShowPrice] = useState(false)

  const [reaUniqueId, setReaId] = useState('')
  const [reference, setReference] = useState('')
  const [reaAgentId, setReaAgentId] = useState('')

  const [updatedAtFormatted, setUpdatedAt] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [createdAtFormatted, setCreatedAtFormatted] = useState('')

  const [address, setAddress] = useState({
    "subNumber": "",
    "streetNumber": "",
    "street": "",
    "suburb": regions[0].districts[0].suburbs[0],
    "city": regions[0].districts[0].name,
    "state": regions[0].name,
    "postcode": "",
    "country": "New Zealand"
  })

  const [region, setRegion] = useState('')

  const [showAddress, setShowAddress] = useState(false)
  const [showSuburb, setShowSuburb] = useState(false)

  const [agentsAvailable, setAgentsAvailable] = useState([])
  const [agents, setAgents] = useState([])
  const [originalAgents, setOriginalAgents] = useState([])
  const [agentsAltered, setAgentsAltered] = useState(false)
  const [selectedAgent, setSelectedAgent] = useState({})

  const [mainHeading, setMainHeading] = useState('')
  const [mainText, setMainText] = useState('')
  const [landArea, setLandArea] = useState('')
  const [floorArea, setFloorArea] = useState('')
  const [bedrooms, setBedrooms] = useState(0)
  const [bathrooms, setBathrooms] = useState(0)
  const [garages, setGarages] = useState(0)
  const [offstreetParking, setOffStreetParking] = useState(0)
  const [additionalFeatures, setFeatures] = useState([])
  // const [additionalFeatures, setFeatures] = useState([{ feature: '', position: 0 }])

  const [listedDate, setListedDate] = useState(new Date())
  const [expiryDate, setExpiryDate] = useState(new Date())

  // const [attachments, setAttachments] = useState([{ type: '', value: '', mode: '', url: '' }])
  const [attachments, setAttachments] = useState([])
  // const [originalAttachments, setOriginalAttachments] = useState([{ type: '', value: '', mode: '', url: '' }])
  const [originalAttachments, setOriginalAttachments] = useState([])
  const [attachmentsAltered, setAttachmentsAltered] = useState(false)

  const [images, setImages] = useState([])
  const [imagesAltered, setImagesAltered] = useState(false)
  const [imagePositionsAltered, setImagePositionsAltered] = useState(true)

  const [newImages, setNewImages] = useState([])
  const [removedImages, setRemovedImages] = useState([])

  const [orderingImages, setOrderingImages] = useState(false)
  const [byNegotiation, setByNegotiation] = useState(false)
  const [displaySaleDetails, setDisplaySaleDetails] = useState('')

  const [lat, setLatitude] = useState(-41.162)
  const [lng, setLongitude] = useState(173.880)
  const [mapZoom, setMapZoom] = useState(10)
  const [locDescription, setLocDescription] = useState('')
  const [googleMapLink, setGoogleMapLink] = useState('')

  const [openHomeInfoOpen, setShowOpenHomeInfo] = useState([])
  const [openHomes, setOpenHomes] = useState([])

  const [removedOpenHomes, setRemovedOpenHomes] = useState([])
  const [openHomesAltered, setOpenHomesAltered] = useState(false)

  const [status, setPropertyStatus] = useState('draft')
  const [originalStatus, setOgStatus] = useState('')

  const [auctionDate, setAuctionDate] = useState(new Date())
  const [auctionTime, setAuctionTime] = useState("12:00:00")
  const [auctionLocation, setAuctionLocation] = useState('')
  const [auctionLocationOther, setAuctionLocationOther] = useState('')
  const [auctionIsSoldPrior, setAuctionIsSoldPrior] = useState('')
  const [auctionRule, setAuctionRule] = useState('')

  const [alertsUpdated, setAlertsUpdated] = useState(0)
  const [submitting, setSubmitting] = useState(false)

  const [submitStatus, setSubmitStatus] = useState({
    agents: false,
    categories: false,
    attachments: false,
    images: false,
    openhomes: false,
  })




  const [modal, setModal] = useState({
    isOpen: false,
    imageUrl: '',
    id: 0,
    idx: 0,
    topOffset: 0,
    type: 'images'
  })

  const [url, setPropertyUrl] = useState('')

  useEffect(() => {
    if (!agentsAvailable.length) {
      getAgentsAvailable()
    }
    if (newId && !props.isEdit) {
      handlePostPropertySuccess(newId)
    }
  }, [newId, agents, props.isEdit])

  useEffect(() => {
    if (props.isEdit && id) {
      let url = `admin/properties/${id}`
      getData(url, handleSetData, handleError)
      props.setSubPage('Edit listing')
    } else {
      props.setSubPage('Create new listing')
    }
  }, [])


    useEffect(() => {
    if (submitting && !props.isEdit) {
      const allFalse = Object.values(submitStatus).every(value => value === false);
      if (allFalse) {
          history.push(`/account/properties/edit/${newId}`);
      }
    }
  }, [submitting, submitStatus, history]); 



  const handleSetData = (res) => {
    setSoldOn(res.soldOn || null)
    setCreatorID(res.creatorID || 1)
    setCarports(res.carports || null)
    setOpenSpaces(res.openSpaces || null)
    setFeatured(res.featured || null)

    setUpdatedAt(new Date(res.updatedAt).toLocaleString())

    setCreatedAt(res.createdAt)
    setCreatedAtFormatted(new Date(res.createdAt).toLocaleString())

    setFeatures(res.additionalFeatures || [])

    setAddress({
      city: res.address.city === 'Auckland' ? 'Auckland City' : res.address.city,
      country: res.address.country,
      postcode: res.address.postcode,
      state: !res.address.state.length && res.address.city.includes('Auckland') ? 'Auckland' : res.address.state,
      street: res.address.street,
      streetNumber: res.address.streetNumber,
      subNumber: res.address.subNumber,
      suburb: res.address.suburb
    })

    setRegion(res.region)

    setPrice(res.price)

    setAgents(res.agents || [])

    setOriginalAgents(res.agents)

    setAttachments(res.attachments || [])
    setOriginalAttachments(res.attachments || [])

    setReaId(res.reaUniqueID)
    setReference(res.reference)
    setReaAgentId(res.reaAgentID)

    setAuctionDate(res.auctionDate)

    setAuctionLocation(res.auctionLocation)
    setAuctionLocationOther(res.auctionLocationOther)
    setAuctionRule(res.auctionRule)

    setAuctionTime({
      time: res.auctionTime,
      timeUnformatted: res.auctionTime
        ? formatFakeTime(res.auctionTime.substr(0, res.auctionTime.length - 3))
        : null
    })

    setAuctionIsSoldPrior(res.auctionIsSoldPrior)
    setBathrooms(res.bathrooms)
    setBedrooms(res.bedrooms)
    setByNegotiation(res.byNegotiation)
    setCategories(res.categoryies || [])
    setOriginalCategories(res.categoryies || [])

    setDisplaySaleDetails(res.displaySaleDetails)
    setFloorArea(res.floorArea)
    setGarages(res.garages)
    setLandArea(res.landArea)

    setLatitude(res.locLatitude)
    setLongitude(res.locLongitude)
    setMapZoom(res.locZoom)
    setLocDescription(res.locDescription)
    setGoogleMapLink(res.googleMapLink)

    setImages(res.images)
    setListedDate(res.listedDate)

    setPropertyStatus(res.status)
    setOgStatus(res.status)

    setMainHeading(res.mainHeading)
    setMainText(res.mainText)

    setOffStreetParking(res.offstreetParking)
    setSaleMethod(res.saleMethod)

    setShowAddress(res.showAddress)
    setShowPrice(res.showPrice)
    setShowSuburb(res.showSuburb)
    setPropertyUrl(res.url)

    let formattedOpenHomes = (res.openhomes ? formatOpenHomes(res.openhomes) : [])
    setOpenHomes((formattedOpenHomes) || [])
  }

  function formatFakeTime(substr) {
    return 'Mon 01 Jan 2020 ' + substr + ' GMT+1300 (New Zealand Daylight Time)'
  }

  function formatOpenHomes(op) {
    for (let i = 0; i < op.length; i++) {
      let subSt = op[i].startTime.substr(0, op[i].startTime.length - 3);
      let subEt = op[i].endTime.substr(0, op[i].endTime.length - 3);
      // Fake date, need correct time only
      // Initial startTimeUnformatted and endTimeUnformatted for Open Home time pickers
      let fakeDateSt = formatFakeTime(subSt)
      let fakeDateEt = formatFakeTime(subEt)
      op[i]['startTimeUnformatted'] = fakeDateSt
      op[i]['endTimeUnformatted'] = fakeDateEt
    }
    return op
  }

  const getAgentsAvailable = () => {
    getData('agents', handleSetAgentsAvailable, handleError)
  }

  // Handles agents shown on dropdown list
  const handleSetAgentsAvailable = (allAgents) => {
    let newAgentsAvailable = []
    allAgents.forEach((agent, i) => {
      // if agent !assigned to property, add to available agents list
      if (!agents.find(ag => { return ag.id === agent.id })) {
        delete agent.jobTitle
        delete agent.description
        delete agent.agentStatus
        newAgentsAvailable.push(agent)
      }
    })
    setSelectedAgent(newAgentsAvailable[0])
    setAgentsAvailable(newAgentsAvailable)
  }

  const handleCheckboxChange = () => {
    setByNegotiation(prevChecked => !prevChecked);
  };


  // TO DO: handle generic errors
  const handleError = (error) => {
    let message = error.message ? error.message : `Error`
    return setNewAlert('openhome_update', 'success', message, error.status)
  }

  const handleUploadImage = () => {
    let newImagesArr = getArrObjCopy(newImages)
    const inputFiles = document.getElementById('propertyimg-upload').files
    for (let i = 0; i < inputFiles.length; i++) {
      let url = URL.createObjectURL(inputFiles[i])
      newImagesArr.push({ file: inputFiles[i], url: url })
    }
    setNewImages(newImagesArr)
    setImagesAltered(true)
  }

  const handleSubmit = (submitAll) => {
    // If !submitAll, submit general settings
    let data = {
      // Fields not on form
      "carports": carports,
      "openSpaces": openSpaces,
      "featured": featured,
      "createdAt": createdAt,
      // General
      "creatorID": creatorID,
      "url": url ? url : mainHeading.replace(/ /g, '-'),
      "reference": reference,
      "reaAgentID": reaAgentId,
      "price": price,
      "showAddress": showAddress,
      "showPrice": showPrice,
      "showSuburb": showSuburb,
      "region": region,

      "address": address,
      "status": status,
      // TO DO -> ONLY send status if not edit?

      // 3: Desc & Feature
      "landArea": landArea,
      "floorArea": floorArea,
      "bedrooms": bedrooms,
      "bathrooms": bathrooms,
      "garages": garages,
      "offstreetParking": offstreetParking,
      "mainHeading": mainHeading,
      "mainText": mainText,
      "additionalFeatures": additionalFeatures,

      //  Sale Method
      "listedDate": listedDate,
      "soldOn": soldOn,
      "saleMethod": saleMethod,
      "auctionDate": auctionDate,
      "auctionTime": auctionTime.time,
      "auctionLocation": auctionLocation,
      "auctionLocationOther": auctionLocationOther,
      "auctionIsSoldPrior": auctionIsSoldPrior,
      "auctionRule": auctionRule,
      "displaySaleDetails": displaySaleDetails,
      "byNegotiation": byNegotiation,

      // Maps
      "locLongitude": lng,
      "locLatitude": lat,
      "locZoom": mapZoom,
      "locDescription": locDescription,
      "googleMapLink": googleMapLink,
    }

    if (props.isEdit) (
    data["reaUniqueID"] = reaUniqueId
    )

    let callback;
    if (props.isEdit) {
      if (submitAll) {
        callback = handleEditSubmitAll;
      } else {
        callback = handleEditSubmitGeneral;
      }
      patchData(`admin/properties/${id}`, data, callback, handlePatchPropertyError)
    } else {
      postData('admin/properties', data, (r) => setNewId(r.id), handlePostPropertyError)
    }
  }

  const handlePatchPropertySuccess = () => {
    let message = `Updated property general details`
    setNewAlert('patch_property', 'success', message, '')
  }

  const handlePatchPropertyError = (r) => {
    let message = `Error Updating property general details`
    setNewAlert('patch_property_error', 'error', message, r.status)
  }

  const handleEditSubmitGeneral = () => {
    handlePatchPropertySuccess()
    handleSendStatus()
    handleSendAgents()
    handleSendCategories()
  }

  const handleEditSubmitAll = () => {
    handlePatchPropertySuccess()
    handleSendStatus()
    handleSubmitProcess()
  }

  const handlePostPropertySuccess = (propertyId) => {
    let message = `Created new property record ${propertyId}`
    setNewAlert('post_property', 'success', message, '')

    handleSubmitProcess()
  }

  const handlePostPropertyError = (r) => {
    let message = `Error creating new property record: ${r.response.body.error_description}`
    setNewAlert('post_property_error', 'error', message, r.status)
  }
  // Called on successful creation of new property
  // Called on Save All settings for edit
  const handleSubmitProcess = () => {
       setSubmitStatus({
      agents: agentsAltered,
      openhomes: openHomesAltered,
      categories: categoriesAltered,
      attachments: attachmentsAltered,
      images: imagesAltered
    })
    setSubmitting(true)
    handleSendOpenHomes()
    handleSendAttachments()
    handleSendAgents()
    handleSendCategories()
    handleSendImages()
  }

  const setNewAlert = (id, status, message, statusCode) => {
    let newAlert = {
      id: id,
      status: status,
      statusCode: statusCode,
      message: message
    }
    const pushAlert = async () => {
      return processAlerts.push(newAlert)
    }

    const spliceAlert = async (index) => {
      return processAlerts.splice(index, newAlert)
    }

    let matchIdx;
    processAlerts.forEach((process, idx) => {
      if (process.id === id) {
        matchIdx = idx;
      }
    })

    if (matchIdx >= 0) {
      spliceAlert(matchIdx).then(r => {
        processCount++;
        alertsUpdated !== processCount && setAlertsUpdated(processCount)
      })
    } else {
      pushAlert().then(r => {
        processCount++;
        alertsUpdated !== processCount && setAlertsUpdated(processCount)
      })
    }
  }

  const deleteAlert = (id) => {
    const spliceAlert = async (index) => {
      return processAlerts.splice(index, 1)
    }

    let matchIdx;
    processAlerts.forEach((process, idx) => {
      if (process.id === id) {
        matchIdx = idx;
      }
    })
    if (matchIdx >= 0) {
      spliceAlert(matchIdx).then(r => {
        processCount++;
        setAlertsUpdated(processCount)
      })
    }
  }


  const handleSendStatus = () => {
    if (originalStatus !== status) {
      patchPropertyStatus(id, status, (r) => handleSendStatusSuccess(r), (r) => handleSendStatusError(r))
    }
  }

  const handleSendStatusSuccess = async (r) => {
    let message = `Updated property status from ${originalStatus.toUpperCase()} to ${status.toUpperCase()}`
    return setNewAlert('status', 'success', message, '')
  }

  const handleSendStatusError = async (r) => {
    let message = `Error updating Property Status from ${originalStatus.toUpperCase()} to ${status.toUpperCase()}`
    return setNewAlert('status', 'error', message, r.status)
  }

  const handleSendOpenHomes = (method) => {
    let newOpenHomes = []
    if (openHomesAltered) {
      if (props.isEdit) {
        let editedOpenHomes = []
        // Handle edited items
        openHomes.forEach(op => {
          if (op.isEdited === true) {
            delete op.isEdited
            editedOpenHomes.push(op)
          }
        })
        editedOpenHomes.forEach((edited, idx) => {
          let url = `${newId}/openhomes/${edited.id}`

          updatePropertiesData(url, edited,
            (r) => handleUpdateOpenHomesSuccess(r, idx, editedOpenHomes.length),
            (r) => handleUpdateOpenHomesError(r, idx, editedOpenHomes.length))
        })
        // On props.isEdit, new open homes are marked isNew: true
        openHomes.forEach(op => {
          if (op.isNew === true) {
            delete op.isNew
            newOpenHomes.push(op)
          }
        })

        // DELETE removed open homes  
        removedOpenHomes.forEach((removedId, idx) => {
          let url = `${newId}/openhomes/${removedId}`

          handleDeleteOpenHomesSuccess([], idx, removedOpenHomes.length)

          deletePropertiesData(url,
            (r) => handleDeleteOpenHomesSuccess(r, idx, removedOpenHomes.length),
            (r) => handleDeleteOpenHomesError(r, idx, removedOpenHomes.length))
        })
      } else {
        // If new property, all open homes are new
        newOpenHomes = openHomes
      }
      if (newOpenHomes.length) {
        let url = `${newId}/openhomes`

        createPropertiesData(url, newOpenHomes,
          (r) => handleCreateOpenHomesSuccess(r, newOpenHomes.length),
          (r) => handleCreateOpenHomesError(r, newOpenHomes.length))
      }
    } else { return }
  }

  const handleUpdateOpenHomesSuccess = async (r, idx, length) => {
    let message = `Updated Open Home ${idx + 1} of ${length}`
    setOpenHomesAltered(false)
    setSubmitStatus(prevState => ({
    ...prevState,
      openhomes: false, 
    }));
    return setNewAlert('openhome_update', 'success', message, '')
  }

  const handleUpdateOpenHomesError = async (r, idx, length) => {
    let message = `Error updating Open Home ${idx + 1} of ${length}`
    return setNewAlert('openhome_update', 'error', message, r.status)
  }

  const handleDeleteOpenHomesSuccess = (r, idx, length) => {
    let message = `Removed Open Home`
    setNewAlert('openhome_remove', 'success', message, '')
    setOpenHomesAltered(false)
    setRemovedOpenHomes([])
    setSubmitStatus(prevState => ({
    ...prevState,
      openhomes: false, 
    }));

  }

  const handleDeleteOpenHomesError = (r, idx, length) => {
    let message = `Error removing Open Home ${idx + 1} of ${length}`
    setNewAlert('openhome_remove_error', 'error', message, r.status)
  }

  const handleCreateOpenHomesSuccess = (r, length) => {
    let message = `Created ${length} new Open Home record${length > 1 ? 's' : ''}`
    setNewAlert('openhome_create', 'success', message, '')
    setOpenHomesAltered(false)
    setSubmitStatus(prevState => ({
    ...prevState,
      openhomes: false, 
    }));

  }

  const handleCreateOpenHomesError = (r, length) => {
    let message = `Error creating ${length} new Open Home record${length > 1 ? 's' : ''}`
    setNewAlert('openhome_create', 'error', message, r.status)
  }

  const handleSendAttachments = () => {
    if (attachmentsAltered) {
      let attachmentsArr = getArrObjCopy(attachments)
      let newAttachments = []
      let attachmentsToRemove = []
      let idx = originalAttachments.length

      if (props.isEdit) {
        originalAttachments.forEach(og => {
          if (!attachmentsArr.find(at => { return at.id === og.id })) {
            attachmentsToRemove.push(og.id)
          }
        })

        // Remove attachments
        attachmentsToRemove.forEach((attachmentId, i) => {
          let url = `${newId}/attachments/${attachmentId}`
          deletePropertiesData(url,
            (r) => handleRemoveAttachmentsSuccess(r, i, attachmentsToRemove.length),
            (r) => handleRemoveAttachmentsError(r, i, attachmentsToRemove.length))
        })
      }

      attachmentsArr.forEach(attachment => {
      
        let things = originalAttachments.find(og => {
          return og.id === attachment.id
        })
        if (!originalAttachments.find(og => {
          return og.id === attachment.id
        })) {
          newAttachments.push(attachment)
        }
      })
      
      
      // Create and assign new attachments
      let propertyId = props.isEdit ? id : newId;
      newAttachments.forEach((attachment, i) => {
        idx++;
        if (attachment.file) {
          let preSignedBody = {
            "id": Number(propertyId),
            "idType": "properties",
            "objectName": attachment.file.name,
            "objectType": attachment.file.type,
            "objectContentType": attachment.file.type
          }
          getPreSignedUrl(preSignedBody,
            (r) => addAttachmentToBucket(r, attachment, i, idx, newAttachments.length),
            (r) => handlePreSignedError(r, attachment))
        } else {
          handleAssignAttachment([], attachment, i, newAttachments.length)
        }
      })

      // Check if attachment has been edited (including position)
      attachmentsArr.forEach(attachment => {
        if (attachment.id && originalAttachments.find(og => {
          return (og.id === attachment.id && !(_.isEqual(og, attachment)))
        })) {
          let url = `admin/properties/${props.isEdit ? id : newId}/attachments/${attachment.id}`
          patchData(url, attachment,
            (r) => handlePatchAttachmentsSuccess(r),
            (r) => handlePatchAttachmentsError(r))
        }
      })
    } else {
      return
    }
  }

  const handlePatchAttachmentsSuccess = (r) => {
    let message = `Updated attachments`
    setNewAlert('attachments_order', 'success', message, '')
    setAttachmentsAltered(false)
        setSubmitStatus(prevState => ({
    ...prevState,
      attachments: false, 
    }));
  }

  const handlePatchAttachmentsError = (r) => {
    let message = `Error updating attachments`
    setNewAlert('attachments_order_error', 'error', message, r.status)
  }

  const handleRemoveAttachmentsSuccess = (r, idx, length) => {
    let message = `Removed Attachment ${idx + 1} of ${length}`
    setNewAlert('attachment_remove', 'success', message, '')
    setAttachmentsAltered(false)
        setSubmitStatus(prevState => ({
    ...prevState,
      attachments: false, 
    }));
  }

  const handleRemoveAttachmentsError = (r, idx, length) => {
    let message = `Error Removing Attachment ${idx + 1} of ${length}`
    setNewAlert('attachment_remove_error', 'error', message, r.status)
  }

  const handlePreSignedError = (r, file) => {
    let message = `Error getting presigned URL for uploaded file ${file.file.name}. Aborting upload.`
    setNewAlert('presigned_error', 'error', message, r.status)
  }

  const addAttachmentToBucket = (res, attachment, i, idx, length) => {
    let url = res.preSignedURL
    const reader = new FileReader()
    reader.readAsDataURL(attachment.file)

    uploadAttachment(attachment.file, attachment.file.type, url,
      (r) => handleUploadAttachmentSuccess(r, attachment, idx, length),
      (r) => handleUploadAttachmentError(r, attachment, idx, length))
  }

  const handleUploadAttachmentSuccess = (r, attachment, idx, length) => {
    let message = `Uploaded Attachment File ${attachment.file.name}`
    setNewAlert('attachment_upload', 'success', message, '')
    handleAssignAttachment(r, attachment, idx, length)
  }

  const handleUploadAttachmentError = (r, attachment, idx, length) => {
    let message = `Error Uploading Attachment File ${attachment.file.name}`
    setNewAlert('attachment_upload_error', 'error', message, r.status)
  }

  const handleAssignAttachment = (r, attachment, idx, length) => {
    let attachmentInfo = [
      {
        "mode": attachment.mode,
        "type": attachment.type,
        "url": attachment.url,
        "filename": null,
        "storageKey": null,
        "filetype": null,
        "mimetype": null,
        "title": attachment.title,
        "position": idx
        // TO DO - position assigned on reorder? 
        // "position": attachment.position
      }
    ]
    if (attachment.file) {
      attachmentInfo[0]["filename"] = attachment.file.name
      attachmentInfo[0]["storageKey"] = attachment.file.name
      attachmentInfo[0]["filetype"] = attachment.file.type
      attachmentInfo[0]["mimetype"] = attachment.file.name.split('.').pop()

    }
    let url = `${newId}/attachments`

    createPropertiesData(url, attachmentInfo,
      (r) => handleAssignAttachmentsSuccess(r, idx, length),
      (r) => handleAssignAttachmentsError(r, idx, length))
  }

  const handleAssignAttachmentsSuccess = (r, idx, length) => {
    let message = `Assigned new Attachment ${idx + 1} of ${length}`
    setNewAlert('attachment_assign', 'success', message, '')
    setAttachmentsAltered(false)
        setSubmitStatus(prevState => ({
    ...prevState,
      attachments: false, 
    }));

  }

  const handleAssignAttachmentsError = (r, idx, length) => {
    let message = `Error Assigning new Attachment ${idx + 1} of ${length}`
    setNewAlert('attachment_assign_error', 'error', message, r.status)
  }

  const handleSendAgents = () => {
    if (agentsAltered) {
      let agentsArr = getArrObjCopy(agents)
      let agentsFormatted = []
      agentsArr.forEach(ag => {
        agentsFormatted.push({ id: ag.id, position: ag.position })
      })
      let data = { "propertyAgents": agentsFormatted }
      agentsFormatted.length &&
        patchData(`admin/properties/${props.isEdit ? id : newId}/agents`, data, handlePatchAgentsSuccess, (r) => handlePatchAgentsError(r))
    } else {
      return
    }
  }

  const handlePatchAgentsSuccess = (r) => {
    let message = `Updated property agents`
    setNewAlert('agents_order', 'success', message, '')
    setAgentsAltered(false)
      setSubmitStatus(prevState => ({
        ...prevState,
        agents: false, 
    }));
  }

  const handlePatchAgentsError = (r) => {
    let message = `Error updating property agents`
    setNewAlert('agents_order_error', 'error', message, r.status)
  }


  const handleSendCategories = () => {
    if (categoriesAltered) {
      let categoriesArr = getArrObjCopy(categories)
      let categoriesToAssign = []
      let categoriesToRemove = []
      if (props.isEdit) {
        originalCategories.forEach(og => {
          if (!categoriesArr.find(cat => { return cat.id === og.id })) {
            categoriesToRemove.push(og.id)
          }
        })

        categoriesToRemove.forEach((catId, idx) => {
          let url = `${newId}/categories/${catId}`

          deletePropertiesData(url,
            (r) => handleRemoveCategoriesSuccess(r, idx, categoriesToRemove.length),
            (r) => handleRemoveCategoriesError(r, idx, categoriesToRemove.length))
        })
      }

      categoriesArr.forEach(cat => {
        if (!originalCategories.find(og => { return og.id === cat.id })) {
          categoriesToAssign.push(cat.id)
        }
      })

      let url = `${newId}/categories`
      let data = { "categoryIDs": categoriesToAssign }

      categoriesToAssign.length &&
        createPropertiesData(url, data,
          (r) => handleAssignCategoriesSuccess(r, categoriesToAssign.length),
          (r) => handleAssignCategoriesError(r, categoriesToAssign.length))
    }
  }

  const handleAssignCategoriesSuccess = (r, length) => {
    let message = `Assigned ${length} new ${length > 1 ? 'Categories' : 'Category'}`
    setNewAlert('categories_assign', 'success', message, '')
    setCategoriesAltered(false)
        setSubmitStatus(prevState => ({
    ...prevState,
      categories: false, 
    }));

  }

  const handleAssignCategoriesError = (r, length) => {
    let message = `Error Assigning ${length} new ${length > 1 ? 'Categories' : 'Category'}`
    setNewAlert('categories_assign_error', 'error', message, r.status)
  }

  const handleRemoveCategoriesSuccess = (r, idx, length) => {
    let message = `Removed Category ${idx + 1} of ${length}`
    setNewAlert('categories_remove', 'success', message, '')
    setCategoriesAltered(false)
        setSubmitStatus(prevState => ({
    ...prevState,
      categories: false, 
    }));
  }

  const handleRemoveCategoriesError = (r, idx, length) => {
    let message = `Error Removing Category ${idx + 1} of ${length}`
    setNewAlert('categories_remove_error', 'error', message, r.status)
  }


  const handleSendImages = () => {
    let idx = images.length ? images[images.length - 1].position : 0;
    if (imagesAltered) {
      let propertyId = props.isEdit ? id : newId;
      if (removedImages.length) {
        removedImages.forEach((removed, idx) => {
          let url = `${propertyId}/images/${removed.id} `
          deletePropertiesData(url,
            (r) => handleRemoveImageSuccess(r, idx, removedImages.length),
            (r) => handleRemoveImageError(r, idx, removedImages.length))
        })
      }
      if (newImages.length) {
        idx++;
        newImages.forEach((image, i) => {
          let preSignedBody = {
            "id": Number(propertyId),
            "idType": "properties",
            "objectName": image.file.name,
            "objectType": "images",
            "objectContentType": `image/${image.file.name.split('.').pop()} `
          }
          // Add pre signed body data
          getPreSignedUrl(preSignedBody,
            (r) => addImgToBucket(r, image, i, idx, newImages.length),
            (r) => handlePreSignedError(r))
        })
      }

      // Patch images if position changed? 
      if (imagePositionsAltered) {
        images.forEach((image, i) => {
          let url = `admin/properties/${propertyId}/images/${image.id}`
          patchData(url, image,
            () => handleUpdateImagesSuccess(i, images.length),
            () => handleUpdateImagesError(i, images.length))
        })
      }
    }
  }

  const handleUpdateImagesSuccess = (idx, imagesLength) => {
    if ((idx + 1) === imagesLength) {
      let message = `Updated image positions`
      setNewAlert('images_update', 'success', message, '')
    }
    setImagesAltered(false)
        setSubmitStatus(prevState => ({
    ...prevState,
      images: false, 
    }));

  }

  const handleUpdateImagesError = (idx, imagesLength) => {
    if ((idx + 1) === imagesLength) {
      let message = `Error updating image positions`
      setNewAlert('images_update_error', 'error', message, '')
    }
  }

  const handleRemoveImageSuccess = (r, idx, length) => {
    let message = `Removed Image ${idx + 1} of ${length}`
    setNewAlert('images_remove', 'success', message, '')
    setImagesAltered(false)
        setSubmitStatus(prevState => ({
    ...prevState,
      images: false, 
    }));
  }

  const handleRemoveImageError = (r, idx, length) => {
    let message = `Error Removing Image ${idx + 1} of ${length}`
    setNewAlert('images_remove_error', 'error', message, r.status)
  }

  const addImgToBucket = (res, image, i, idx, length) => {
    let url = res.preSignedURL
    let fileType = image.file.name.split('.').pop()
    const reader = new FileReader()
    reader.readAsDataURL(image.file)
    uploadImg(image.file, fileType, url,
      (r) => handleUploadImageSuccess(res, image, idx, length),
      (r) => handleUploadImageError(res, image, idx, length))
  }

  const handleUploadImageSuccess = (r, image, idx, length) => {
    let message = `Uploaded Image ${image.file.name}`
    setNewAlert(`upload_image_${image.file.name}`, 'success', message, '')
    handleAssignImage(r, image, idx, length)
  }

  const handleUploadImageError = (r, image,) => {
    let message = `Error Uploading Image ${image.file.name}`
    setNewAlert(`upload_image_error_${image.file.name}`, 'error', message, r.status)
  }

  const handleAssignImage = (r, image, idx, length) => {
    let imagesInfo = [
      {
        "filename": image.file.name,
        "storageKey": image.file.name,
        "position": idx,
        "description": null
      }
    ]
    let url = `${newId}/images`

    createPropertiesData(url, imagesInfo,
      (r) => handleAssignImageSuccess(r, image, idx, length),
      (r) => handleAssignImageError(r, image, idx, length))
  }

  const handleAssignImageSuccess = (r, image, idx, length) => {
    let message = `Assigned ${idx + 1} of ${length} new images to listing`
    setNewAlert('image_assign', 'success', message, '')
    setImagesAltered(false)
        setSubmitStatus(prevState => ({
    ...prevState,
      images: false, 
    }));

  }

  const handleAssignImageError = (r, image, idx, length) => {
    let message = `Error Assigning ${idx + 1} of ${length} new images to listing`
    setNewAlert('image_assign_error', 'error', message, r.status)
  }

  const handleAddress = (field, value) => {
    let newAddress = getObjCopy(address)
    newAddress[field] = value
    setAddress(newAddress)
  }

  const handleRegion = (region) => {
    let newAddress = getObjCopy(address)
    newAddress["state"] = region
    newAddress["city"] = regions.find(reg => reg.name === region)
      .districts[0]
      .name
    newAddress["suburb"] = regions.find(reg => reg.name === region)
      .districts[0]
      .suburbs[0]
    setAddress(newAddress)
    setRegion(region)
  }

  const handleDistrict = (district) => {
    let newAddress = getObjCopy(address)
    newAddress["city"] = district
    newAddress["suburb"] =
      regions.find(reg => reg.name === address["state"])
        .districts.find(dis => dis.name === district)
        .suburbs[0] || ''
    setAddress(newAddress)
  }

  const handleSuburb = (suburb) => {
    let newAddress = getObjCopy(address)
    newAddress["suburb"] = suburb
    setAddress(newAddress)
  }

  const handleAgents = (agent, method, idx) => {
    // Handles adding and removing of agents
    // let newAgents = getArrObjCopy(agents)
    // if (agents.find(ag => ag.id === agent.id)) {
    //   for (let i = 0; i < agents.length; i++) {
    //     if (agents[i].id === agent.id) {
    //       newAgents.splice(i, 1)
    //     }
    //   }
    // } else {
    //   newAgents.push(agent)
    // }
    let newAgents = getArrObjCopy(agents)
    let newAgentsAvailable = getArrObjCopy(agentsAvailable)

    switch (method) {
      case 'add': {
        newAgents.push(agent)
        newAgentsAvailable.splice(idx, 1)
        break;
      }
      case 'remove': {
        if (newAgents.length > 1) {
          newAgents.splice(idx, 1);
          newAgentsAvailable.push(agent)
        }
        break;
      }
      default: return false
    }

    setAgentsAvailable(newAgentsAvailable)
    setSelectedAgent(newAgentsAvailable[0])
    handleSetAgents(newAgents)
  }

  const handleSetAgents = (newAgents) => {
    setAgentsAltered(true)
    setAgents(newAgents)
  }

  const handleShowOpenHomeInfo = (idx) => {
    // Handles open/closed state of open home info textboxes
    // Shallow copy 
    let newInfos = [...openHomeInfoOpen]
    if (openHomeInfoOpen.includes(idx)) {
      newInfos.splice(idx, 1);
    } else {
      newInfos.push(idx)
    }
    setShowOpenHomeInfo(newInfos)
  }

  const handleAttachment = (method, where, value, idx) => {
    let newAttachments = getArrObjCopy(attachments)
    switch (method) {
      case 'add': {
        newAttachments.push({
          id: newAttachments.length,
          type: 'floorplan',
          url: '',
          title: '',
          file: '',
          mode: 'file',
          position: newAttachments.length - 1
        })
        break;
      }
      case 'remove': {
        if (newAttachments.length >= 1) {
          newAttachments.splice(idx, 1);
        } else {
          newAttachments[idx].where = ''
        }
        break;
      }
      case 'edit': {
        newAttachments[idx][where] = value
        break;
      }
      default: return false
    }
    !attachmentsAltered && setAttachmentsAltered(true)
    setAttachments(newAttachments)
  }

  const handleUploadAttachment = (idx, id) => {
    let newAttachmentsArr = getArrObjCopy(attachments)
    const inputFile = document.getElementById(`attachmentupload-${id}`).files[0]

    newAttachmentsArr[idx].file = inputFile
    newAttachmentsArr[idx].url = ''
    setAttachments(newAttachmentsArr)
  }

  const handleRemoveAttachmentFile = (idx, id) => {
    document.getElementById(`attachmentupload-${newId}`).value = "";
    let newAttachmentsArr = getArrObjCopy(attachments)
    newAttachmentsArr[idx].file = null
    setAttachments(newAttachmentsArr)
  }

  const handleFeature = (feature, method, idx) => {
    let newFeatures = getArrObjCopy(additionalFeatures)
    switch (method) {
      case 'add': {
        newFeatures.push({ feature: '', position: idx })
        break;
      }
      case 'remove': {
        if (newFeatures.length > 1) {
          newFeatures.splice(idx, 1);
        } else {
          newFeatures[idx].feature = ''
        }
        break;
      }
      case 'edit': {
        newFeatures[idx].feature = feature
        break;
      }
      default: return false
    }
    setFeatures(newFeatures)
  }

  const handleCategory = (category, catId) => {
    // Category ids:
    // Apartment - 3,
    // Homes - 18,
    // Waiheke Island & Coastal - 19

    let newCat = getArrObjCopy(categories)
    if (categories.find(c => c.name === category)) {
      for (let i = 0; i < categories.length; i++) {
        if (categories[i].name === category) {
          newCat.splice(i, 1)
        }
      }
    } else {
      // id: categoryIds[category]
      newCat.push({ id: catId, name: category })
    }
    !categoriesAltered && setCategoriesAltered(true)
    setCategories(newCat)
  }

  const handleOpenHome = (method, where, value, idx) => {
    let newOpenHomes;
    openHomes
      ? newOpenHomes = getArrObjCopy(openHomes)
      : newOpenHomes = []

    let newRemovedOpenHomes = []
    switch (method) {
      case 'add': {
        let newItem = {
          info: '',
          dayOn: handleDateFormat(value),
          startTime: '12:00pm',
          startTimeUnformatted: value,
          endTime: '12:30pm',
          endTimeUnformatted: value,
        }
        props.isEdit && (newItem.isNew = true)
        newOpenHomes.push(newItem)
        break;
      }
      case 'remove': {
        newRemovedOpenHomes.push(newOpenHomes[idx].id)
        newOpenHomes.splice(idx, 1);
        setRemovedOpenHomes((prevItems) => [...prevItems, newRemovedOpenHomes]);
        break;
      }
      case 'edit': {
        let formattedVal;
        if (where === 'dayOn') {
          formattedVal = handleDateFormat(value)
        } else if (where === 'info') {
          formattedVal = value
        } else {
          formattedVal = handleTimeFormat(value)
          newOpenHomes[idx][where + 'Unformatted'] = value
        }
        newOpenHomes[idx][where] = formattedVal
        props.isEdit && (newOpenHomes[idx].isEdited = true)
        break;
      }
      default: return false
    }
    !openHomesAltered && setOpenHomesAltered(true)
    setOpenHomes(newOpenHomes)
  }

  const handleTimeFormat = (time) => {
    // let hours = ("0" + time.getHours()).slice(-2);
    // let minutes = ("0" + time.getMinutes()).slice(-2);
    // let seconds = ("0" + time.getSeconds()).slice(-2);
    // return (`${hours}: ${minutes}: ${seconds}: +00`)

    let hours = time.getHours();
    let minutes = time.getMinutes();
    
    // Determine AM or PM suffix
    let ampm = hours >= 12 ? 'pm' : 'am';
    
    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If hour is 0, change it to 12
    
    // Pad minutes with a leading zero if needed
    minutes = minutes < 10 ? '0' + minutes : minutes;
    
    return hours + ':' + minutes + ampm;
  }

  const handleDateFormat = (date) => {
    let yyyy = date.getFullYear().toString();
    // getMonth() is zero-based   
    let mm = (date.getMonth() + 1).toString();
    let dd = date.getDate().toString();
    dd < 10 && (dd = '0' + dd)
    mm < 10 && (mm = '0' + mm)
    return yyyy + '-' + mm + '-' + dd + 'T00:00:00Z'
  }

  const reorder = (arr, method, idx, callback) => {
    let newArr = getArrObjCopy(arr)
    let toIdx;
    let item = newArr[idx]
    newArr.splice(idx, 1)
    method === 'up'
      ? toIdx = (idx - 1)
      : toIdx = (idx + 1)
    newArr.splice(toIdx, 0, item)
    newArr.forEach((item, idx) => {
      item.position = idx;
    })
    callback(newArr)
  }

  const attachmentsInputFix = (newArr) => {
    setAttachments(newArr)
    setAttachmentsAltered(true)
    newArr.forEach(attachment => {
      let inputItem = document.getElementById(`attachmentupload-${attachment.id}`)
      if (inputItem) {
        // TO DO handle input file render on reorder
      }
    })
  }

  const handleRemoveImage = (img, idx) => {
    let newImagesArr = getArrObjCopy(images)
    let removedImagesArr = getArrObjCopy(removedImages)
    newImagesArr.splice(idx, 1)
    removedImagesArr.push(img)
    setRemovedImages(removedImagesArr)
    setImages(newImagesArr)
    !imagesAltered && setImagesAltered(true)
  }

  const handleRemoveUploadedImage = (idx) => {
    let newImagesArr = getArrObjCopy(newImages)
    newImagesArr.splice(idx, 1)
    setNewImages(newImagesArr)
  }

  const undoRemoveImage = (img, idx) => {
    let newImagesArr = getArrObjCopy(images)
    let removedImagesArr = getArrObjCopy(removedImages)
    removedImagesArr.splice(idx, 1)
    newImagesArr.push(img)
    setRemovedImages(removedImagesArr)
    setImages(newImagesArr)
  }

  const handleImagePositions = (d) => {
    d.forEach((image, idx) => {
      image.position = idx + 1;
    })
    !imagesAltered && setImagesAltered(true)
    !imagePositionsAltered && setImagePositionsAltered(true)
    setImages(d)
  }

  const handleUpdateLocation = (loc) => {
    if (loc.geometry) {
      setLatitude(loc.geometry.location.lat())
      setLongitude(loc.geometry.location.lng())
      setLocDescription(loc.formatted_address)
    }
  }


  const handleModal = (open, img, idx, type) => {
    let url = img ? `${cloudFrontURL}/properties/${newId}/images/${img.storageKey}` : ''
    setModal({
      isOpen: open,
      imageUrl: url,
      id: img ? img.id : 0,
      idx: idx,
      topOffset: (!isServer ? (window.pageYOffset - 75) : 0),
      type: type
    })
  }

  return (
    <div className="kpod-form-container">
      <div className="alerts-container">
        {processAlerts.map((alert, index) => {
          return (
            // severity="warning"
            // severity="info"
            // severity="success"
            <div className="alert-item" key={alert.id}>
              <Alert severity={alert.status} key={alert.id + alert.status}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small">
                    <CloseIcon fontSize="inherit" onClick={() => deleteAlert(alert.id)} />
                  </IconButton>
                }>
                <AlertTitle className="alert-title">
                  {alert.status + ' '}
                  {alert.statusCode ? alert.statusCode : ''}
                </AlertTitle>
                {alert.message}
              </Alert>
            </div>
          )
        })}
      </div>
      <div className="kpod-form-tabs">
        <div className={`kpod-form-tab ${tab === 1 ? 'selected' : ''}`}
          onClick={() => setTab(1)}>
          Quick Entry
        </div>
        <div className={`kpod-form-tab ${tab === 2 ? 'selected' : ''}`}
          onClick={() => setTab(2)}>
          General
        </div>
        {/* <div className="kpod-form-tab" onClick={() => setTab(3)}>
          {`Desc & Feature`}
        </div>
        <div className="kpod-form-tab" onClick={() => setTab(4)}>
          Sale Method
        </div>
        <div className="kpod-form-tab" onClick={() => setTab(5)}>
          Maps
        </div> */}
        <div className={`kpod-form-tab ${tab === 3 ? 'selected' : ''}`}
          onClick={() => setTab(3)}>
          Open Homes
        </div>
        <div className={`kpod-form-tab ${tab === 4 ? 'selected' : ''}`}
          onClick={() => setTab(4)}>
          Images
        </div>
        <div className={`kpod-form-tab ${tab === 5 ? 'selected' : ''}`}
          onClick={() => setTab(5)}>
          Attachments
        </div>
      </div>
      {modal.isOpen &&
        <ImageModal
          handleModal={(open, img, idx, type) => handleModal(open, img, idx, type)}
          modal={modal}
          images={
            modal.type === 'new'
              ? newImages
              : modal.type === 'removed'
                ? removedImages
                : images}
          id={id}
        />
      }
      <div className="kpod-form-outer">
        <div className="row kpod-form-header-info">
          {props.isEdit && (tab === 1 || tab === 2) &&
            <div className="kpod-form-infotext column">
              <div className="row">
                <div className="kpod-form-infotext-header">
                  Created At:
                </div>
                {createdAtFormatted}
              </div>
              <div className="kpod-form-infotext row">
                <div className="kpod-form-infotext-header">
                  Updated At:
                </div>
                {updatedAtFormatted}
              </div>
            </div>
          }
          <div className={`row ${!props.isEdit && 'right'}`}>
            {props.isEdit && (tab === 1 || tab === 2) &&
              <div className="kpod-form-infotext infotext-column">
                <div className="kpod-form-infotext-header">
                  Property Id RealNZ
                </div>
                {/* {reaUniqueId} */}
              </div>
            }
            {props.isEdit && (tab === 1 || tab === 2) &&
              <div className="kpod-form-infotext infotext-column">
                <div className="kpod-form-infotext-header">
                  Property Web Id
                </div>
                {reaUniqueId}
              </div>
            }
            {/* TO DO: add Date sold if sold  */}
            {(tab === 1 || tab === 2) &&
              <div className="kpod-form-infotext infotext-column">
                <div className="kpod-form-infotext-header">
                  Property Status
                </div>
                <select name="Property Status"
                  onChange={(e) => setPropertyStatus(e.target.value.toLowerCase())}>
                  <option value="draft"
                    selected={status === 'unpublished'}>
                    Unpublished
                  </option>
                  <option value="live"
                    selected={status === 'published' || status === 'live'}>
                    Published
                  </option>
                  <option value="sold"
                    selected={(status === 'sold')}>
                    Sold
                  </option>
                  <option value="rented"
                    selected={(status === 'rented')}>
                    Rented
                  </option>
                  {/* <option value="leased"
                    selected={(status === 'leased')}>
                    Leased
                  </option> */}
                  <option value="withdrawn"
                    selected={status === 'withdrawn'}>
                    Withdrawn
                  </option>
                  <option value="archived"
                    selected={status === 'archived'}>
                    Archived
                  </option>
                </select>
              </div>
            }
          </div>
        </div>

        {/* <div className="kpod-form-inner"> */}

        {(tab === 1 || tab === 2) &&
          <div className="kpod-form-item">
            <div className="kpod-form-header">
              General Details
            </div>
            <div className="kpod-form-inner">
              <div className="kpod-form-header">
                Category
              </div>
              <div className="row kpod-form-checkbox">
                <label for="isHomes">Homes</label>
                <input type="checkbox"
                  id="isHomes"
                  placeholder="Homes?"
                  checked={categories.find(c => c.name === 'Homes')}
                  onChange={() => handleCategory('Homes', 18)}>
                </input>
              </div>
              <div className="row kpod-form-checkbox">
                <label for="isHomes">Apartments</label>
                <input type="checkbox"
                  id="isApartments"
                  placeholder="Apartments?"
                  checked={categories.find(c => c.name === 'Apartment')}
                  onChange={() => handleCategory('Apartment', 3)}
                ></input>
              </div>
              <div className="row kpod-form-checkbox">
                <label for="isCoastal">{`Waiheke Island & Coastal`}</label>
                <input type="checkbox"
                  id="publichide"
                  placeholder={`Waiheke Island & Coastal`}
                  checked={categories.find(c => c.name === `Waiheke Island & Coastal`)}
                  onChange={(e) => handleCategory(`Waiheke Island & Coastal`, 19)}
                ></input>
              </div>
              <div className="kpod-form-spacer"></div>
              <div className="kpod-form-spacer"></div>
              Unit
              <input type="text"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={address.subNumber}
                onChange={(e) => handleAddress("subNumber", e.target.value)}
              ></input>
              Number
              <input type="text"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={address.streetNumber}
                onChange={(e) => handleAddress("streetNumber", e.target.value)}
              ></input>
              Street Name
              <input type="text"
                className="kpod-form-input"
                required="true"
                placeholder="Street Name"
                value={address.street}
                onChange={(e) => handleAddress("street", e.target.value)}
              ></input>
              Hide Adress
              <select name="Hide Address" onChange={(e) => setShowAddress(e.target.value === "true" ? true : false)}>
                <option value={true}
                  selected={showAddress}>
                  Do not hide the Address
                </option>
                <option value={false}
                  selected={!showAddress}>
                  Hide the Address
                </option>
                {/* TO DO - where do these go?
                  <option value="Sales Support">Hide street number only</option>
                  <option value="Sales Manager">Hide street number and address</option> */}
              </select>
              Hide Suburb
              <select name="Hide Suburb" onChange={(e) => setShowSuburb(e.target.value === "true" ? true : false)}>
                <option value={true}
                  selected={showSuburb}>
                  Do not hide the Suburb
                </option>
                <option value={false}
                  selected={!showSuburb}>
                  Hide the Suburb
                </option>
                {/* TO DO - where do these go?
                  <option value="Sales Support">Hide street number only</option>
                  <option value="Sales Manager">Hide street number and address</option> */}
              </select>

              Region
              <select name="Regions" onChange={(e) => handleRegion(e.target.value)}>
                {regions && regions.length && regions.map((region, i) => {
                  return (
                    <option key={i} value={region.name}
                      selected={address["state"] === region.name}>
                      {region.name}
                    </option>
                  )
                })}
              </select>
              <div className="kpod-form-infotext">
                1. Select region for www.realestate.co.nz
              </div>
              District
              <select name="District" onChange={(e) => handleDistrict(e.target.value)}>
                {regions ? regions.length &&
                  regions.find(region => region.name === address["state"])
                    .districts.map((district, i) => {
                      return (
                        <option key={i} value={district.name}
                          selected={address["city"] && address["city"] === district.name}>
                          {district.name}
                        </option>
                      )
                    }) : console.log('no regions')}
              </select>
              <div className="kpod-form-infotext">
                2. Select district for www.realestate.co.nz
              </div>
              Suburb
              <select name="Suburb" onChange={(e) => handleSuburb(e.target.value)}>
                {address["suburb"] && address["suburb"].length ?
                  regions.find(r => r) &&
                  // TEMPORARY
                  regions.find(region => region.name === address["state"])
                    .districts.map(district => {
                      return (
                        district.name === address["city"] && district.suburbs.map((burb, i) => {
                          return (
                            <option key={i} value={burb}
                              selected={address["suburb"] === burb}>
                              {burb}
                            </option>
                          )
                        })
                      )
                    })
                  : <div></div>}
              </select>
              <div className="kpod-form-infotext">
                3. Select suburb for www.realestate.co.nz
              </div>
            </div>
            {/* </div> */}
          </div>}

        <div className="kpod-form-item">
          <div className="kpod-form-inner">
            <div className="kpod-form-header">
              Agents
              <div className="input-sm">
                <div>
                  <select name="Agents" onChange={(e) => {
                    let agent = agentsAvailable.find(a => a.displayName === e.target.value)
                    setSelectedAgent(agent)
                  }
                  }>
                    {agentsAvailable.map(agent => {
                      return (
                        <option value={agent.displayName}
                          key={agent.id}
                          selected={agent.id === selectedAgent.id}>
                          {agent.displayName}
                        </option>
                      )
                    })}
                  </select>
                  <i className="fa fa-plus-circle functions-btn"
                    aria-hidden="true"
                    style={{
                      padding: '5px',
                      color: '#707070',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleAgents(selectedAgent, 'add')}
                  ></i>
                </div>
                <div className="kpod-form-infotext">
                  Use this add icon to add the selected agent.
                </div>
              </div>
            </div>
            <div className="kpod-form-infotext">
              Top entry is the main agent/lister.
            </div>
            {agents.map((agent, idx) => {
              return (
                <div key={idx + 'agent'} className="row">
                  <input className="kpod-form-input input-sm"
                    value={agents[idx].displayName}
                    placeholder={agents[idx].displayName}
                    disabled="true">
                  </input>
                  <i className={`fa fa-arrow-up functions-btn functions-btn 
                        ${idx === 0 && 'btn-disabled'}`}
                    aria-hidden="true"
                    style={{
                      padding: '5px',
                      color: '#707070',
                      cursor: 'pointer'
                    }}
                    onClick={() => idx !== 0 && reorder(agents, 'up', idx, handleSetAgents)}
                  >
                  </i>
                  <i className={`fa fa-arrow-down functions-btn functions-btn
                        ${idx === agents.length - 1 && 'btn-disabled'}`}
                    aria-hidden="true"
                    style={{
                      padding: '5px',
                      color: '#707070',
                      cursor: 'pointer'
                    }}
                    onClick={() => idx !== agents.length - 1
                      && reorder(agents, 'down', idx, handleSetAgents)}
                  >
                  </i>
                  <i className="fa fa-times-circle functions-btn functions-btn"
                    aria-hidden="true"
                    key={idx + 'remove'}
                    style={{
                      padding: '5px',
                      color: '#707070',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleAgents(agent, 'remove', idx)}
                  ></i>
                </div>
              )
            })}
          </div>

          {props.isEdit &&
            <button className={`kpod-form-button ${!agentsAltered ? 'disabled' : ''}`}
              // onClick={() => handleSubmit(2)}
              onClick={() => handleSendAgents()}>
              Save Agents
            </button>
          }
        </div>

        {(tab === 1 || tab === 2) &&
          <div className="kpod-form-item">
            <div className="kpod-form-header">
              {`Desc & Features`}
            </div>
            <div className="kpod-form-inner">
              Land Size
              <input type="text"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={landArea}
                onChange={(e) => setLandArea(e.target.value)}
              ></input>
              Floor Area
              <input type="text"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={floorArea}
                onChange={(e) => setFloorArea(e.target.value)}></input>
              Bedrooms
              <input type="number"
                min="0"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={bedrooms}
                onChange={(e) => setBedrooms(parseInt(e.target.value))}></input>
              Bathrooms
              <input type="number"
                min="0"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={bathrooms}
                onChange={(e) => setBathrooms(parseInt(e.target.value))}></input>
              Garage Spaces
              <input type="number"
                min="0"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={garages}
                onChange={(e) => setGarages(parseInt(e.target.value))} ></input>
              Off Street Parking
              <input type="number"
                min="0"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={offstreetParking}
                onChange={(e) => setOffStreetParking(parseInt(e.target.value))}></input>
              Main Heading
              <input type="text"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={mainHeading}
                onChange={(e) => setMainHeading(e.target.value)}
              ></input>
            </div>
            <div className="kpod-form-item">
              <div className="kpod-form-header">
                Main Text
              </div>
              <div className="kpod-form-inner">
                <MCETextEditor
                  description={mainText}
                  setDescription={(desc) => setMainText(desc)}
                />
              </div>
            </div>
            <div className="kpod-form-header">
              Additional Features
              <i className="fa fa-plus-circle functions-btn"
                aria-hidden="true"
                style={{
                  padding: '5px',
                  color: '#707070',
                  cursor: 'pointer'
                }}
                onClick={() => handleFeature('', 'add', (additionalFeatures.length))}
              ></i>
            </div>
            <div className="kpod-form-inner">
              {additionalFeatures.map((f, idx) => {
                return (
                  <div key={idx + 'feature'}>
                    <input type="text"
                      className="kpod-form-input input-sm"
                      required="true"
                      placeholder={f.feature}
                      value={f.feature}
                      onChange={(e) => handleFeature(e.target.value, 'edit', idx)}
                    ></input>
                    <i className={`fa fa-arrow-up functions-btn functions-btn 
                        ${idx === 0 && 'btn-disabled'}`}
                      aria-hidden="true"
                      style={{
                        padding: '5px',
                        color: '#707070',
                        cursor: 'pointer'
                      }}
                      onClick={() => idx !== 0 && reorder(additionalFeatures, 'up', idx, setFeatures)}></i>
                    <i className={`fa fa-arrow-down functions-btn functions-btn
                      ${idx === additionalFeatures.length - 1 && 'btn-disabled'}`}
                      aria-hidden="true"
                      style={{
                        padding: '5px',
                        color: '#707070',
                        cursor: 'pointer'
                      }}
                      onClick={() => idx !== additionalFeatures.length - 1
                        && reorder(additionalFeatures, 'down', idx, setFeatures)}>
                    </i>
                    <i className="fa fa-times-circle functions-btn functions-btn"
                      aria-hidden="true"
                      key={idx + 'remove'}
                      style={{
                        padding: '5px',
                        color: '#707070',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleFeature(f, 'remove', idx)}
                    ></i>
                  </div>
                )
              })}
            </div>
          </div>
        }

        {(tab === 1 || tab === 2) &&
          <div className="kpod-form-item">
            <div className="kpod-form-header">
              Sale Method
            </div>
            <div className="kpod-form-input-header">
              Listed date
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker value={listedDate} onChange={(e) => setListedDate(e)} />
            </MuiPickersUtilsProvider>
            <div className="kpod-form-infotext">
              Enter date format DD/MM/YYYY or choose date by clicking on the calendar.
            </div>
            <div className="kpod-form-input-header">
              Listing expiry date
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker value={expiryDate} onChange={(e) => setExpiryDate(e)} />
            </MuiPickersUtilsProvider>
            <div className="kpod-form-infotext">
              Enter date format DD/MM/YYYY or choose date by clicking on the calendar.
            </div>
            <div className="kpod-form-input-header">
              Sold on date
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker value={soldOn} onChange={(e) => setSoldOn(e)} />
            </MuiPickersUtilsProvider>
            <div className="kpod-form-infotext">
              Enter date format DD/MM/YYYY or choose date by clicking on the calendar.
            </div>
            <div className="kpod-form-input-header">
              Sale Method
            </div>
            <select name="Sale Method" onChange={(e) => setSaleMethod(e.target.value)}>
              <option value="For Sale"
                selected={saleMethod === 'For Sale'}>
                For Sale
              </option>
                <option value="Rental"
                selected={saleMethod === 'Rental'}>
                For Rent
              </option>
              <option value="Auction"
                selected={saleMethod === 'Auction'}>
                Auction
              </option>
              <option value="Tender"
                selected={saleMethod === 'Tender'}>
                Tender
              </option>
              <option value="Set Sale"
                selected={saleMethod === 'Set Sale'}>
                Set Sale
              </option>
              <option value="International Tender"
                selected={saleMethod === 'International Tender'}>
                International Tender
              </option>
              <option value="International Set Sale"
                selected={saleMethod === 'International Set Sale'}>
                International Set Sale
              </option>
            </select>

            {saleMethod === 'Auction' ?
              <div className="column">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="kpod-form-input-header">
                    Auction Date
                  </div>
                  <DatePicker
                    value={auctionDate && auctionDate}
                    onChange={(e) => setAuctionDate(e)} />
                  <div className="kpod-form-input-header">
                    Auction Time
                  </div>
                  <TimePicker
                    value={auctionTime && auctionTime.timeUnformatted}
                    onChange={(e) => setAuctionTime(e)} />
                  <div className="kpod-form-input-header">
                    Auction Location
                  </div>
                  <select name="Auction Location" onChange={(e) => {
                    e.target.value === "Auction Other"
                      ? setAuctionLocationOther(' ')
                      : auctionLocationOther && setAuctionLocationOther(null)
                    setAuctionLocation(e.target.value)
                  }}>
                    <option value="On Site"
                      selected={auctionLocation === 'On Site'}>
                      On Site
                    </option>
                    <option value="4 Drake St, Freemans Bay"
                      selected={auctionLocation === '4 Drake St, Freemans Bay'}>
                      4 Drake St, Freemans Bay [Kellands Office]
                    </option>
                    <option value="279 Remuera Road, Remuera [Kellands Office"
                      selected={auctionLocation === '279 Remuera Road, Remuera [Kellands Office'}>
                      279 Remuera Road, Remuera [Kellands Office]
                    </option>
                    <option value="Auction Other"
                      selected={auctionLocationOther}>
                      Other...
                    </option>
                  </select>
                  {auctionLocationOther &&
                    <input
                      onChange={(e) => {
                        auctionLocation.length && setAuctionLocation('')
                        setAuctionLocationOther(e.target.value)
                      }}>
                    </input>}
                </MuiPickersUtilsProvider>
              </div>
              : <div />
            }
            {((saleMethod === 'Auction') ||
              (saleMethod === 'Tender') ||
              (saleMethod === 'International Tender')) &&
              <div className="column">
                <div className="kpod-form-input-header">
                  Unless Sold Prior
                </div>
                <div className="row">
                  <label for="isSoldPrior">
                    Tick unless sold prior by private treaty
                  </label>
                  <input
                    type="checkbox"
                    id="isSoldPrior"
                    placeholder=""
                    checked={auctionIsSoldPrior}>
                  </input>
                </div>
              </div>
            }
            {saleMethod === 'Rental' && (
              <div className="kpod-form-inner">
                <div className="kpod-form-input-header">
                 Hide Price
                </div>
                <select name="Hide Price" onChange={(e) => setShowPrice(e.target.value === "true" ? true : false)}>
                  <option value={true}
                    selected={showPrice}>
                    Show the Price
                  </option>
                  <option value={false}
                    selected={!showPrice}>
                    Hide the Price
                  </option>
                </select>
                <div className="kpod-form-input-header">
                  Price
                </div>
                    
              <input type="number"
                min="0"
                className="kpod-form-input"
                required="true"
                placeholder=""
                value={price}
                onChange={(e) => setPrice(parseInt(e.target.value))}
              ></input>
               
              </div>)}
            <div className="kpod-form-input-header">
              Display Sale Details
            </div>
            <input
              type="text"
              className="kpod-form-input"
              required="true"
              placeholder=""
               onChange={(e) => setDisplaySaleDetails(e.target.value)}
              value={displaySaleDetails}>
            </input>
            <div className="kpod-form-input-header">
              By Negotiation
            </div>
            <div className="row">
              <label for="isHomes">
                Tick if this sale is by negotiation
              </label>
              <input
                type="checkbox"
                id="isHomes"
                placeholder="Homes?"
                checked={byNegotiation}
                onChange={handleCheckboxChange}
                >
              </input>
            </div>
          </div>}

        {(tab === 1 || tab === 2) &&
          <div className="kpod-form-item">
            <div className="kpod-form-header">
              Google Maps
            </div>
            <div className="kpod-form-inner">
              <Map
                lat={lat}
                lng={lng}
                mapZoom={mapZoom}
                locDescription={locDescription}
                setLatitude={() => setLatitude()}
                setLongitude={() => setLongitude()}
                setMapZoom={() => setMapZoom()}
                updateLocation={(loc) => handleUpdateLocation(loc)}
                isEdit={props.isEdit}
              />
            </div>
            {props.isEdit &&
              <button className="kpod-form-button"
                onClick={() => handleSubmit(false)}>
                Save General Settings
              </button>}
          </div>}

        {(tab === 1 || tab === 3) &&
          <div className="kpod-form-item">
            <div className="kpod-form-header">
              Open Homes
              <i className="fa fa-plus-circle functions-btn"
                aria-hidden="true"
                style={{
                  padding: '5px',
                  color: '#707070',
                  cursor: 'pointer'
                }}
                onClick={() => handleOpenHome('add', '', new Date(), (openHomes ? openHomes.length : 0))}
              ></i>
            </div>
            <div className="kpod-form-inner">
              <div className="kpod-form-infotext">
                Enter date format DD/MM/YYYY and time format HH:MM or pick date/time by clicking on the calendar/clock.
                Use the pencil icon to provide additional information to an open home date.
              </div>
              {openHomes.length ? openHomes.map((openHome, idx) => {
                return (
                  <div className="column" key={idx + 'openhome'}>
                    <div className="row kpod-form-input-header">
                      <i className="fa fa-pencil-square-o functions-btn"
                        aria-hidden="true"
                        style={{
                          padding: '5px',
                          color: '#707070',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleShowOpenHomeInfo(idx)}></i>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="column">

                          Date
                          <DatePicker
                            value={openHome.dayOn}
                            onChange={(e) => handleOpenHome('edit', 'dayOn', e, idx)} />
                        </div>
                        <div className="column">
                          Start Time
                          <TimePicker
                            value={openHome['startTimeUnformatted']}
                            onChange={(e) => handleOpenHome('edit', 'startTime', e, idx)} />
                        </div>
                        <div className="column">
                          End Time
                          <TimePicker
                            value={openHome['endTimeUnformatted']}
                            onChange={(e) => handleOpenHome('edit', 'endTime', e, idx)} />
                        </div>
                      </MuiPickersUtilsProvider>
                      {/* <i className={`fa fa-arrow-up functions-btn
                        ${idx === 0 && 'btn-disabled'}`}
                          aria-hidden="true"
                          style={{
                            padding: '5px',
                            color: '#707070',
                            cursor: 'pointer'
                          }}
                          onClick={() => idx !== 0 && reorder(openHomes, 'up', idx, setOpenHomes)}></i>
                        <i className={`fa fa-arrow-down functions-btn
                          ${idx === openHomes.length - 1 && 'btn-disabled'}`}
                          aria-hidden="true"
                          style={{
                            padding: '5px',
                            color: '#707070',
                            cursor: 'pointer'
                          }}
                          onClick={() => idx !== openHomes.length - 1 &&
                            reorder(openHomes, 'down', idx, setOpenHomes)}>
                        </i> */}
                      <i className="fa fa-times-circle functions-btn"
                        aria-hidden="true"
                        key={idx + 'remove'}
                        style={{
                          padding: '5px',
                          color: '#707070',
                          cursor: 'pointer'
                        }}
                        onClick={(e) => handleOpenHome('remove', null, null, idx)}
                      ></i>
                    </div>
                    {openHomeInfoOpen.includes(idx) &&
                      <div className="column kpod-form-extra-infotext kpod-form-infotext"
                        key={idx + 'openhomeinfo'}>
                        Information
                        <input type="text"
                          className="kpod-form-input"
                          placeholder=""
                          value={openHome.info}
                          onChange={(e) => handleOpenHome('edit', 'info', e.target.value, idx)}
                        ></input>
                      </div>
                    }
                  </div>
                )
              }) : <div>No open homes to show</div>}
            </div>
            {props.isEdit &&
              <button
                className={`kpod-form-button ${!openHomesAltered ? 'disabled' : ''}`}
                onClick={() => handleSendOpenHomes()}>
                Save Open Homes
              </button>
            }
          </div>}

        {(tab === 1 || tab === 4) &&
          <div className="kpod-form-item">
            <div className="row space-between">
              <div className="kpod-form-header">
                Images
              </div>
              <div className="kpod-form-subheader row">
                <div className="">
                  Add Images
                </div>
                <div className="kpod-upload-container">
                  <input id="propertyimg-upload"
                    type="file" placeholder=""
                    key="imgupload"
                    name="imgupload"
                    accept="image/*"
                    multiple="multiple"
                    onChange={() => handleUploadImage()}></input>
                </div>
              </div>
            </div>
            <div className="kpod-form-infotext">
              Add more images by using the field above. Click the Save button to upload your images.
              Once images are uploaded, the image order can be easily changed by selecting 'Order images' and dragging the thumbnails to the desired position.
              Use the image icon to preview and the delete icon to delete.
              {orderingImages ?
                <div className="kpod-a-form-btn" onClick={() => setOrderingImages(false)}>
                  Close Order Images
                  <i className="fa fa-times-circle functions-btn"
                    aria-hidden="true"
                    style={{
                      padding: '5px',
                      color: '#707070',
                      cursor: 'pointer',
                      fontSize: '16px',
                      marginTop: '2px'
                    }}
                  ></i>
                </div>
                : images.length ?
                  <div className="kpod-a-form-btn" onClick={() => setOrderingImages(true)}>
                    Order Images
                  </div> : <div />}
            </div>
            <div className="kpod-items-container">
              {orderingImages ?
                <DragDropContainer
                  data={images}
                  propertyId={id}
                  dragType="image"
                  setData={(d) => handleImagePositions(d)}
                />
                :
                images.map((img, idx) => {
                  return (
                    <div className="kpod-img-thumbnail-container">
                      <img src={`${cloudFrontURL}/properties/${newId}/images/${img.storageKey}`}
                        className="kpod-img-thumbnail"
                        onClick={() => handleModal(true, img, idx, 'images')}
                        alt=""
                      />
                      <div className="row center">
                        <i className="fa fa-picture-o functions-btn"
                          aria-hidden="true"
                          style={{
                            padding: '5px',
                            color: '#707070',
                            cursor: 'pointer'
                          }}
                          onClick={() => handleModal(true, img, idx, 'images')}
                        ></i>
                        <span title="Remove Image">
                          <i className="fa fa-times-circle functions-btn"
                            aria-hidden="true"
                            style={{
                              padding: '5px',
                              color: '#707070',
                              cursor: 'pointer',
                              fontSize: '20px',
                              marginTop: '-3px'
                            }}
                            onClick={() => handleRemoveImage(img, idx)}
                          ></i>
                        </span>
                      </div>
                    </div>
                  )
                })}
            </div>
            {newImages.length ?
              <div className="kpod-form-inner">
                <div className="kpod-form-item-header">
                  New Images
                </div>
                <div className="kpod-items-container">
                  {newImages.map((img, idx) => {
                    return (
                      <div className="kpod-img-thumbnail-container">
                        <img src={img.url}
                          className="kpod-img-thumbnail"
                          onClick={() => handleModal(true, img, idx, 'new')}
                          alt=""
                        />
                        <div className="row center">
                          <i className="fa fa-picture-o functions-btn"
                            aria-hidden="true"
                            style={{
                              padding: '5px',
                              color: '#707070',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleModal(true, img, idx, 'new')}
                          ></i>
                          <span title="Remove Image">
                            <i className="fa fa-times-circle functions-btn"
                              aria-hidden="true"
                              style={{
                                padding: '5px',
                                color: '#707070',
                                cursor: 'pointer',
                                fontSize: '20px',
                                marginTop: '-3px'
                              }}
                              onClick={() => handleRemoveUploadedImage(idx)}
                            ></i>
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              : <div />}


            {removedImages.length ?
              <div className="kpod-form-inner">
                <div className="kpod-form-item-header">
                  Removed Images
                </div>

                <div className="kpod-items-container">
                  {removedImages.map((img, idx) => {
                    return (
                      <div className="kpod-img-thumbnail-container">
                        <img src={`${cloudFrontURL}/properties/${newId}/images/${img.storageKey}`}
                          className="kpod-img-thumbnail"
                          onClick={() => handleModal(true, img, idx, 'removed')}
                          alt=""
                        />
                        <div className="row center">
                          <i className="fa fa-picture-o functions-btn"
                            aria-hidden="true"
                            style={{
                              padding: '5px',
                              color: '#707070',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleModal(true, img, idx, 'removed')}
                          ></i>
                          <span title="Undo Remove Image">
                            <i className="fa fa-undo functions-btn"
                              aria-hidden="true"
                              style={{
                                padding: '5px',
                                color: '#707070',
                                cursor: 'pointer',
                                fontSize: '20px',
                                marginTop: '-3px'
                              }}
                              onClick={() => undoRemoveImage(img, idx)}
                            ></i>
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              : <div />}
            {props.isEdit &&
              <button className={`kpod-form-button ${!imagesAltered ? 'disabled' : ''}`}
                onClick={() => handleSendImages()}>
                Save Images
              </button>
            }
          </div>}

        {(tab === 1 || tab === 5) &&
          <div className="kpod-form-item">
            <div className="kpod-form-header">
              Attachments
              <i className="fa fa-plus-circle functions-btn"
                aria-hidden="true"
                style={{
                  padding: '5px',
                  color: '#707070',
                  cursor: 'pointer'
                }}
                onClick={() => handleAttachment('add', null, null, (attachments.length))}
              ></i>
              <div className="kpod-form-infotext">
                Use this add icon to add more attachments.
              </div>
            </div>
            <div className="kpod-form-inner">
              <div className="kpod-form-infotext">
                This section is reserved to enter links to other website or upload files from your local filesystem.
                Select the type of attachment from the dropdown list and then decide whether you want to add a URL or upload a file.
                You may rearrange the order when there are multiple attachments available.
              </div>
              {attachments.map((attachment, idx) => {
                return (
                  <div className="row" key={idx + 'attachment'}>
                    <div className="kpod-form-inner">
                      <div className="kpod-form-input-header">
                        Type of Attachment
                      </div>
                      <select name="Attachment Type" key={idx + 'attachment-select'}
                        onChange={(e) => handleAttachment('edit', 'type', e.target.value, idx)}>
                        <option value="floorplan"
                          selected={attachment.type === 'floorplan'}>
                          Interactive Floor Plan
                        </option>
                        <option value="video"
                          selected={attachment.type === 'video'}>
                          Video
                        </option>
                        <option value="virtualtour"
                          selected={attachment.type === 'virtualtour'}>
                          Virtual Tour
                        </option>
                        <option value="freereports"
                          selected={attachment.type === 'freereports'}>
                          Free Reports
                        </option>
                        <option value="website"
                          selected={attachment.type === 'website'}>
                          Website
                        </option>
                        <option value="document"
                          selected={attachment.type === 'document'}>
                          Document
                        </option>
                      </select>

                      <div className="kpod-form-input-header">
                        Mode of Attachment
                      </div>
                      <select name="Attachment Mode" key={idx + 'attachment-mode-select'}
                        onChange={(e) => handleAttachment('edit', 'mode', e.target.value, idx)}>
                        <option value="url"
                          selected={attachment.mode === 'url'}>
                          Link
                        </option>
                        <option value="file"
                          selected={attachment.mode === 'file'}>
                          File
                        </option>
                        <option value="embed"
                          selected={attachment.mode === 'embed'}>
                          Embed
                        </option>
                      </select>

                      <div className="kpod-form-input-header">
                        Enter URL or Upload File
                      </div>
                      <input type="text"
                        className="kpod-form-input"
                        value={attachment.title}
                        placeholder="Display Title"
                        onChange={(e) => handleAttachment('edit', 'title', e.target.value, idx)}>
                      </input>
                      <input type="text"
                        className="kpod-form-input"
                        placeholder="URL"
                        value={attachment.url}
                        disabled={attachments[idx].file}
                        onChange={(e) => handleAttachment('edit', 'url', e.target.value, idx)}>
                      </input>

                      <div className="kpod-upload-container" key={`attachmentinput-${idx}`}>
                        <input id={`attachmentupload-${attachment.id}`}
                          type="file"
                          placeholder=""
                          name="imgupload"
                          accept="*"
                          onChange={() => handleUploadAttachment(idx, attachment.id)}>
                        </input>
                        {attachments[idx].filename ?
                          <span title="Remove file">
                            <i className="fa fa-times-circle functions-btn"
                              aria-hidden="true"
                              key={idx + 'remove'}
                              style={{
                                padding: '5px',
                                color: '#707070',
                                cursor: 'pointer'
                              }}
                              onClick={() => handleRemoveAttachmentFile(idx, attachment.id)}
                            ></i>
                          </span>
                          : <div />}
                      </div>

                    </div>
                    <div className="fa-buttons-container">
                      <i className={`fa fa-arrow-up functions-btn
                      ${idx === 0 && 'btn-disabled'}`}
                        aria-hidden="true"
                        style={{
                          padding: '5px',
                          color: '#707070',
                          cursor: 'pointer'
                        }}
                        onClick={() => idx !== 0 && reorder(attachments, 'up', idx, attachmentsInputFix)}></i>
                      <i className={`fa fa-arrow-down functions-btn
                        ${idx === attachments.length - 1 && 'btn-disabled'}`}
                        aria-hidden="true"
                        style={{
                          padding: '5px',
                          color: '#707070',
                          cursor: 'pointer'
                        }}
                        onClick={() => idx !== attachments.length - 1 &&
                          reorder(attachments, 'down', idx, attachmentsInputFix)}>
                      </i>
                      <i className="fa fa-times-circle functions-btn"
                        aria-hidden="true"
                        key={idx + 'remove'}
                        style={{
                          padding: '5px',
                          color: '#707070',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleAttachment('remove', null, null, idx)}
                      ></i>
                    </div>
                  </div>
                )
              })}
            </div>
            {props.isEdit &&
              <button className={`kpod-form-button ${!attachmentsAltered ? 'disabled' : ''}`}
                onClick={() => handleSendAttachments()}>
                Save Attachments
              </button>
            }
          </div>}

        {tab === 1 &&
          <button className="kpod-form-button"
            onClick={() => handleSubmit(true)}>
            Save All
          </button>
        }
      </div>
    </div >
  )
}

export default PropertyForm