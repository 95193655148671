import App from './client/App';
import { BrowserRouter, Router } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { createBrowserHistory, createMemoryHistory } from 'history'

import { isServer } from './client/isServer'

export const history = isServer
  ? createMemoryHistory({
      initialEntries: ['/'],
    })
  : createBrowserHistory();


hydrate(
  <BrowserRouter>
    <App history={history} />
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
