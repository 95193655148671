
import React, { useState, useEffect } from 'react'
import { search } from '../api.js'

const Search = props => {
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (query.length) {
      return
    } else if (props.clearSearchQuery && query.length) {
      setQuery('')
    }
  }, [props, query])

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSetQuery = (e) => {
    setQuery(e.target.value)
  }

  const handleSearch = () => {
    let newQuery = query;
    let isAdmin = false
    let where = props.searchType;
    if (props.searchType === 'users') {
      isAdmin = true;
      let handler;
      let spec;
      if (props.subPage.includes('admin')) {
        handler = 'role'
        spec = 'administrator'
      } else {
        handler = 'status'
        spec = props.dataFilter
      }
      // let navInfo = navOptions.find(option => option.name === 'users');
      // let filtered = navInfo.subMenus.find(subMenu => subMenu.aka === props.dataFilter)
      newQuery += `&${handler}=${spec}`
    } else if (props.searchType === 'home') {
      where = 'properties'
    }
    let encoded = encodeURI(newQuery)
    search(where, isAdmin, encoded, '', handleSearchData, handleSearchError)
    props.setSearchTerm(query)
    if (props.clearSearchQuery) {
      props.setClearSearchQuery(false)
    }
  }

  const handleSearchData = (data) => {
    if (!data) { data = [{ id: 'sample data' }] }
    props.setSearchData(data)
    props.setIsSearchData(true)
  }

  const handleSearchError = (data) => {
    props.setSearchData([])
    props.setIsSearchData(true)
    // TO DO - Set Error as header in Main
  }

  return (
    <div className="column kpod-banner-search-container">
      <div className="kpod-banner-search-title">
        Search {props.searchType === 'home'
          ? 'properties'
          : props.searchType === 'users'
            ? props.subPage
            : props.searchType}
      </div>
      <div id="search-input"
        className=""
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}>
        <input
          placeholder=""
          type="text"
          className="kpod-form-input"
          style={{
            width: '100%'
          }}
          value={query}
          onChange={(e) => handleSetQuery(e)}
          onKeyPress={handleKeypress} />
        <span className="fa fa-search kpod-search"
          style={{
            content: '\f002',
            color: '#ec008c',
            cursor: 'pointer',
            fontSize: '20px'
          }}
          onClick={() => handleSearch()}
        />
      </div>
    </div>
  )
}

export default Search