
const regions = [
  { 
    name: "",
    districts: [
      {
        name: "",
        suburbs: [""]
      }
    ]
  },
  {
    name: "Auckland", 
    districts: [
      {
        name: "Auckland City",
        suburbs: [
          "Arch Hill", 
          "Arran Bay",
          "Auckland City",
          "Avondale",
          "Balmoral",
          "Blackpool",
          "Blockhouse Bay",
          "Broadway Park",
          "Central Park",
          "Church Bay",
          "City",
          "Cowe's Bay",
          "Cox's Bay",
          "Eden Terrace",
          "Ellerslie",
          "Enclosure Bay",
          "Epsom",
          "Freemans Bay",
          "Glen Innes",
          "Glendowie",
          "Grafton",
          "Great Barrier Island",
          "Greenlane",
          "Greenwoods Corner",
          "Grey Lynn",
          "Hekerua Bay",
          "Herne Bay",
          "Hillsborough - Auckland City",
          "Kennedy Point",
          "Kenny Point",
          "Kingsland",
          "Kohimarama",
          "Little Oneroa",
          "Lynfield",
          "Matiatia Estate",
					"Meadowbank",
					"Mechanics Bay",
					"Mission Bay",
					"Morningside - Auckland City",
					"Mt Albert",
					"Mt Eden",
					"Mt Roskill",
					"Mt Roskill South",
					"Mt Wellington",
					"New Windsor",
					"Newmarket",
					"Newton",
					"Okupu",
					"Omiha",
					"One Tree Hill",
					"Onehunga",
					"Oneroa",
					"Onetangi",
					"Orakei",
					"Oranga",
					"Orapiu",
					"Ostend",
					"Otahuhu",
					"Other Islands",
					"Owairaka",
					"Palm Beach",
					"Panmure",
					"Parnell",
					"Penrose",
					"Point England",
					"Ponsonby",
					"Pt Chevalier",
					"Pt England",
					"Rakino Island",
					"Remuera",
					"Rocky Bay",
					"Rosebank",
					"Royal Oak",
					"Sandringham",
					"Sandy Bay",
					"Southdown",
					"St Heliers",
					"St Johns",
					"St Lukes",
					"St Marys Bay",
					"Surfdale",
					"Sylvia Park",
					"Tamaki",
					"Te Matuku Bay",
					"Te Papapa",
					"Te Whau",
					"Three Kings",
					"Tryphena",
					"Waiheke Island",
					"Waikopou",
					"Waikowhai",
					"Waiotaiki Bay",
					"Waterview",
					"Wesley",
					"Western Springs",
					"Westfield",
					"Westmere",
					"Whakanewha"
        ]
      },
      {
        name: "Franklin",
        suburbs: [
          "Aka Aka",
          "Ararimu",
          "Awhitu",
          "Awhitu Central",
          "Big Bay",
          "Bombay",
          "Buckland",
          "Clarks Beach",
          "Franklin Surrounds",
          "Glenbrook",
          "Glenbrook Beach",
          "Grahams Beach",
          "Harrisville",
          "Helvetia",
          "Hunua",
          "Hunua Regional Parkland",
          "Kaiaua",
          "Karaka",
          "Kariotahi",
          "Kellyville",
          "Kingseat",
          "Kohekohe",
          "Mangatangi",
          "Mangatawhiri",
          "Manukau Heads",
          "Matakawau",
          "Matakawau Point",
          "Mauku",
          "Mercer",
          "Miranda",
          "Mission Bush",
          "Moumoukai",
          "Onewhero",
          "Orua Bay",
          "Otaua",
          "Paerata",
          "Paparata",
          "Paparimu",
          "Patumahoe",
          "Pokeno",
          "Pollok",
          "Port Waikato",
          "Pukekawa",
          "Pukekohe",
          "Pukekohe East",
          "Pukeoware",
          "Puni",
          "Ramarama",
          "Taurangaruru",
          "Te Hihi",
          "Te Kohanga",
          "Te Toro",
          "Tuakau",
          "Waiau Beach",
          "Waiau Pa",
          "Waikaretu",
          "Waipipi",
          "Waiuku",
          "Whakatiwai",
          "Whangarata",
          "Wharekawa",
          "Whiriwhiri"
        ]
      },
      {
        name: "Manukau City",
        suburbs: [
          "Auckland Airport",
          "Beachlands",
          "Botany Downs",
          "Brookby",
          "Bucklands Beach",
          "Burswood",
          "Chapel Downs",
          "Clendon Park",
          "Clendon Town",
          "Clevedon",
          "Clover Park",
          "Cockle Bay",
          "Cumbria Downs",
          "Dannemora",
          "East Tamaki",
          "East Tamaki Heights",
          "Eastern Beach",
          "Edgewater",
          "Farm Cove",
          "Favona",
          "Flat Bush",
          "Golflands",
          "Goodwood Heights",
          "Greenmount",
          "Half Moon Bay",
          "Highland Park",
          "Hill Park",
          "Homai",
          "Howick",
          "Howick East",
          "Hunters Corner",
          "Huntington Park",
          "Ihumatao",
          "Kawakawa Bay",
          "Lloyd Elsmore Park",
          "Mahia Park",
          "Mangere",
          "Mangere Bridge",
          "Mangere East",
          "Manukau Central",
          "Manukau City",
          "Manukau Heights",
          "Manurewa",
          "Manurewa East",
          "Maraetai",
          "Maraetai Beach",
          "Mellons Bay",
          "Middlemore",
          "Northpark",
          "Omana Beach",
          "Orere",
          "Orere Point",
          "Otara",
          "Pakuranga",
          "Pakuranga Heights",
          "Papatoetoe",
          "Papatoetoe South",
          "Pine Harbour",
          "Point View Park",
          "Puhinui",
          "Randwick Park",
          "Shamrock Park",
          "Shelly Park",
          "Somerville",
          "Sunnyhills",
          "The Gardens",
          "Totara Heights",
          "Wattle Downs",
          "Weymouth",
          "Whitford",
          "Wiri"
        ]
      },
      {
        name: "North Shore City",
        suburbs: [
          "Akoranga",
          "Albany",
          "Bayswater",
          "Bayview",
          "Beach Haven",
          "Belmont - North Shore City",
          "Birkdale",
          "Birkenhead",
          "Brookfield",
          "Browns Bay",
          "Bushlands",
          "Campbells Bay",
          "Castor Bay",
          "Chatswood",
          "Cheltenham",
          "Chester Park",
          "Crown Hill",
          "Cuthill",
          "Devonport",
          "Forrest Hill",
          "Glenfield",
          "Glenfield North",
          "Glenvar",
          "Greenhithe",
          "Hauraki",
          "Highbury",
          "Hillcrest",
          "Kauri Park",
          "Long Bay",
          "Mairangi Bay",
          "Marlborough",
          "Massey Campus",
          "Meadowood",
          "Milford",
          "Murrays Bay",
          "Narrow Neck",
          "North Harbour",
          "Northcote",
          "Northcote Central",
          "Northcote Point",
          "Northcross",
          "Okura",
          "Okura Village",
          "Onepoto - North Shore City",
          "Paremoremo",
          "Pinehill",
          "Rosedale",
          "Rothesay Bay",
          "Stanley Bay",
          "Stanley Point",
          "Sunnybrae",
          "Sunnynook",
          "Sunset",
          "Sunset North",
          "Sunset North Industrial",
          "Takapuna",
          "Takapuna Central",
          "Takapuna Industrial",
          "The Landing",
          "The Palms",
          "Torbay",
          "Torbay Heights",
          "Totaravale",
          "Unsworth Heights",
          "Vauxhall",
          "Waiake",
          "Wainoni",
          "Wairau Park",
          "Wairau Valley",
          "Westlake",
          "Windsor Park",
          "Windy Ridge",
          "Witheford Heights"
        ]
      },
      {
        name: "Papakura",
        suburbs: [
          "Alfriston",
          "Ardmore",
          "Conifer Grove",
          "Drury",
          "Hingaia",
          "Opaheke",
          "Pahurehure",
          "Papakura",
          "Ponga",
          "Red Hill",
          "Rosehill",
          "Runciman",
          "Takanini"
        ]
      },
      {
        name: "Rodney",
        suburbs: [
          "Ahuroa",
          "Albany Heights",
          "Algies Bay",
          "Arkles Bay",
          "Army Bay",
          "Coatesville",
          "Dairy Flat",
          "Gulf Harbour",
          "Hatfields Beach",
          "Helensville",
          "Hibiscus Coast Surrounds",
          "Hobbs Bay",
          "Hoteo",
          "Huapai",
          "Kaipara Flats",
          "Kakanui - Rodney",
          "Kaukapakapa",
          "Kawau Island",
          "Kumeu",
          "Leigh",
          "Mahurangi East",
          "Mahurangi West",
          "Makarau",
          "Mangakura",
          "Manly",
          "Matakana",
          "Matakatia",
          "Matheson Bay",
          "Maygrove",
          "Muriwai Beach",
          "Muriwai Valley",
          "Okoromai Bay",
          "Omaha",
          "Omaha Flats",
          "Orewa",
          "Pakiri",
          "Parakai",
          "Parkhurst",
          "Pine Valley",
          "Point Wells",
          "Port Albert",
          "Puhoi",
          "Red Beach",
          "Redvale",
          "Riverhead",
          "Riverhead Forest",
          "Rodney Surrounds",
          "Sandspit",
          "Scotts Landing",
          "Silverdale - Whangaparaoa",
          "Snells Beach",
          "South Head",
          "Stanmore Bay",
          "Stillwater",
          "Takatu",
          "Tapora",
          "Tauhoa",
          "Taupaki",
          "Te Arai",
          "Te Arai Point",
          "Te Hana",
          "Te Henga (Bethells / Waitakere)",
          "Te Pua",
          "The Grange",
          "Ti Point",
          "Tindalls Beach",
          "Tomarata",
          "Upper Orewa",
          "Wade Heads",
          "Waikoukou Valley",
          "Waimauku",
          "Wainui",
          "Waioneke",
          "Waitakere (RDC)",
          "Waiteitei",
          "Waitoki",
          "Waiwera",
          "Waiwhiu",
          "Warkworth",
          "Wayby",
          "Wellsford",
          "Wenderholm",
          "Whangaparaoa",
          "Whangaripo",
          "Whangateau",
          "Wharehine",
          "Wharepapa",
          "Woodhill",
          "Woodhill - Rodney",
          "Woodhill Forest"
        ]
      },
      {
        name: "Waitakere City",
        suburbs: [
          "Anawhata",
          "Cornwallis",
          "Edmonton",
          "French Bay",
          "Glen Eden",
          "Glen Eden South",
          "Glen Eden West",
          "Glendene",
          "Green Bay",
          "Harbour View",
          "Henderson",
          "Henderson North",
          "Henderson Valley",
          "Herald Island5",
          "Hobsonville",
          "Huia",
          "Karekare",
          "Kaurilands",
          "Kelston",
          "Konini",
          "Laingholm",
          "Lincoln",
          "Little Huia",
          "Luckens Point",
          "Massey",
          "Massey North",
          "Massey West",
          "Mclaren Park",
          "New Lynn",
          "Oratia",
          "Palm Heights",
          "Parau",
          "Piha",
          "Ranui",
          "Royal Heights",
          "South Titirangi",
          "Sunnyvale",
          "Swanson",
          "Te Atatu",
          "Te Atatu Peninsula",
          "Te Atatu South",
          "Te Henga (Bethells Beach)",
          "Titirangi",
          "Titirangi Beach",
          "Titirangi North",
          "Waiatarua",
          "Waima",
          "Waitakere",
          "West Harbour",
          "Western Heights",
          "Westgate",
          "Westpark Marina",
          "Whatipu5",
          "Whenuapai",
          "Wood Bay",
          "Woodlands Park"
        ]
      }
    ]
  },
  {
    name: "Bay of Plenty",
    districts: [
      {
        name: "Kawerau",
        suburbs: [
          "Kawerau",
          "Kawerau Surrounds"
        ]
      },
      {
        name: "Opotiki",
        suburbs: [
          "Kutarere",
          "Omaio",
          "Omarumutu",
          "Opotiki",
          "Opotiki Surrounds",
          "Te Kaha",
          "Torere",
          "Waihau Bay",
          "Wairata"
        ]
      },
      {
        name: "Rotorua",
        suburbs: [
          "Atiamuri East",
          "Broadlands",
          "Fenton Park",
          "Fordlands",
          "Glenholme",
          "Hamurana",
          "Hannahs Bay",
          "Hillcrest - Rotorua District",
          "Holdens Bay",
          "Horohoro",
          "Kaharoa",
          "Kaingaroa North",
          "Kawaha Point",
          "Koutu",
          "Lake Areas",
          "Lake Okareka",
          "Lake Okataina",
          "Lake Rotoma",
          "Lake Tarawera",
          "Lynmore",
          "Mamaku",
          "Mangakakahi",
          "Mihi",
          "Mourea",
          "Ngakuru",
          "Ngapuna",
          "Ngongotaha",
          "Ohinemutu",
          "Okere Falls",
          "Oturoa",
          "Owhata",
          "Pleasant Heights",
          "Pomare",
          "Pukehangi",
          "Reporoa",
          "Rerewhakaaitu",
          "Rotokawa",
          "Rotorua Central",
          "Rotorua Surrounds",
          "Selwyn Heights",
          "Springfield",
          "Sunnybrook",
          "Tihiotonga",
          "Tikitere",
          "Utuhina",
          "Waikite",
          "Waimangu",
          "Waiotapu",
          "Waiwhero",
          "Western Heights",
          "Whakarewarewa",
          "Whakarewarewa Forest"
        ]
      },
      {
        name: "Tauranga",
        suburbs: [
          "Arataki",
          "Avenues",
          "Bellevue",
          "Bethlehem",
          "Brookfield",
          "Cambridge Heights",
          "Gate Pa",
          "Greerton",
          "Hairini",
          "Judea",
          "Kairua",
          "Matapihi",
          "Matua",
          "Maungatapu",
          "Merivale - Tauranga District",
          "Mt Maunganui",
          "Mt Maunganui Surrounds",
          "Omanu",
          "Otumoetai",
          "Papamoa",
          "Parkvale",
          "Poike",
          "Sulphur Point",
          "Tauranga Central",
          "Tauranga South",
          "Tauranga Surrounds",
          "Tauriko",
          "Te Reti",
          "Welcome Bay",
          "Westridge"
        ]
      },
      {
        name: "Western Bay Of Plenty",
        suburbs: [
          "Aongatete",
          "Athenree",
          "Kaimai",
          "Katikati",
          "Kauri Point",
          "Maketu",
          "Matakana Island",
          "Ngapeke",
          "Ohauiti",
          "Omokoroa",
          "Paengaroa",
          "Pongakawa",
          "Pukehina",
          "Pyes Pa",
          "Te Puke",
          "Te Puna",
          "Waihi Beach",
          "Waimata",
          "Western BOP Surrounds"
        ]
      },
      {
        name: "Whakatane",
        suburbs: [
          "Coastlands",
          "Edgecumbe",
          "Manawahe",
          "Matahina",
          "Matata",
          "Murupara",
          "Ohope",
          "Omataroa",
          "Paroa - Whakatane",
          "Poroporo",
          "Taneatua",
          "Te Teko",
          "Te Whaiti",
          "Thornton",
          "Waimana",
          "Waingarara",
          "Whakatane",
          "Whakatane Surrounds",
        ]
      },
    ]
  },
  {
    name: "Canterbury",
    districts: [
      {
        name: "Ashburton",
        suburbs: [
          "Allenton",
          "Ashburton",
          "Ashburton Surrounds",
          "Fairton",
          "Hampstead",
          "Hinds",
          "Lauriston",
          "Lismore",
          "Mayfield - Ashburton District",
          "Methven",
          "Mt Somers",
          "Netherby",
          "Rakaia",
          "Tinwald",
          "Winchmore"
        ]
      },
      {
        name: "Banks Peninsula",
        suburbs: [					
          "Akaroa",
          "Banks Peninsula Surrounds",
          "Birdlings Flat",
          "Cass Bay",
          "Charteris Bay",
          "Church Bay",
          "Corsair Bay",
          "Diamond Harbour",
          "Duvauchelle",
          "French Farm",
          "Governors Bay",
          "Le Bons Bay",
          "Little Akaloa",
          "Little River",
          "Lyttelton",
          "Lyttelton Harbour",
          "Motukarara",
          "Okains Bay",
          "Pigeon Bay",
          "Port Levy",
          "Purau",
          "Robinsons Bay",
          "Takamatua",
          "Wainui - Banks Peninsula District"
        ]
      },
      {
        name: "Christchurch City",
        suburbs: [
          "Addington",
          "Aidanfield",
          "Airport",
          "Aranui",
          "Avoca Valley",
          "Avon Loop",
          "Avondale - Christchurch",
          "Avonhead",
          "Avonside",
          "Balmoral Hill",
          "Barrington",
          "Beckenham",
          "Belfast",
          "Bexley",
          "Bishopdale - Christchurch City",
          "Bowenvale Valley",
          "Bromley",
          "Brookhaven",
          "Brooklands",
          "Broomfield",
          "Bryndwr",
          "Burnside - Christchurch City",
          "Burwood",
          "Cannon Hill",
          "Casebrook",
          "Cashmere Flats",
          "Cashmere Hills",
          "Chaneys",
          "Christchurch Surrounds",
          "City",
          "Clifton",
          "Clifton Hill",
          "Coutts Island",
          "Cracroft",
          "Dallington",
          "Edgeware",
          "Fendalton",
          "Fendalton North",
          "Ferrymead",
          "Halswell",
          "Harewood",
          "Heathcote Valley",
          "Hei Hei",
          "Hillmorton",
          "Hillsborough",
          "Hogben",
          "Hoon Hay",
          "Horatane",
          "Hornby",
          "Huntsbury",
          "Hyde Park",
          "Ilam",
          "Islington",
          "Kainga",
          "Kennedys Bush",
          "Ladbrooks",
          "Linwood",
          "Mairehau",
          "Marshland",
          "Masham",
          "McCormacks Bay",
          "Merivale - Christchurch City",
          "Middleton",
          "Moncks Bay",
          "Moncks Spur",
          "Mt Pleasant",
          "Murray Aynsley",
          "New Brighton",
          "North Beach",
          "North Linwood",
          "North New Brighton",
          "Northcote - Christchurch",
          "Northwood",
          "Oaklands",
          "Opawa",
          "Ouruhia",
          "Papanui - Christchurch City",
          "Paparua",
          "Parklands",
          "Phillipstown",
          "Queenspark",
          "Redcliffs",
          "Redwood - Christchurch City",
          "Regents Park",
          "Riccarton",
          "Riccarton Park",
          "Richmond",
          "Richmond Hill",
          "Roimata",
          "Russley",
          "Scarborough",
          "Shirley",
          "Sockburn",
          "Somerfield",
          "South New Brighton",
          "Southshore",
          "Spencerville",
          "Spreydon",
          "St Albans",
          "St Andrews Hill",
          "St Martins",
          "Stewarts Gully",
          "Strowan",
          "Styx",
          "Sumner",
          "Sydenham",
          "Taylors Mistake",
          "Templeton",
          "Thorrington",
          "Upper Riccarton",
          "Waimairi Beach",
          "Wainoni",
          "Waltham",
          "Westburn",
          "Westhaven",
          "Westlake",
          "Westmorland",
          "Wigram",
          "Windsor",
          "Woolston",
          "Yaldhurst"
        ]
      },
      {
        name: "Hurunui",
        suburbs: [
          "Amberley",
          "Amberley Beach",
          "Balcairn",
          "Cheviot",
          "Culverden",
          "Gore Bay",
          "Hanmer Springs",
          "Hawarden",
          "Hundalee",
          "Hurunui",
          "Hurunui Surrounds",
          "Leithfield",
          "Leithfield Beach",
          "Lewis Pass",
          "Motunau",
          "Rotherham",
          "Scargill",
          "Waiau",
          "Waikari",
          "Waipara",
        ]
      },
      {
        name: "Mackenzie",
        suburbs: [
          "Albury",
          "Fairlie",
          "Lake Tekapo",
          "Mackenzie Surrounds",
          "Mt Cook - Mackenzie District",
          "Twizel"
        ]
      },
      {
        name: "Selwyn",
        suburbs: [
          "Arthurs Pass",
          "Broadfields",
          "Burnham",
          "Craigieburn",
          "Darfield",
          "Doyleston",
          "Dunsandel",
          "Glentunnel",
          "Greenpark",
          "Irwell",
          "Kirwee",
          "Lansdowne",
          "Leeston",
          "Lincoln",
          "Prebbleton",
          "Rolleston",
          "Sandy Knolls",
          "Selwyn Surrounds",
          "Sheffield",
          "Southbridge",
          "Springfield - Selwyn District",
          "Springston",
          "Tai Tapu",
          "Waddington",
          "Weedons",
          "West Melton",
          "Windwhistle"
        ]
      },      {
        name: "Timaru",
        suburbs: [
          "Beaconfield",
          "Clandeboye",
          "Fairview",
          "Geraldine",
          "Gleniti",
          "Glenwood",
          "Hadlow",
          "Highfield",
          "Kensington - Timaru District",
          "Levels",
          "Marchwiel",
          "Mesopotamia",
          "Orari Bridge",
          "Pareora",
          "Parkside",
          "Pleasant Point",
          "Port Timaru",
          "Redruth",
          "Seaview - Timaru District",
          "Smithfield",
          "Te Moana",
          "Temuka",
          "Timaru",
          "Timaru Surrounds",
          "Waimataitai",
          "Washdyke",
          "Watlington",
          "West End",
          "Winchester"
        ]
      },      
      {
        name: "Waimakariri",
        suburbs: [
          "Ashley",
          "Clarkville",
          "Cust",
          "Glentui",
          "Kaiapoi",
          "Kairaki Beach",
          "Loburn",
          "Ohoka",
          "Okuku",
          "Oxford",
          "Rangiora",
          "Sefton",
          "Southbrook",
          "Swannanoa",
          "The Pines Beach",
          "Tuahiwi",
          "Waikuku",
          "Waikuku Beach",
          "Waimakariri Surrounds",
          "West Eyreton",
          "Whitecliffs",
          "Woodend - Waimakariri District",
        ]
      },
      {
        name: "Waimate",
        suburbs: [
          "Aviemore",
          "Blue Cliffs",
          "Glenavy",
          "Hakataramea Valley",
          "Ikawai",
          "Makikihi",
          "Maungati",
          "St Andrews - Waimate District",
          "Waihaorunga",
          "Waimate",
          "Waimate Surrounds",
        ]
      }
    ]
  },
  {
    name: "Central Otago / Lakes District",
    districts: [
      {
        name: "Central Otago",
        suburbs: [
          "Alexandra",
          "Bannockburn",
          "Central Otago Surrounds",
          "Clyde",
          "Cromwell",
          "Ettrick",
          "Lindis Pass",
          "Naseby",
          "Nevis",
          "Omakau",
          "Oturehua",
          "Paerau",
          "Ranfurly",
          "Roxburgh",
          "Roxburgh East",
        ]			
      },
      {
        name: "Queenstown-Lakes",
        suburbs: [
          "Albert Town",
          "Arrowtown",
          "Arthurs Point",
          "Cardrona",
          "Cattle Flat",
          "Cecil Peak",
          "Coronet Peak",
          "Crown Terrace",
          "Fernhill - Queenstown-Lakes District",
          "Frankton",
          "Gibbston",
          "Glendhu",
          "Glenorchy",
          "Hawea",
          "Kelvin Heights",
          "Kingston - Queenstown-Lakes District",
          "Lake Hawea",
          "Lake Hayes",
          "Luggate",
          "Makarora",
          "Queenstown",
          "Queenstown East",
          "Queenstown Surrounds",
          "Remarkables",
          "Skippers",
          "Sunshine Bay - Queenstown",
          "Wakatipu Basin",
          "Walter Peak",
          "Wanaka",
          "Wanaka Surrounds",
        ]			
      },		
    ]
  },
  {
    name: "Confidential",
    districts: [
      {
        name: "Confidential",
        suburbs: [
          "Confidential"
        ]
      }
    ]
  },
  {
    name: "Coromandel",
    districts: [
      {
        name: "Hauraki",
        suburbs: [
          "Hauraki Surrounds",
          "Kaihere",
          "Karangahake",
          "Mangatarata",
          "Ngatea",
          "Paeroa",
          "Turua",
          "Waihi",
          "Waikino",
        ]
      },
      {
        name: "Thames-Coromandel",
        suburbs: [
          "Colville",
          "Cooks Beach",
          "Coroglen",
          "Coromandel",
          "Coromandel Coast",
          "Coromandel Surrounds",
          "East Coast Surrounds",
          "Hahei",
          "Hikuai",
          "Hikutaia",
          "Hot Water Beach",
          "Kaimarama",
          "Kauaeranga",
          "Kennedy Bay",
          "Kopu",
          "Kopuarahi",
          "Kuaotunu",
          "Little Bay",
          "MacKaytown",
          "Manaia - Thames / Coromandel",
          "Matarangi",
          "Matatoki",
          "Netherton",
          "Ngarimu Bay",
          "Oamaru Bay",
          "Onemana",
          "Opito Bay - Coromandel",
          "Opoutere",
          "Papaaroha",
          "Pauanui",
          "Port Charles",
          "Puriri",
          "Ruamahunga Bay",
          "Sandy Bay (Coromandel)",
          "Tairua",
          "Tapu",
          "Tararu",
          "Te Kouma",
          "Te Mata",
          "Te Puru",
          "Te Rerenga",
          "Thames",
          "Thames Coast",
          "Thames Surrounds",
          "Thornton Bay",
          "Tuateawa",
          "Waiomu",
          "Waitakaruru",
          "Waitete Bay",
          "Whakatete Bay",
          "Whangamata",
          "Whangapoua",
          "Whenuakite",
          "Whiritoa",
          "Whitianga",
        ]
      }
    ]
  },
  {
    name: "Gisborne",
    districts: [
      {
        name: "Gisborne",
        suburbs: [
          "Awapuni",
          "East Cape",
          "Elgin",
          "Gisborne Central",
          "Gisborne City Surrounds",
          "Gisborne Coastal",
          "Gisborne Country",
          "Hexton",
          "Hicks Bay",
          "Inner Kaiti",
          "Kaiti",
          "Makaraka",
          "Makorori",
          "Mangapapa",
          "Manutuke",
          "Matawai",
          "Matokitoki",
          "Muriwai - Gisborne District",
          "Ngatapa",
          "Okitu",
          "Patutahi",
          "Potaka",
          "Rangitukia",
          "Riverdale",
          "Ruatoria",
          "Tamarau",
          "Taruheru",
          "Te Araroa",
          "Te Hapara",
          "Te Karaka",
          "Tikitiki",
          "Tiniroto",
          "Tokomaru Bay",
          "Tolaga Bay",
          "Waimata Valley",
          "Wainui Beach",
          "Whangara",
          "Whatatutu",
          "Whataupoko",
        ]
      }
    ]
  },
  {
    name: "Hawkes Bay",
    districts: [
      {
        name: "Central Hawkes Bay",
        suburbs: [
          "Central Hawkes Bay Coastal",
          "Central Hawkes Bay Country",
          "Elsthorpe",
          "Otane",
          "Waipawa",
          "Waipukurau",
        ]
      },
      {
        name: "Hastings",
        suburbs: [
          "Akina",
          "Bridge Pa",
          "Camberley",
          "Clive",
          "Crownthorpe",
          "Dartmoor Valley",
          "Eskdale",
          "Fernhill - Hastings District",
          "Flaxmere",
          "Frimley",
          "Hastings",
          "Hastings Coastal",
          "Hastings Country",
          "Havelock North",
          "Hawkes Bay Area",
          "Karamu",
          "Longlands",
          "Mahora",
          "Mangateretere",
          "Maraekakaho",
          "Mayfair",
          "Omahu",
          "Pakipaki",
          "Pakowhai",
          "Parkvale",
          "Poukawa",
          "Puketitiri",
          "Raureka",
          "Rawhiti - Napier",
          "St Leonards - Hastings District",
          "Te Awanga",
          "Te Haroto",
          "Tomoana",
          "Tutira",
          "Twyford",
          "Waimarama",
          "Waiohiki",
          "Waipatiki",
          "Whakatu",
          "Whirinaki",
        ]
      },
      {
        name: "Napier City",
        suburbs: [
          "Ahuriri",
          "Awatoto",
          "Bay View",
          "Greenmeadows",
          "Jervoistown",
          "Maraenui",
          "Marewa",
          "Meeanee",
          "Napier Central",
          "Napier City Surrounds",
          "Napier Hill",
          "Napier Port",
          "Napier South",
          "Onekawa",
          "Pandora",
          "Pirimai",
          "Poraiti",
          "Tamatea",
          "Taradale",
          "Te Awa",
          "Westshore",
        ]
      },
      {
        name: "Wairoa",
        suburbs: [
          "Kotemaori",
          "Mahia Peninsula",
          "Nuhaka/Morere",
          "Rangiahua",
          "Raupunga",
          "Ruakituri",
          "Tuai",
          "Waihua",
          "Wairoa",
          "Wairoa Coastal",
          "Wairoa Country",
          "Whakaki",
        ]
      }
    ]
  },
  {
    name: "Manawatu",
    districts: [
      {
        name: "Horowhenua",
        suburbs: [
          "Foxton",
          "Foxton Beach",
          "Heatherlea",
          "Horowhenua Surrounds",
          "Levin",
          "Manakau",
          "Muhunoa East",
          "Ohau - Levin",
          "Shannon",
          "Tokomaru",
          "Waitarere",
          "Weraroa",
        ]
      },
      {
        name: "Manawatu",
        suburbs: [
          "Aorangi",
          "Apiti",
          "Bunnythorpe",
          "Feilding",
          "Halcombe",
          "Himatangi",
          "Himatangi Beach",
          "Hokio",
          "Kairanga",
          "Kauwhata",
          "Kimbolton",
          "Longburn",
          "Maewa",
          "Manawatu Surrounds",
          "Ohakea",
          "Opiki",
          "Pohangina",
          "Rangiwahia",
          "Rongotea",
          "Sanson",
          "Stoney Creek",
          "Waitarere Beach",
          "Waituna West",
        ]
      },
      {
        name: "Palmerston North",
        suburbs: [
          "Aokautere",
          "Ashhurst",
          "CBD",
          "Cloverlea",
          "Highbury - Palmerston North City",
          "Hokowhitu",
          "Kelvin Grove",
          "Linton",
          "Linton Camp",
          "Massey University",
          "Milson",
          "Palmerston North Surrounds",
          "Terrace End",
          "Tirita",
        ]
      }
    ]
  },
  {
    name: "Malborough",
    districts: [
      {
        name: "Kaikoura",
        suburbs: [
          "Clarence",
          "Kaikoura",
          "Kaikoura Surrounds",
        ]
      },
      {
        name: "Marlborough",
        suburbs: [
          "Anakiwa",
          "Blenheim",
          "Burleigh",
          "Curious Cove",
          "Dillons Point",
          "Endeavour Inlet",
          "Fairhall",
          "Farnham",
          "Grovetown",
          "Havelock",
          "Islington - Marlborough",
          "Kenepuru Sound",
          "Lochmara Bay",
          "Mahakipawa",
          "Mahau Sound",
          "Marlborough Country",
          "Marlborough Sounds",
          "Mayfield",
          "Mayfield - Marlborough District",
          "Moenui Bay",
          "Molesworth - Marlborough District",
          "Okiwi Bay",
          "Omaka",
          "Pelorous Sounds",
          "Penzance",
          "Picton",
          "Rai Valley",
          "Rapaura",
          "Rarangi",
          "Redwoodtown",
          "Riverlands",
          "Riversdale - Marlborough District",
          "Roselands",
          "Seddon",
          "Spring Creek",
          "Springlands",
          "Tennyson",
          "Tirimoana",
          "Tuamarina",
          "Waikawa Bay",
          "Wairau",
          "Wairau Valley",
          "Ward",
          "Witherlea",
          "Woodbourne"
        ]
      }
    ]
  },
  {
    name: "Nelson & Bays",
    districts: [
      {
        name: "Nelson City",
        suburbs: [
          "Annesbrook",
          "Atawhai",
          "Beachville",
          "Bishopdale - Nelson City",
          "Britannia Heights",
          "Brooklands",
          "Bryant Range",
          "Enner Glynn",
          "Hira",
          "Kokorua",
          "Maitai Valley",
          "Maitlands",
          "Marybank",
          "Moana",
          "Monaco",
          "Moutere",
          "Nayland",
          "Nelson",
          "Nelson Airport",
          "Nelson East",
          "Nelson South",
          "Nelson Surrounds",
          "Ngawhatu",
          "Port Hills",
          "Port Nelson",
          "Saxton",
          "Stepneyville",
          "Stoke",
          "Tahunanui",
          "The Brook",
          "The Wood",
          "Toi Toi",
          "Tui Glen",
          "Waimeas",
          "Wakapuaka",
          "Wakatu",
          "Washington Valley",
          "Whangamoa"
        ]
      },
      {
        name: "Tasman",
        suburbs: [
          "Aniseed Valley",
          "Brightwater",
          "Collingwood",
          "Hope",
          "Kaiteriteri",
          "Motueka",
          "Mt Murchison",
          "Murchison",
          "Nelson Bays Coastal",
          "Nelson Country",
          "Ngatimoti",
          "Parapara",
          "Richmond",
          "St Arnaud",
          "Takaka",
          "Tapawera",
          "Tasman Lakes",
          "Upper Moutere",
        ]
      }
    ]
  },
  {
    name: "North Otago",
    districts: [
      {
        name: "Waitaki",
        suburbs: [
          "Deborah",
          "Duntroon",
          "Enfield",
          "Five Forks",
          "Hampden",
          "Herbert",
          "Kurow",
          "Lake Ohau",
          "Maheno",
          "Moeraki",
          "North Otago",
          "North Otago Surrounds",
          "Oamaru",
          "Ohau - Canterbury",
          "Omarama",
          "Orana Park",
          "Otekaieke",
          "Otematata",
          "Palmerston",
          "Pukeuri Junction",
          "Richmond - Otago",
          "Waianakarua",
          "Waihemo",
          "Waitaki Valley",
          "Weston",
          "Windsor - Waitaki District"
        ]
      }
    ]
  },
  {
    name: "Northland",
    districts: [
      {
        name: "Far North",
        suburbs: [
          "",
          "Awanui",
          "Cable Bay",
          "Cape Reinga",
          "Coopers Beach",
          "Doves Bay",
          "Haruru",
          "Herekino",
          "Hi Hi",
          "Hokianga Surrounds",
          "Horeke",
          "Houhora",
          "Jacks Bay",
          "Kaeo",
          "Kaikohe",
          "Kaikohe Surrounds",
          "Kaimaumau",
          "Kaitaia",
          "Kaitaia Surrounds",
          "Karetu",
          "Karikari",
          "Kawakawa",
          "Kawakawa Surrounds",
          "Kerikeri",
          "Kerikeri Surrounds",
          "Kohukohu",
          "Mangamuka",
          "Mangonui",
          "Mangonui Surrounds",
          "Matawaia",
          "Mitimiti",
          "Moerewa",
          "Ngawha",
          "Ohaeawai",
          "Okaihau",
          "Okiato",
          "Omapere",
          "Opito Bay - Kerikeri",
          "Opononi",
          "Paihia",
          "Paihia Surrounds",
          "Panguru",
          "Parekura Bay",
          "Pukenui",
          "Pukepoto",
          "Puketona",
          "Rangiputa",
          "Rawene",
          "Rawhiti - Russell",
          "Reinga Park",
          "Riverview",
          "Russell",
          "Russell Surrounds",
          "Skudders Beach",
          "South Hokianga",
          "Taheke",
          "Taipa",
          "Tapeka",
          "Tapuaetahi",
          "Taupo Bay",
          "Tauranga Bay",
          "Te Haumi",
          "Te Ngaire",
          "Te Wahapu",
          "Totara North",
          "Veronica Point",
          "Waimate North",
          "Waipapa",
          "Waipapakauri",
          "Whangaroa/Kaeo Surrounds",
          "Whatuwhiwhi"
        ]
      },
      {
        name: "Kaipara",
        suburbs: [
          "Arapohue",
          "Dargaville",
          "Dargaville Surrounds",
          "Kaiwaka",
          "Mangawhai",
          "Mangawhai Heads",
          "Maungaturoto",
          "Molesworth - Kaipara District",
          "Otamatea Surrounds",
          "Pouto",
          "Ruawai",
          "Ruawai Surrounds",
          "Tangiteroria",
          "Te Kopuru",
          "Tinopai",
          "Topuni",
          "Waipoua",
        ]
      },
      {
        name: "Whangarei",
        suburbs: [
          "Bream Bay",
        "Glenbervie",
          "Hikurangi",
          "Horahora",
        "Kamo",
          "Kauri",
        "Kensington - Whangarei District",
          "Langs Beach",
          "Mairtown",
          "Matapouri",
        "Maunu",
          "McLeod Bay",
        "Morningside - Whangarei District",
          "Ngunguru",
          "Oakleigh",
          "Oakura Coast",
          "One Tree Point",
        "Onerahi",
        "Otaika",
        "Otangarei",
          "Pakotai",
        "Parahaki",
          "Parua Bay",
          "Pataua",
          "Pataua North",
          "Pipiwai",
          "Port Whangarei",
          "Portland",
        "Raumanga",
          "Regent",
        "Riverside",
          "Ruakaka",
          "Ruatangata",
        "Sherwood",
          "Springfield - Whangarei District",
          "Springs Flat",
          "Tamaterau",
          "Three Mile Bush",
        "Tikipunga",
          "Toetoe",
          "Tutukaka Coast",
          "Vinetown",
          "Waiotira",
          "Waiotu",
          "Whananaki",
          "Whangarei Area",
        "Whangarei Central",
        "Whangarei City Surrounds",
          "Whangarei Heads",
          "Whangarei Surrounds",
          "Whareora",
        "Whau Valley",
        "Woodhill - Whangarei",
        ]
      }
    ]
  },
  {
    name: "Otago",
    districts: [
      {
        name: "Clutha",
        suburbs: [
          "Balclutha",
          "Balclutha-Clinton Surrounds",
          "Benhar/Stirling",
          "Brighton - Clutha District",
          "Bruce-Kaitangata Surrounds",
          "Catlins Surrounds",
          "Chaslands",
          "Clinton",
          "Kaitangata",
          "Kaka Point",
          "Lawrence",
          "Lawrence-Tuapeka Surrounds",
          "Milton",
          "Owaka",
          "Papatowai/Pounawea",
          "South Otago Coastal",
          "South Otago Country",
          "Taieri Mouth",
          "Tapanui",
          "Waitahuna",
          "West Otago Surrounds",
        ]
      },
      {
        name: "Dunedin City",
        suburbs: [
          "Abbotsford",
          "Allanton",
          "Andersons Bay",
          "Aramoana",
          "Balaclava",
          "Belleknowes",
          "Blackhead",
          "Blanket Bay",
          "Bradford",
          "Brighton - Dunedin City",
          "Broad Bay",
          "Brockville",
          "Burkes",
          "Burnside - Dunedin City",
          "Calton Hill",
          "Cape Saunders",
          "Careys Bay",
          "Caversham",
          "Central City",
          "Chain hills",
          "Challis",
          "Clarks Junction",
          "Clyde Hill",
          "Colinswood",
          "Company Bay",
          "Concord",
          "Corstorphine",
          "Dalmore",
          "Deborah Bay",
          "Doctors Point",
          "East Otago Surrounds",
          "East Taieri",
          "Fairfield - Dunedin City",
          "Glenleith",
          "Glenross",
          "Green Island",
          "Halfway Bush",
          "Harington Point",
          "Harwood",
          "Helensburgh",
          "Henley",
          "Highcliff",
          "Hoopers Inlet",
          "Hyde",
          "Kaikorai",
          "Kaikorai Valley",
          "Karitane",
          "Kenmure",
          "Kensington",
          "Kew",
          "Kilmog",
          "Kinmont",
          "Leith Valley",
          "Liberton",
          "Macandrew Bay",
          "Maia",
          "Maori Hill",
          "Maryhill",
          "Middlemarch",
          "Mornington",
          "Mosgiel",
          "Mt Allan",
          "Mt Cargill",
          "Mt Mera",
          "Musselburgh",
          "Normanby",
          "North Dunedin",
          "North Dunedin Surrounds",
          "North East Valley",
          "North Taieri",
          "Ocean Grove",
          "Ocean View",
          "Opoho",
          "Osborne",
          "Otago Peninsula",
          "Outram",
          "Peninsula Surrounds",
          "Pine Hill",
          "Port Chalmers",
          "Portobello",
          "Pukehiki",
          "Purakaunui",
          "Ravensbourne",
          "Roseneath",
          "Roslyn",
          "Saddle Hill",
          "Sandymount",
          "Sawyers Bay",
          "Shiel Hill",
          "South Dunedin",
          "St Clair",
          "St Clair Park",
          "St Kilda",
          "St Leonards - Dunedin City",
          "Strath Taieri Surrounds",
          "Sunnyvale",
          "Sunshine",
          "Taiaroa Head",
          "Taieri Plain",
          "Tainui",
          "The Cove",
          "Upper Junction",
          "Upper Waitati",
          "Vauxhall",
          "Waikouaiti",
          "Waitati",
          "Wakari",
          "Waldronville",
          "Warrington",
          "Waverley - Dunedin City",
          "West Harbour Surrounds",
          "Westwood",
          "Whare Flat",
          "Wingatui",
          "Woodhaugh",
        ]
      }
    ]
  },
  {
    name: "Pasific Islands",
    districts: [
      {
        name: "Pasific Islands",
        suburbs: [
          "Chatham Island",
          "New Caledonia",
          "Outer Islands",
          "Pitt Island",
          "Rarotonga",
          "Vanua Levu",
          "Vanuatu",
          "Viti Levu",
        ]
      }
    ]
  },
  {
    name: "Southland",
    districts: [
      {
        name: "Gore",
        suburbs: [
          "Gore",
          "Gore Surrounds",
          "Mataura",
          "Pukerau",
          "Waikaka",
        ]
      },
      {
        name: "Invercargill City",
        suburbs: [
          "Appleby",
          "Avenal",
          "Awarua",
          "Bluff",
          "Georgetown",
          "Glengarry",
          "Grasmere",
          "Hawthorndale",
          "Heidelberg",
          "Invercargill",
          "Invercargill Surrounds",
          "Kingswell",
          "Makarewa",
          "Mill Road",
          "Myross Bush",
          "Newfield",
          "Oreti Beach",
          "Otatara",
          "Richmond - Invercargill City",
          "Strathern",
          "Tisbury",
          "Tiwai Point",
          "Waikiwi",
          "Waimatua",
          "Windsor - Invercargill City",
          "Woodend - Invercargill City",
        ]
      },
      {
        name: "Southland",
        suburbs: [
          "Balfour",
          "Blackmount",
          "Colac Bay",
          "Dipton",
          "Drummond",
          "Edendale",
          "Fiordland",
          "Garston",
          "Halfmoon Bay - Southland District",
          "Lake Te Anau",
          "Lochiel",
          "Lumsden & Surrounds",
          "Manapouri",
          "Milford Sound",
          "Mossburn",
          "Nightcaps",
          "Ohai",
          "Orepuki",
          "Otahuti",
          "Otautau",
          "Riversdale - Southland District",
          "Riverton & Surrounds",
          "Stewart Island",
          "Te Anau & Surrounds",
          "Thornbury",
          "Tokanui - Southland District",
          "Tuatapere",
          "Waianiwa",
          "Waikaia",
          "Wallacetown",
          "Winton & Surrounds",
          "Woodlands",
          "Wyndham & Surrounds",
        ]
      }
    ]
  },
  {
    name: "Taranaki",
    districts: [
      {
        name: "New Plymouth",
        suburbs: [
          "Bell Block",
          "Blagdon",
          "Brooklands",
          "Camp Huinga",
          "Egmont Village",
          "Ferndale",
          "Fitzroy - New Plymouth",
          "Frankleigh",
          "Frankleigh Park",
          "Glen Avon",
          "Highlands Park",
          "Hillsborough - New Plymouth District",
          "Hurdon",
          "Hurworth",
          "Inglewood",
          "Lynmouth",
          "Marfell",
          "Marsland Hill",
          "Merrilands",
          "Mokau",
          "Moturoa",
          "New Plymouth",
          "New Plymouth Area Surrounds",
          "New Plymouth City Surrounds",
          "New Plymouth Coastal",
          "Oakura",
          "Okato",
          "Omata",
          "Pukekura Park",
          "Sentry Hill",
          "Spotswood",
          "Strandon",
          "Tarata",
          "Tarurutangi",
          "Tikorangi",
          "Tongaporutu",
          "Uruti",
          "Vogeltown - New Plymouth District",
          "Waitara",
          "Welbourn",
          "Westown",
          "Whalers Gate",
        ]
      },
      {
        name: "South Taranaki,",
        suburbs: [
          "Eltham",
          "Hawera",
          "Manaia - Taranaki",
          "Mangamingi",
          "Manutahi",
          "Normanby",
          "Ohangai",
          "Okaiawa",
          "Opunake",
          "Patea",
          "Rahotu",
          "South Taranaki Surrounds",
          "Waitotara",
          "Waverley - South Taranaki District",
        ]
      },
      {
        name: "Stratford",
        suburbs: [
          "Douglas",
          "Huiakama",
          "Midhirst",
          "Stratford",
          "Stratford Surrounds",
          "Tahora",
        ]
      }
    ]
  },
  {
    name: "Waikato",
    districts: [
      {
        name: "Hamilton City",
        suburbs: [
          "Bader",
          "Bankwood",
          "Beerescourt",
          "Burbush",
          "Callum Brae",
          "Chartwell - Hamilton",
          "Chedworth Park",
          "Claudelands",
          "Coleraine",
          "Deanwell",
          "Dinsdale",
          "Eden Park",
          "Enderley",
          "Fairfield - Hamilton City",
          "Fairview Downs",
          "Fitzroy",
          "Flagstaff",
          "Forest Lake",
          "Frankton - Hamilton City",
          "Glenview",
          "Grosvenor Park",
          "Hamilton Central",
          "Hamilton East",
          "Hamilton North",
          "Hamilton Surrounds",
          "Hamilton West",
          "Harrowfield",
          "Hillcrest - Hamilton City",
          "Horsham Downs",
          "Huntington",
          "Livingstone",
          "Maeroa",
          "Melville",
          "Nawton",
          "Peacocke",
          "Pukete",
          "Queenwood",
          "Rototuna",
          "Ruakura",
          "Silverdale",
          "Somerset Heights",
          "St Andrews - Hamilton City",
          "St James Park",
          "Tamahere",
          "Te Rapa",
          "Temple View",
          "Thornton Estate",
          "Western Heights",
          "Whitiora",
        ]
      },
      {
        name: "Matamata-Piako",
        suburbs: [
          "Hinuera",
          "Kiwitahi",
          "Matamata",
          "Matamata-Piako Surrounds",
          "Morrinsville",
          "Okauia",
          "Tahuna",
          "Te Aroha",
          "Te Poi",
          "Waharoa",
          "Waihou",
          "Walton",
          "Wardville",
        ]
      },
      {
        name: "Otorohanga",
        suburbs: [
          "Arohena",
          "Maihihi",
          "Ngutunui",
          "Oparau",
          "Otorohanga",
          "Otorohanga Surrounds",
          "Waiharakeke",
        ]
      },
      {
        name: "South Waikato",
        suburbs: [
          "Amisfield",
          "Aotea",
          "Kinleith",
          "Lichfield",
          "Matarawa",
          "Papanui - Tokoroa",
          "Paraonui",
          "Parkdale - Tokoroa",
          "Putaruru",
          "South Waikato Surrounds",
          "Strathmore - Tokoroa",
          "Tapapa",
          "Te Whetu",
          "Tirau",
          "Tokoroa",
          "Upper Atiamuri",
        ]
      },
      {
        name: "Taupo",
        suburbs: [
          "Acacia Bay",
          "Atiamuri West",
          "Five Mile Bay",
          "Hilltop",
          "Iwitahi",
          "Kaingaroa South",
          "Kinloch",
          "Lake Taupo",
          "Mangakino",
          "Maroa",
          "Motutere",
          "Nukuhau",
          "Omori",
          "Opepe",
          "Rangipo",
          "Richmond Heights",
          "Taharua",
          "Tahorakuri",
          "Tauhara",
          "Taupo",
          "Taupo Surrounds",
          "Tihoi",
          "Turangi",
          "Wairakei",
          "Waitahanui",
          "Wharewaka",
        ]
      },
      {
        name: "Waikato",
        suburbs: [
          "Eureka",
          "Glen Massey",
          "Gordonton",
          "Horotiu",          
          "Huntly",
          "Kainui",
          "Kauri Flat",         
          "Maramarua",        
          "Meremere",         
          "Ngaruawahia",
          "Raglan",
          "Rangiriri",
          "Rotongaro",
          "Taupiri",
          "Te Akau",
          "Te Kauwhata",
          "Te Kowhai",
          "Te Uku",
          "Waerenga",
          "Waikato Surrounds",  
          "Waiterimu",  
          "Whatawhata",
          "Whitikahu",
        ]
      },
      {
        name: "Waipa",
        suburbs: [
          "Cambridge",
          "Kihikihi",
          "Leamington",
          "Ngahinapouri",
          "Ohaupo",
          "Pirongia",
          "Pokuru",
          "Puahue",
          "Rotongata",
          "Rotoorangi",
          "Rukuhia",
          "Te Awamutu",
          "Te Miro",
          "Te Pahu",
          "Te Rahu",
          "Te Rore",
          "Tokanui - Waipa District",
          "Waipa Surrounds",
        ]
      },
      {
        name: "Waitomo",
        suburbs: [
          "Aria",
          "Awakino",
          "Benneydale",
          "Eight Mile Junction",
          "Mahoenui",
          "Mapiu",
          "Marokopa",
          "Pakeho",
          "Piopio",
          "Piripiri",
          "Pureora",
          "Taharoa",
          "Te Anga",
          "Te Kuiti",
          "Te Kuiti / Waitomo Surrounds",
          "Waipa Valley",
          "Waitomo Caves",
        ]
      }
    ]
  },
  {
    name: "Wairarapa",
    districts: [
      {
        name: "Carterton",
        suburbs: [
          "Carterton",
          "Carterton Surrounds",
          "Gladstone",
          "Longbush",
          "Mt Holdsworth",
          "Te Wharau",
          "Waingawa",
        ]
      },
      {
        name: "Masterton",
        suburbs: [
          "Bideford",
          "Blairlogie",
          "Homebush",
          "Masterton",
          "Masterton Surrounds",
          "Matahiwi",
          "Mauriceville",
          "Ngahape",
          "Opaki",
          "Riversdale Beach",
          "Ruamahanga Forks",
          "Solway",
          "Tauweru",
          "Tinui",
        ]
      },
      {
        name: "South Wairarapa",
        suburbs: [
          "Cape Palliser",
          "Featherston",
          "Greytown",
          "Hinakura",
          "Martinborough",
          "Pirinoa",
          "South Wairarapa Surrounds",
          "Tuturumuri",
        ]
      },
      {
        name: "Tararua",
        suburbs: [
          "Dannevirke",
          "Eketahuna",
          "Norsewood",
          "Pahiatua",
          "Pongaroa",
          "Tarakamuku",
          "Tararua Surrounds",
          "Tiraumea",
          "Wimbledon",
          "Woodville",
        ]
      },
    ]
  },
  {
    name: "Wanganui",					
    districts: [
      {
        name: "Rangitikei",
        suburbs: [
          "Bulls",
          "Lake Alice",
          "Mangaweka",
          "Marton",
          "Ngamahanga",
          "Parewanui",
          "Rangitikei Surrounds",
          "Ratana",
          "Taihape",
          "Taoroa Junction",
        ]
      },
      {
        name: "Ruapehu",
        suburbs: [
          "Kakahi",
          "Mangatupoto",
          "Manunui",
          "National Park",
          "Ngapuke",
          "Ohakune",
          "Ohura",
          "Owhango",
          "Raetihi",
          "Ruapehu-King Country Surrounds",
          "Taumarunui",
          "Tokirima",
          "Tongariro",
          "Waimiha",
          "Waiouru",
          "Whakapapa",
        ]
      },
      {
        name: "Wanganui",
        suburbs: [
          "Aramoho",
          "Bastia Hill",
          "Brunswick",
          "Castlecliff",
          "College Estate",
          "Durie Hill",
          "Eastown",
          "Fordell",
          "Gonville",
          "Kai Iwi",
          "Kaitoke - Wanganui",
          "Kakatahi",
          "Koitiata",
          "Mangamahu",
          "Marybank",
          "Mosston",
          "Okoia",
          "Otamatea",
          "Papaiti",
          "Parikino",
          "Putiki",
          "Ranana",
          "Springvale",
          "St Johns Hill",
          "Tawhero",
          "Wanganui Central",
          "Wanganui East",
          "Wanganui Surrounds",
          "Westmere",
          "Whangaehu",
        ]
      }
    ]
  },
  {
    name: "Wellington",
    districts: [
      {
        name: "Kapiti Coast",
        suburbs: [
          "Kapiti Coast Surrounds",
          "Maungakotukutuku",
          "Otaihanga",
          "Otaki",
          "Otaki Beach",
          "Paekakariki",
          "Paraparaumu",
          "Paraparaumu Beach",
          "Peka Peka",
          "Raumati Beach",
          "Raumati South",
          "Te Horo",
          "Waikanae",
          "Waikanae Beach",
        ]
      },
      {
        name: "Lower Hutt City",
        suburbs: [
          "Alicetown",
          "Arakura",
          "Ava",
          "Avalon",
          "Belmont - Lower Hutt City",
          "Birdwood",
          "Boulcott",
          "Central Hutt",
          "Days Bay",
          "Eastbourne",
          "Epuni",
          "Fairfield - Lower Hutt City",
          "Glendale",
          "Gracefield",
          "Harbour View",
          "Haywards",
          "Holborn",
          "Homedale",
          "Hutt Valley Surrounds",
          "Kelson",
          "Korokoro",
          "Lowry Bay",
          "Mahina Bay",
          "Manor Park",
          "Maungaraki",
          "Melling",
          "Moera",
          "Muritai",
          "Naenae",
          "Normandale",
          "Park Avenue",
          "Parkway",
          "Pencarrow",
          "Petone",
          "Point Howard",
          "Rona Bay",
          "Seaview - Lower Hutt City",
          "Stokes Valley",
          "Sunshine Bay - Eastbourne",
          "Taita",
          "Tirohanga",
          "Wainuiomata",
          "Waiwhetu",
          "Waterloo",
          "Western Hills",
          "Wingate",
          "Woburn",
          "York Bay",
        ]
      },
      {
        name: "Porirua City",
        suburbs: [
          "Aotea - Porirua",
          "Ascot Park",
          "Camborne",
          "Cannons Creek",
          "Elsdon",
          "Golden Gate",
          "Judgeford",
          "Karehana Bay",
          "Mana",
          "Okowai",
          "Onepoto - Porirua City",
          "Paekakariki Hill",
          "Papakowhai",
          "Paremata",
          "Pauatahanui",
          "Plimmerton",
          "Porirua",
          "Porirua East",
          "Pukerua Bay",
          "Pukerua Bay Surrounds",
          "Ranui Heights",
          "Takapuwahia",
          "Titahi Bay",
          "Waitangirua",
          "Whitby",
        ]
      },
      {
        name: "Upper Hutt City",
        suburbs: [
          "Akatarawa",
          "Akatarawa Valley",
          "Birchville",
          "Blue Mountains",
          "Brown Owl",
          "Clouston Park",
          "Craigs Flat",
          "Ebdentown",
          "Elderslea",
          "Heretaunga",
          "Kaitoke",
          "Kingsley Heights",
          "Maidstone Park",
          "Mangaroa",
          "Maoribank",
          "Maymorn",
          "Moonshine",
          "Parkdale - Upper Hutt",
          "Pinehaven",
          "Poets Block",
          "Rimutaka Hill",
          "Silverstream",
          "Sunnyview",
          "Te Marua",
          "The Plateau",
          "Timberlea",
          "Totara Park",
          "Trentham",
          "Trentham Military Camp",
          "Upper Hutt",
          "Upper Hutt Surrounds",
          "Wallaceville",
          "Whitemans Valley",
        ]
      },
      {
        name: "Wellington City",
        suburbs: [
          "Aro Valley",
          "Berhampore",
          "Broadmeadows",
          "Brooklyn",
          "Chartwell",
          "Churton Park",
          "City/Te Aro",
          "Crawford",
          "Crofton Downs",
          "Glenside",
          "Greenacres",
          "Grenada North",
          "Grenada Village",
          "Happy Valley",
          "Hataitai",
          "Highbury - Wellington City",
          "Horokiwi",
          "Houghton Bay",
          "Island Bay",
          "Johnsonville",
          "Kaiwharawhara",
          "Karaka Bay",
          "Karori",
          "Kelburn",
          "Khandallah",
          "Kilbirnie",
          "Kingston",
          "Kowhai Park",
          "Lambton",
          "Linden",
          "Lindenvale",
          "Lyall Bay",
          "Makara",
          "Maupuia",
          "Melrose",
          "Miramar",
          "Mornington",
          "Mt Cook - Wellington City",
          "Mt Victoria",
          "Newlands",
          "Newtown",
          "Ngaio",
          "Ngauranga",
          "Northland",
          "Ohariu Valley",
          "Oriental Bay",
          "Owhiro Bay",
          "Paparangi",
          "Rangoon Heights",
          "Raroa",
          "Redwood - Wellington City",
          "Rongotai",
          "Roseneath",
          "Scorching Bay",
          "Seatoun",
          "Seatoun Bays",
          "Southgate",
          "Strathmore Park",
          "Sundale",
          "Tawa",
          "Te Aro",
          "Thorndon",
          "Vogeltown - Wellington City",
          "Wadestown",
          "Westhaven",
          "Wilton",
        ]
      },
    ]
  },
  {
    name: "West Coast",
    districts: [
      {
        name: "Buller",
        suburbs: [
          "Buller Gorge",
          "Buller Surrounds",
          "Cape Foulwind",
          "Carters Beach",
          "Charleston",
          "Hector",
          "Inangahua",
          "Kahurangi",
          "Karamea",
          "Punakaiki",
          "Reefton",
          "Seddonville",
          "Waimangaroa",
          "Westport",
          "Westport Surrounds"
        ]
      },
      {
        name: "Grey",
        suburbs: [
          "Ahaura",
          "Barrytown",
          "Blackball",
          "Blaketown",
          "Cobden",
          "Dobson",
          "Greymouth",
          "Greymouth Surrounds",
          "Karoro",
          "Lake Brunner",
          "Ngahere",
          "Runanga",
          "South Beach",
          "Totara Flat",
        ]
      },,
      {
        name: "Westland",
        suburbs: [
          "Bruce Bay",
          "Castle Hill",
          "Coalbrookdale",
          "Dunollie",
          "Fox Glacier",
          "Franz Josef",
          "Haast",
          "Harihari",
          "Hokitika",
          "Ikamatua",
          "Jackson Bay",
          "Kaiata",
          "Kaniere",
          "Kumara",
          "Moana - Canterbury",
          "Omoto Valley",
          "Otira",
          "Paroa - West Coast",
          "Rapahoe",
          "Ross",
          "Taylorville",
          "Westland Area",
          "Westland Surrounds",
          "Whataroa",
        ]
      },
    ]
  }
]

export default regions