import React, { useState, useEffect } from 'react'

const Breadcrumbs = props => {

  const [breadcrumbs, setBreadcrumbs] = useState([props.page, props.subPage])

  useEffect(() => {
    setBreadcrumbs([props.page, props.subPage])
  }, [props])

  return (
    <div className="kpod-breadcrumbs row">
      {breadcrumbs.map((crumb, i, arr) => {
        return (
          <div key={i} className="kpod-breadcrumbs">
            {arr[i + 1]
              ? <div key={i} className="kpod-breadcrumb" onClick={() => {
                props.setSubPage('')
              }
              }>
                {`${crumb} >`}
              </div>
              : <div key={i} className="kpod-breadcrumb">
                {crumb}
              </div>
            }
          </div>
        )
      })}
    </div>
  )

}

export default Breadcrumbs