export function getObjCopy(obj) {
  let newObj = Object.assign({}, obj)
  return newObj
}

export function getArrObjCopy(array) {
  let newArray = []
  array.forEach(item => {
    let object = Object.assign({}, item)
    newArray.push(object)
  })
  return newArray
}