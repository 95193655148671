
const navOptions = [
  {
    name: "properties",
    displayTitle: "Properties",
    subMenus: [
      {
        name: "published",
        aka: "live",
        handler: "status",
      },
      {
        name: "unpublished",
        aka: "draft",
        handler: "status",
      },
      {
        name: "sold",
        aka: "sold",
        handler: "status",
      },
      {
        name: "Rented",
        aka: "rented,leased",
        handler: "status",
      },
      {
        name: "withdrawn",
        aka: "withdrawn",
        handler: "status",
      },
      {
        name: "archived",
        aka: "archived",
        handler: "status",
      },
    ],
    isAdmin: false,
  },
  {
    name: "web",
    displayTitle: "Web Pages",
    subMenus: [
      {
        name: "featured property",
        aka: "featured",
        handler: "",
      },
      {
        name: "page contents",
        aka: "contents",
        handler: "",
      },
    ],
    isAdmin: false,
  },
  {
    name: "users",
    displayTitle: "User Profiles",
    subMenus: [
      {
        name: "inactive salespeople",
        aka: "inactive",
        handler: "status",
      },
      {
        name: "active salespeople",
        aka: "active",
        handler: "status",
      },
      {
        name: "k-pod admin users",
        aka: "administrator",
        handler: "role",
      },
    ],
    isAdmin: true,
  },
  // {
  //   name: 'reports',
  //   displayTitle: 'Reports',
  //   subMenus: [],
  //   isAdmin: true
  // }
];

export default navOptions