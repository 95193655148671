import React, { useEffect, useState } from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import IconButton from '@material-ui/core/IconButton';
import { getArrObjCopy } from '../getCopies.js'

import DragDropContainer from './DragDropContainer.jsx'
import { updateAgentPositions } from '../api.js'

const OrderAgents = props => {
  const [data, setData] = useState([])
  const [updatePositionSuccess, setUpdatePositionSuccess] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (props.data.length !== data.length) {
      let dataCopy = getArrObjCopy(props.data)
      dataCopy = dataCopy.sort((a, b) => {
        return a.position - b.position
      })
      setData(dataCopy)
    }
  }, [props.data])

  // const onDragStart = (event, userName) => {
  //   console.log('dragstart on div: ', userName);
  //   event.dataTransfer.setData("userName", userName);
  // }

  // const onDragOver = (event) => {
  //   console.log('on drag over')
  //   event.stopPropagation();
  //   event.preventDefault();
  // }

  // const onDrop = (event, cat) => {
  // }

  const handleUpdatePositions = () => {
    let dataCopy = getArrObjCopy(data)
    let newPositions = []

    dataCopy.forEach((agent, idx) => {
      let newAgent = {}
      newAgent.id = agent.id
      newAgent.position = idx + 1;
      newPositions.push(newAgent)
    })
    updateAgentPositions(newPositions, () => setUpdatePositionSuccess(true), handleError)
  }

  const handleError = (err) => {
    setError(err)
  }

  return (
    <div className="kpod-container">
      <h1 className="kpod-main-title">
        Order Salespeople
      </h1>
      <div className="kpod-items-banner">
        <div className="kpod-buttons kpod-order-info row">
          {error && error.error_description ?
            <Alert severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small">
                </IconButton >
              }>
              <AlertTitle className="alert-title">
                {error.error_description}
              </AlertTitle>
            </Alert >
            :
            updatePositionSuccess ?
              <Alert severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small">
                  </IconButton >
                }>
                <AlertTitle className="alert-title">
                  Salespeople positions updated successfully
              </AlertTitle>
              </Alert >
              : <div />}
          <div className="kpod-buttons">
            <button className="kpod-button" onClick={() => props.setIsOrdering(false)}>
              List Salespeople
          </button>
            <button className="kpod-button" onClick={handleUpdatePositions}>
              Save Order
          </button>
          </div>
        </div>
      </div>
      <div className="kpod-ordering-container">
        <DragDropContainer
          data={data}
          dragType="user"
          setData={(d) => setData(d)} />
      </div>
    </div>
  )

}

export default OrderAgents