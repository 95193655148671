import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getData } from './api'
import { formatDate, formatTimes } from './formatDateTimes'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'


const PropertyPrintView = props => {

  const { url } = useParams()

  const [property, setProperty] = useState(null)
  const [img1Url, setImg1Url] = useState('')
  const [img2Url, setImg2Url] = useState('')
  const [img3Url, setImg3Url] = useState('')

  useEffect(() => {

    if (!props.property) {
      let propertyUrl = `properties/link/${url}`
      getData(propertyUrl, handleSetProperty)
    } else {

      handleSetProperty(props.property)
    }
  }, [props.property, url])


  const handleSetProperty = (data) => {

    setProperty(data)
    handleSetImageUrls(data)
  }

  const handleSetImageUrls = (data) => {

    let img1 = data.images.find(i => i.position === 1)
    let url1 = `${cloudFrontURL}/properties/${data.id}/images/${img1.storageKey}`
    setImg1Url(url1)

    let img2 = data.images.find(i => i.position === 2)
    let url2 = `${cloudFrontURL}/properties/${data.id}/images/${img2.storageKey}`
    setImg2Url(url2)

    let img3 = data.images.find(i => i.position === 3)
    let url3 = `${cloudFrontURL}/properties/${data.id}/images/${img3.storageKey}`
    setImg3Url(url3)
  }

  if (property) {
    return (
      <div className="main-body print-view-container column">
        <div id="header" className="main-header-lg">
          <a id="logo-lg" href="/">
            <img src="/images/logo.png" alt="Kellands Real Estate" />
          </a>
        </div>
        <div className="row">
          <div className="print-page-left column">
            <div className="print-title">
              {property.address.subNumber ? `${property.address.subNumber}/` : ''}
              {`${property.address.streetNumber} `}
              {property.address.street}
            </div>
            <div className="print-title uppercase">
              {property.address.suburb}
            </div>

            <div className="row property-print-feature">
              <div className="row">
                <img className="feature-print-icon" src="/images/print_bed.gif" alt="bed" />
                <div className="feature-print-text">
                  {property.bedrooms}
                </div>
              </div>
              <div className="row">
                <img className="feature-print-icon" src="/images/print_bath.gif" alt="bath" />
                <div className="feature-print-text">
                  {property.bathrooms}
                </div>
              </div>
              <div className="row">
                <img className="feature-print-icon" src="/images/print_carCover.gif" alt="garages" />
                <div className="feature-print-text">
                  {property.garages}
                </div>
              </div>
              <div className="row">
                <img className="feature-print-icon" src="/images/print_car.gif" alt="car" />
                <div className="feature-print-text">
                  {property.offstreetParking}
                </div>
              </div>
              <div className="row">
                <img className="feature-print-icon" src="/images/landsize_icon.png" alt="land" />
                <div className="feature-print-text">
                  {Number(property.floorArea) ? property.floorArea : property.landArea}
                </div>
              </div>
            </div>

            <div className="print-title print-subheader">
              {property.saleMethod}
            </div>
            {property.auctionDate && property.saleMethod === 'Auction' ?
              <p className="print-body-text">
                {formatDate(property.auctionDate)} {formatTimes(property.auctionTime)} @ {property.auctionLocationOther
                  ? property.auctionLocationOther
                  : property.auctionLocation}
              </p>
              :
              <p className="print-body-text">
                {` ${property.saleMethod} 
               ${property.isByNegotiation
                    ? ' - By Negotiation'
                    : ''} `}
              </p>}

            <div className="print-title print-subheader">
              Open Homes
          </div>
            <p className="print-body-text column">
              {property.openhomes ?
                property.openhomes.map((date) => {
                  return (
                    <div>
                      {date && date.dayOn && `${formatDate(date.dayOn)}`}
                      {` ${date.startTime} -  ${date.endTime}`}
                    </div>
                  )
                })
                : <div>
                  Please contact agent for appointment to view.
                  </div>
              }
            </p>

            <div className="print-title print-subheader">
              {property.mainHeading}
            </div>
            <p className="print-body-text"
              dangerouslySetInnerHTML={{ __html: property.mainText }}>
            </p>

            <div className="print-title print-subheader">
              Features
            </div>
            {property.additionalFeatures ?
              <div className="print-body-text">
                <ul>
                  {property.additionalFeatures.map(f => {
                    return (
                      <li className="info-list-item">
                        {f.feature}
                      </li>
                    )
                  })}
                </ul>
              </div>
              : <div />}

            <div className="print-title print-subheader">
              Agent Information
            </div>
            {property.agents ?
              property.agents.map(agent => {
                return (
                  <div className="print-agent-item column">
                    <div className="print-body-text bold">
                      {agent.displayName}
                    </div>
                    <div className="print-body-text">
                      PH: {agent.phone}
                    </div>
                    <div className="print-body-text">
                      MOB: {agent.mobile}
                    </div>
                    <div className="print-body-text">
                      {agent.email}
                    </div>
                  </div>
                )
              })
              : <div />}
            <div className="print-page-disclaimer">
              This Information has been obtained on behalf of the Vendor and copies have been made available to prospective purchasers and interested parties for general information purposes only. However, neither the Vendor nor Kellands Real Estate Limited warrant the accuracy of this copy and they accept no liability for any errors or omissions in the report. It is recommended to all prospective Purchasers and interested parties that they conduct their own due diligence.
            </div>
          </div>
          <div className="print-page-right column">
            <img className="print-page-image" src={img1Url} alt="" />
            <img className="print-page-image" src={img2Url} alt="" />
            <img className="print-page-image" src={img3Url} alt="" />
          </div>
        </div>
      </div>
    )
  } else return (<div></div>)
}

export default PropertyPrintView