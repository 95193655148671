import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import navOptions from './navOptions.js'

const Nav = props => {
  const history = useHistory()

  const [navLocation, setNavLocation] = useState(/[^/]*$/.exec(history.location.pathname)[0])
  const [subPage, setSubPage] = useState('')

  useEffect(() => {
    if (props.page !== navLocation) {
      setNavLocation(props.page)
    } else if (props.page === 'users' && !subPage.length) {
      props.setSubPage('active salespeople')
    } else if (props.page === 'web' && !subPage.length) {
      props.setSubPage('featured')
    }
    if (props.subPage !== subPage) {
      setSubPage(props.subPage)
    }
    // clearSearch()
  }, [props, navLocation, subPage, props.subPage])

  useEffect(() => {
    if (!props.loggedIn) {
      handleSetNav('login')
    }
  }, [props.loggedIn])

  const handleSetNav = (page) => {
    setNavLocation(page)
    if (page === 'web') {
      props.setSubPage('featured')
      props.setDataFilter('featured')
    } else if (page === 'users') {
      props.setSubPage('active salespeople')
      props.setDataFilter('active')
    } else {
      props.setSubPage('')
      props.setDataFilter('all')
    }
    props.setPage(page)
  }

  const clearSearch = () => {
    props.setClearSearchQuery(true)
    props.setSearchData(null)
    props.setIsSearchData(false)
  }

  const handleSubPage = (subPage) => {
    // props.handleGetData(props.page, isAdmin, handler, subPage)
    props.setSubPage(subPage.name)
    props.setDataFilter(subPage.aka)
    clearSearch()
    setSubPage(subPage.name)
  }

  return (
    <div className="kpod-nav kpod-nav-w">
      <Link className={`kpod-nav-main kpod-nav-a kpod-nav-${navLocation === 'home' ? 'active' : 'notactive'}`}
        to={props.loggedIn ? '/account' : '/'}
        onClick={() => handleSetNav('home')}>
        Home
      </Link>
      {(!props.loggedIn || props.page === 'login') ?
        <Link className={`kpod-nav-main kpod-nav-a kpod-nav-${navLocation === 'login' ? 'active' : 'notactive'}`}
          to="/account/login"
          onClick={() => handleSetNav('login')}>
          Login
        </Link>
        :
        <div className="kpod-nav">
          {navOptions.map((option, i) => {
            return (
              <div key={i} className={`kpod-nav-a kpod-nav-${navLocation === option.name ? 'active' : 'notactive'}`}>
                <Link className={`kpod-nav-main ${navLocation === option.name ? 'a-active' : 'a-notactive'}`}
                  to={`/account/${option.name}`}
                  onClick={() => handleSetNav(option.name, option.isAdmin)}>
                  {option.displayTitle}
                </Link>
                {option.subMenus.length ?
                  navLocation === option.name &&
                  <div className="kpod-subnav-container">
                    {option.subMenus.map((subOption, i) => {
                      return (
                        <Link key={i} to={`/account/${props.page}`} className={`kpod-nav-sub-a`}
                          onClick={() => handleSubPage(subOption)}>
                          {subOption.name}
                        </Link>
                      )
                    })}
                  </div>
                  : <div />
                }
              </div>
            )
          })}
          {/* <div className={`kpod-nav-main kpod-nav-a kpod-nav-${navLocation === 'logout' ? 'active' : 'notactive'}`}
            onClick={handleLogout}>
            Logout
          </div> */}
        </div>
      }
    </div>
    // </div>
  )
}

export default Nav