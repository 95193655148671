import React, { Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { findDOMNode } from 'react-dom'
import flow from 'lodash/flow'
const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

class DragDropItem extends Component {

  constructor(props) {
    super(props)
    this.state = {

    }
  }

  render() {
    const { connectDragSource, connectDropTarget } = this.props

    return connectDragSource(connectDropTarget(
      this.props.dragType === 'user' ?
        <div key={this.props.item.position} className="kpod-ordering-item kpod-user-drag-item" draggable>
          <div className={`whatson-listing-img-container kpod-user-img
              ${this.props.displayType === 'small' && 'greyscale-img'}`}>
            <img src={
              `${cloudFrontURL}/users/${this.props.item.id}/images/${this.props.item.storageKey}`}
              onError={(e) => { e.target.src = '/images/image-default-agent.png' }}
              className="kpod-item-img"
              alt="">
            </img>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="none" className="image-corner">
              <polygon points="100,100 100,0 0,100"></polygon>
            </svg>
            <span className="kpod-user-status">
              {this.props.item.status}
            </span>
          </div>
          <div className="kpod-ordering-item-title">
            {this.props.item.displayName}
          </div>
        </div>
        : <div key={this.props.item.position} className="kpod-img-thumbnail-container">
          <img src={`${cloudFrontURL}/properties/${this.props.propertyId}/images/${this.props.item.storageKey}`}
            className="kpod-img-thumbnail"
            alt=""
          />
        </div>
    ))
  }
}


const segmentSource = {
  beginDrag(props) {
    return {
      index: props.index,
      user: props.user
    }
  },

  endDrag(props, monitor) {
    let from = props.index;
    let to = monitor.getItem().index
    props.updateOrder(from, to)
  }
}

const segmentTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // const sourceListId = monitor.getItem().listId	
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()

    // Get vertical middle
    // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    //   return
    // }

    // // Dragging upwards
    // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    //   return
    // }

    // Time to actually perform the action
    // if (props.listId === sourceListId ) {

    props.moveItem(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.


    monitor.getItem().index = hoverIndex
    // }		
  }
}

// export default DragDropItem
export default flow(
  DropTarget("ITEM", segmentTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  })),
  DragSource("ITEM", segmentSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)
  (DragDropItem)