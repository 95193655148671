import React, { useState } from 'react'

import Pagination from '@material-ui/lab/Pagination'

const PagePagination = props => {
  const [paginationPage, setPaginationPage] = useState(1)

  const handleChangePage = (e, page) => {
    let o = props.limit * (page - 1)
    let offset = o
    props.setOffset(offset)
    setPaginationPage(page)
  }

  return (
    <div id="kpod-pagination-container" className="kpod-banner row">
      <Pagination
        count={Math.floor(props.count / 10)}
        page={paginationPage}
        onChange={(e, p) => handleChangePage(e, p)}
        color="secondary"
        size="large"
      />
    </div>
  )
}

export default PagePagination