import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { getRedirectURI } from './api';
import PropertyNotFound from './PropertyNotFound';

const WebIdRedirect = props => {
  const [URI, setURI] = useState(null)
  const [notFound, setNotFound] = useState(false)

  const { webId } = useParams();

  useEffect(() => {
    let idNum = Number(webId)
    // Only appears on routes /:number
    if (!Number.isNaN(idNum)) {
      getRedirectURI(webId, setURI, handleURIError)
    }
  }, [])


  const handleURIError = (error) => {
    if (error.status === 404) {
      setNotFound(true)
    }
  }

  if (URI) {
    return (
      window.location.href = `/property/view/${URI}`
    )
  } else if (notFound) {
    return (
      <PropertyNotFound />
    )
  } else {
    return (
      <div />
    )
  }
}

export default WebIdRedirect;