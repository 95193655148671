import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { getPageContent } from './api'

const Privacy = props => {
  const [privacyContent, setPrivacyContent] = useState('')

  useEffect(() => {
    getPrivacyContent()
  }, [])

  const getPrivacyContent = () => {
    getPageContent('privacy', (data) => setPrivacyContent(data[0].value))
  }

  return (
    <div className="main-body column">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content='Privacy | Kellands Real Estate'/>
        <title>Privacy | Kellands Real Estate</title>
      </Helmet>
      <h1 className="main-page-header">
        Privacy
      </h1>
      <div className="row">
        <div className="privacy-text">
          <p className="about-text"
            dangerouslySetInnerHTML={{ __html: privacyContent }}>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Privacy