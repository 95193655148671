import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { Helmet } from 'react-helmet'

import { openEmail } from './utls'
import { isServer } from './isServer'
import { contactFormSubmit } from './api'

const ContactUs = props => {
  // const [map, setMap] = useState(null)
  // const [maps, setMaps] = useState(null)
  // const [bounds, setBounds] = useState(null)
  // const [markers, setMarkers] = useState([])

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [feedback, setFeedback] = useState('')

  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)


  const resetForm = () => {
    setName('')
    setPhone('')
    setEmail('')
    setFeedback('')
  }

  const submitForm = () => {
    if (validateEmail()) {
      let body = {
        "name": name,
        "phone": phone,
        "email": email,
        "feedback": feedback
      }
      contactFormSubmit(body, submitFormSuccess, handleSubmitError)
    } else {
      setEmailInvalid(true)
    }
  }

  const validateEmail = () => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email)
  }

  const handleSubmitError = (error) => {
    setError(error)
  }

  const submitFormSuccess = () => {
    setSuccess(true)
  }

  const handleGoogleApiLoaded = (map, maps) => {
    renderMapMarkers(map, maps)
    // setMap(map)
    // setMaps(maps)
  }

  const renderMapMarkers = (map, maps) => {
    new maps.Marker({
      position: { lat: -36.84844748516946, lng: 174.7556714382743 },
      icon: '/images/GMapsMarker.png',
      map,
      title: ''
    })
    // let newMarker = [marker]
    // setMarkers(newMarker)
  }
  return (
    <div className="main-body column">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content="Contact Us | Kellands Real Estate"/>
        <title>Contact Us | Kellands Real Estate</title>
        <meta property="og:description" content="We would love to hear from you - send us your enquiry or free appraisal request" />
        <meta property="description" content="We would love to hear from you - send us your enquiry or free appraisal request" />
      </Helmet>
      <h1 className="main-page-header">
        Contact Us
      </h1>
      <div className="contactus-container main-text">
        {!success ?
          <div className="half-width">
            <fieldset>
              <p>
                {/* Send us Feedback */}
               We would love to hear from you - send us your enquiry or free appraisal request below
              </p>
              <p className="contactus-inputs">
                <label className="required" for="fname">Your Name *</label>
                <input className="contactus-input"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type="text" name="f_Name" id="fname" />
              </p>
              <p className="contactus-inputs">
                <label for="fcontactphone">Phone Number</label>
                <input className="contactus-input"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  type="text" name="f_ContactPhone" id="fcontactphone" />
              </p>
              <p className="contactus-inputs">
                <label className="required" for="femail">E-mail Address *</label>
                <input className={`contactus-input contactus-input-${emailInvalid}`}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  type="text" name="f_Email" id="femail" />
              </p>
              <input type="hidden" name="f_Office" id="foffice" value="info@kellands.co.nz" />
              <p className="contactus-inputs">
                <label className="required" for="fcomment">
                  {/* Feedback * */}
                  Enquiry and/or Property Address*
                  </label>
                <textarea className="contactus-input"
                  onChange={(e) => setFeedback(e.target.value)}
                  value={feedback}
                  cols="" rows="4" name="f_Comment" id="fcomment">
                </textarea>
              </p>
            </fieldset>
            <fieldset id="submit" className="contactus-submit-container">
              <p>
                <em>Note: fields marked with a <span>*</span> are required to submit this form.</em>
              </p>
              <div className="contactus-submit-btns">
                <input type="reset" name="Clear"
                  onClick={() => resetForm()}
                  className="contactus-form-btn contactus-reset"
                  value="Reset" />
                <input type="submit" name="Submit"
                  onClick={() => submitForm()}
                  className="contactus-form-btn"
                  value="Submit" />
              </div>
            </fieldset>
          </div>
          :
          <div className="contactus-form contactus-left">
            {/* Thank you for your feedback. */}
            Thanks for your enquiry. We will be in touch.
          </div>
        }
        <div className="contactus-right">
          <h2 className="contactus-kre">
            Kellands Real Estate Limited
          </h2>
          <div className="contact-details">
            <p className="contactus-p">
              4 Drake St, Freemans Bay<br />
                Auckland
            </p>
            <p className="contactus-p">
              <strong className="bold">Phone:</strong> +64 9 300 4001
            </p>
            <p className="contactus-p cursor" onClick={() => openEmail('info@kellands.co.nz', isServer, `Contact Request`, "")}>
           
              <b> <strong className="bold">Email:</strong> info@kellands.co.nz</b>
            </p>
            <p className="contactus-p">
              &nbsp;
            </p>
            <p className="contactus-p">
              <strong className="bold">Mailing Address</strong>
            </p>
            <p className="contactus-p">
              PO Box 911 223
            </p>
            <p className="contactus-p">
              Victoria Street West
            </p>
            <p className="contactus-p">
              Auckland 1142
            </p>
          </div>
        </div>
      </div>
      <div className="property-map-container">
        <GoogleMapReact
          bootstrapURLKeys={{
            libraries: ['places']
          }}
          center={{ lat: -36.84844748516946, lng: 174.7556714382743 }}
          defaultCenter={{ lat: 0, lng: 0 }}
          zoom={15}
          defaultZoom={15}
          // bounds={bounds}
          onGoogleApiLoaded={({ map, maps }) => handleGoogleApiLoaded(map, maps)}
        >
        </GoogleMapReact>
      </div>
    </div>
  )
}

export default ContactUs