import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getData } from './api'
import { isServer } from './isServer'
import { formatDate, formatTimes, getFullDateStartTime } from './formatDateTimes'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

const ListingsPrintView = props => {
  const { type } = useParams()
  const [data, setData] = useState([])

  useEffect(() => {
    if (!props.data) {
      let url = ''
      type === 'openhomes'
        ? url = 'properties?openhomesonly=true&status=live'
        : url = 'properties?sale_method=auction&status=live'
      getData(url, (d, count) => handleFormatData(d, count, type))
    } else {
      handleSetData(props.property)
    }
  }, [props, type])

  useEffect(() => {
    if (data.length) {
      if (!isServer) {
        window.print()
      }
    }
  }, [data])


  const handleSetData = (data) => {
    setData(data)
  }

  const handleFormatData = (data, count, type) => {
    let formatted = []
    data.map(listing => {
      if (type === 'auctions') {
        let auctionDateFormatted = formatDate(listing.auctionDate)
        if (!formatted.find(f => {
          return f.auctionDate === listing.auctionDate
        })) {
          listing.auctionTime = formatTimes(listing.auctionTime)
          listing.fullAuctionDateTime = getFullDateStartTime(listing.auctionTime, listing.auctionDate)
          let newAuction = {
            auctionDate: listing.auctionDate,
            date: auctionDateFormatted,
            id: listing.id,
            listings: [{ ...listing }]
          }
          formatted.push(newAuction)
        } else {
          let index = formatted.map((x) => {
            return x.auctionDate
          }).indexOf(listing.auctionDate)
          formatted[index].listings.push(listing)
        }

        formatted = formatted.sort((a, b) => {
          return new Date(a.auctionDate) - new Date(b.auctionDate)
        })

        formatted.forEach(day => {
          day.listings.sort((a, b) => {
            let aTime = new Date(a.fullAuctionDateTime);
            let bTime = new Date(b.fullAuctionDateTime);
            return aTime - bTime;
          })
        })

      } else if (type === 'openhomes') {
        listing.openhomes.forEach(op => {
          op.fullDateTime = getFullDateStartTime(op.startTime, op.dayOn)
          if (!formatted.find(f => {
            return f.dayOn === op.dayOn
          })) {
            op.startTime = formatTimes(op.startTime)
            op.endTime = formatTimes(op.endTime)
            // op.dayOn = formatDate(op.dayOn)
            let newOpenHome = {
              date: formatDate(op.dayOn),
              dayOn: op.dayOn,
              id: op.id,
              listings: [{
                ...listing
              }]
            }
            formatted.push(newOpenHome)
          } else {
            let index = formatted.map((x) => {
              return x.dayOn
            }).indexOf(op.dayOn)
            formatted[index].listings.push(listing)
          }

          formatted = formatted.sort((a, b) => {
            return new Date(a.dayOn) - new Date(b.dayOn)
          })

          // Sort listings by time
          formatted.forEach(day => {
            day.listings.sort((a, b) => {
              // Only get openhome for listing that matches the day
              let openHomeA = a.openhomes.filter(op => op.dayOn === day.dayOn)
              let openHomeB = b.openhomes.filter(op => op.dayOn === day.dayOn)

              // What if two open homes for the same property on the same day?
              // openHomeA.forEach?
              // Will sort by first/earliest time
              let aTime = new Date(openHomeA[0].fullDateTime);
              let bTime = new Date(openHomeB[0].fullDateTime);

              return aTime - bTime;
            })
          })
        })
      }
      return true
    })

    setData(formatted)
  }

  return (
    <div className="main-body print-view-container column">
      <div id="header" className="main-header-lg">
        <a id="logo-lg" href="/">
          <img src="/images/logo.png" alt="Kellands Real Estate" />
        </a>
      </div>
      <div className="print-listing-title">
        {type === 'openhomes' ? 'Open Homes' : 'Upcoming Auction'}
      </div>
      <div className="column main-body">
        <div className="row">
          <div className="print-row-item">
          </div>
          <div className="print-row-item print-title uppercase">
            Address
          </div>
          <div className="print-row-item print-title uppercase">
            Times
          </div>
        </div>
        {data.map((d) => {
          return (
            <div className="column" key={d.id}>
              <div className="print-title print-listing-date">
                {d.date}
              </div>
              {d.listings.map((l) => {
                let img = l.images.find(i => i.position === 1)
                return (
                  <div className="row">
                    <div className="print-row-item">
                      <img className='print-page-image-sm'
                        alt="printicon"
                        src={`${cloudFrontURL}/properties/${l.id}/images/${img.storageKey}`} />
                    </div>
                    <div className="print-row-item">
                      <div className="column">
                        <div className="bold uppercase">
                          {`${l.address.suburb},`}
                        </div>
                        {`${l.address.streetNumber} ${l.address.street}`}
                      </div>
                    </div>
                    <div className="print-row-item">
                      {type === 'openhomes' ?
                        l.openhomes.map((op) => {
                          if (op.dayOn === d.dayOn) {
                            return (
                              `${op.startTime} -  ${op.endTime}`
                            )
                          }
                          return true
                        })
                        : type === 'auctions' ?
                          l.auctionTime
                          : <div />}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ListingsPrintView