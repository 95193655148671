import React, { useState, useEffect } from 'react'
import { createBrowserHistory } from 'history'
import { Link, useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import { isServer } from './isServer'

import { getData } from './api'
import Listing from './Listing.jsx'
import PagePagination from './PagePagination.jsx'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'
// let history = createBrowserHistory()

const AgentQueryListings = props => {
  let history = useHistory()
  const [shouldRender, setShouldRender] = useState(false)
  const [agentName, setAgentName] = useState('')
  const [status, setPropertyStatus] = useState('')

  const [dataCount, setDataCount] = useState(0)
  const [data, setData] = useState([])
  const [limit, setLimit] = useState(12)
  const [offset, setOffset] = useState(0)

  const [isMobile, setIsMobile] = useState(!isServer ? window.innerWidth <= 800 : false)

  useEffect(() => {
    // Strict rules to make sure component does not render information when on standard agentlisting route
    if (history.location.search.length) {
      if (history.location.search.includes('?agent=')) {
        // If using old route /agentslistings?agent=agentname&status=propertystatus
        setShouldRender(true)
        handleSetValues()
      }
      if (agentName.length) {
        handleGetData()
      }
    }
  }, [offset, limit, agentName])

  function handleSetValues() {
    let params = new URLSearchParams(history.location.search).entries()
    for (let pair of params) {
      if (pair[0] === 'agent') {
        setAgentName(pair[1])
      } else if (pair[0] === 'status') {
        setPropertyStatus(pair[1].toLowerCase())
      } else if (pair[0] === 'offset') {
        setOffset(pair[1])
      } else if (pair[0] === 'limit') {
        setLimit(pair[1])
      }
    }
  }

  function handleGetData() {
    // Get listings by agent name
    let url = `agents/name/${agentName}/properties?status=${status}&limit=${limit}&offset=${offset}`
    if (status === 'sold') {
      url += '&order_by=sold_on&sort=desc'
    }
    getData(url, handleSetData)
  }

  const handleSetData = (data, count) => {
    setDataCount(count)
    setData(data)
  }

  if (shouldRender) {
    return (
      <div className="main-body">
        <div className="column">
          <h1 className="main-page-header">
            {agentName.length ?
              (agentName[agentName.length - 1] !== 's'
                ? agentName.replace('-', ' ') + "'s "
                : agentName.replace('-', ' ') + "' ")
              + (status + " ")
              + "Listings"
              : ''}
          </h1>
          <div className="listings-container">
            {data.length ? data.map((property, idx) => {
              return (
              // Use standard link for property view page
              // This page is loaded on the server
                <a href={`/property/view/${property.url}`}
                  className={`listing-container ${isMobile ? 'listing-container-mobile' : ''}`}
                  key={property.id}>
                  <Listing property={property} idx={idx} />
                </a>
              )
            })
              : <div />}
          </div>
        </div>
        {dataCount > 12 ?
          <PagePagination
            limit={limit}
            setLimit={(rpp) => setLimit(rpp)}
            offset={offset}
            setOffset={(startIdx) => setOffset(startIdx)}
            count={dataCount}
          />
          : <div />
        }
      </div>
    )
  } else {
    return (
      <div />
    )
  }
}

export default AgentQueryListings