import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import { logout } from '../api'
import { isServer } from '../isServer'

import Search from './Search.jsx'

const Banner = props => {
  // const history = useHistory()
  const location = useLocation()
  const [user, setUser] = useState('')

  useEffect(() => {
    if (!isServer) {
      setUser(localStorage.getItem('user'))
    }
  }, [])

  const handleLogout = () => {
    props.setLogin(false)
    logout()
    history.push('/account')
  }

  return (
    <div id="kpod-header" className="kpod-banner row">
      <Link id="logo" to="/account">
        <img src="/images/logotransparent.png" alt="Kellands Real Estate" />
      </Link>
      {/* {props.loggedIn && !location.pathname.includes('web')
        && !location.pathname.includes('edit') ? */}
        {!location.pathname.includes('web') 
        && !location.pathname.includes('edit') 
        && !location.pathname.includes('login') ?
        <div id="search-container" className="right row kpod-banner-r">
          <Search
            setSearchData={props.setSearchData}
            subPage={props.subPage}
            searchType={props.page}
            setSearchTerm={props.setSearchTerm}
            clearSearchQuery={props.clearSearchQuery}
            setClearSearchQuery={props.setClearSearchQuery}
            setIsSearchData={props.setIsSearchData}
            dataFilter={props.dataFilter}
          />
          <div className="kpod-banner-loggedin-container">
            <div className="kpod-user-info">
              Hello, {user}
            </div>
            <div className="kpod-banner-btn"
              onClick={() => handleLogout()}>
              Logout
          </div>
            <Link to='/'>
              <div className="kpod-banner-btn">
                Kellands Home
            </div>
            </Link>
          </div>
        </div>
         : <div></div>}
    </div >
  )
}

// export default withStyles(styles)(Banner)
export default Banner