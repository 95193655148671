import React, { useState, useEffect } from 'react'
import { Route, useHistory } from 'react-router'
import { Helmet } from 'react-helmet'

import Login from './Login.jsx'
import Banner from './Banner.jsx'
import Nav from './Nav.jsx'
import Properties from './Properties.jsx'
import Reports from './Reports.jsx'
import Users from './Users.jsx'
import Web from './Web.jsx'
import Breadcrumbs from './Breadcrumbs.jsx'
import UserForm from './UserForm.jsx'
import PropertyForm from './PropertyForm.jsx'

import './KPod.css'
import './KPod-1150-min.css'
import './KPod-900-min.css'

import { logout } from '../api'
import { isServer } from '../isServer'

const Main = props => {
  const history = useHistory()

  let routePage = /[^/]*$/.exec(history.location.pathname)[0]
  if (history.location.pathname.includes('users')) {
    routePage = 'users'
  } else if (history.location.pathname.includes('properties')) {
    routePage = 'properties'
  }
  const [hasToken, setHasToken] = useState(!isServer ? localStorage.getItem('token') : false)
  const [loggedIn, setLogin] = useState(hasToken)
  const [page, setPage] = useState(routePage === 'account' ? 'home' : routePage)
  const [subPage, setSubPage] = useState('')
  const [dataFilter, setDataFilter] = useState(page === 'users' ? 'active' : 'all')
  const [searchData, setSearchData] = useState(null)
  const [isSearchData, setIsSearchData] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [clearSearchQuery, setClearSearchQuery] = useState(true)

  useEffect(() => {
    if (history.location.pathname !== '/account/login' && hasToken) {
      const expiry = !isServer ? localStorage.getItem('expiresat') : null
      const curTime = Math.floor(Date.now() / 1000)
      if (expiry) {
        if (curTime >= expiry) {
          setLogin(false)
          logout()
          setPage('login')
        } else {
          // setLogin(true)
        }
      }
    }
    if (!loggedIn && !history.location.pathname.includes('resetpassword')) {
      history.push('/account/login')
    }
  }, [loggedIn, hasToken, history, page, subPage])

  return (
    <div key="kpod-wrapper" id="kpod-wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content='KPod | Kellands Real Estate'/>
        <title>KPod | Kellands Real Estate</title>
      </Helmet>
      <div id="wrap" className="kpod-wrapper-inner">
        <Banner
          subPage={subPage}
          page={page}
          setLogin={(isLoggedIn) => setLogin(isLoggedIn)}
          setPage={(p) => setPage(p)}
          setIsSearchData={(bool) => setIsSearchData(bool)}
          setSearchData={(data) => setSearchData(data)}
          setSearchTerm={(t) => setSearchTerm(t)}
          clearSearchQuery={clearSearchQuery}
          setClearSearchQuery={(bool) => setClearSearchQuery(bool)}
          dataFilter={dataFilter}
          loggedIn={loggedIn}
        />
        <div className="kpod-dashboard-outer">
          <Nav
            page={page}
            subPage={subPage}
            setPage={(p) => setPage(p)}
            setSubPage={(sp) => setSubPage(sp)}
            setDataFilter={(filter) => setDataFilter(filter)}
            loggedIn={loggedIn}
            setLogin={(isLoggedIn) => setLogin(isLoggedIn)}
            setSearchData={(d) => setSearchData(d)}
            setIsSearchData={(bool) => setIsSearchData(bool)}
            setClearSearchQuery={(bool) => setClearSearchQuery(bool)}
          />
          <div className="kpod-dashboard">
            <Breadcrumbs
              page={page}
              subPage={subPage}

              setSubPage={(sp) => setSubPage(sp)}
            />
            <Route path="/account/login" component={() =>
              <Login
                setLogin={(isLoggedIn) => setLogin(isLoggedIn)}
                setHasToken={(hasToken) => setHasToken(hasToken)}
                setPage={(page) => setPage(page)}
                hasToken
                loggedIn={loggedIn}
              />
            } />
            <Route path="/account/resetpassword" component={() =>
              <Login
                setLogin={(isLoggedIn) => setLogin(isLoggedIn)}
                setHasToken={(hasToken) => setHasToken(hasToken)}
                setPage={(page) => setPage(page)}
                hasToken
                loggedIn={loggedIn}
              />
            } />
            {loggedIn ?
              <div>
                <Route path="/account/properties/edit/:id" component={() =>
                  <PropertyForm
                    dataFilter={dataFilter}
                    setSubPage={(sp) => setSubPage(sp)}
                    isEdit={true}
                  />
                } />
                <Route exact path="/account/properties/edit" component={() =>
                  <PropertyForm
                    dataFilter={dataFilter}
                    setSubPage={(sp) => setSubPage(sp)}
                    isEdit={false}
                  />
                } />
                <Route exact path="/account/properties" component={() =>
                  <Properties
                    dataFilter={dataFilter}
                    setDataFilter={() => setDataFilter()}
                    isSearchData={isSearchData}
                    searchData={searchData}
                    searchTerm={searchTerm}
                    setSearchData={(data) => setSearchData(data)}
                    isDashboard={false}
                  />
                } />
                <Route path="/account/users/edit/:id" component={() =>
                  <UserForm
                    dataFilter={dataFilter}
                    setSubPage={(sp) => setSubPage(sp)}
                    isEdit={true}
                  />
                } />
                <Route exact path="/account/users/edit" component={() =>
                  <UserForm
                    dataFilter={dataFilter}
                    setSubPage={(sp) => setSubPage(sp)}
                    isEdit={false}
                  />
                } />
                <Route exact path="/account/users" component={() =>
                  <Users
                    subPage={subPage}
                    dataFilter={dataFilter}
                    setDataFilter={() => setDataFilter()}
                    searchData={searchData}
                    isSearchData={isSearchData}
                    searchTerm={searchTerm}
                    setSearchData={(data) => setSearchData(data)}
                  />
                } />
                <Route path="/account/web" component={() =>
                  <Web
                    dataFilter={dataFilter}
                    subPage={subPage}
                  />
                } />
                <Route path="/account/reports" component={() =>
                  <Reports
                    dataFilter={dataFilter}
                  />
                } />
                <Route exact path="/account" component={() =>
                  <Properties
                    dataFilter={dataFilter}
                    searchData={searchData}
                    isSearchData={isSearchData}
                    setDataFilter={() => setDataFilter()}
                    isDashboard={true}
                    setPage={(p) => setPage(p)}
                  />
                } />
              </div>
              : <div />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

// export default withStyles(styles)(Main)
export default Main