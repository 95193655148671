import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { isServer } from './isServer'

import Search from './Search.jsx'
import { getPageContent } from "./api"

const Header = props => {
  const [headerLocation, setHeaderLocation] = useState(useLocation())

  const [isMobile, setIsMobile] = useState(!isServer ? window.innerWidth <= 800 : false)
  const [isMedium, setIsMedium] = useState(false)
  const [navOpen, openNav] = useState(false)
  const [status, setPromotionStatus] = useState('')

  const location = useLocation()
  const handleResize = () => {
    if (!isServer) {
      if (window.innerWidth <= 800) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }

        if (window.innerWidth <= 1200) {
        setIsMedium(true)
      } else {
        setIsMedium(false)
      }
    }
  }

   const handlePromoStatusContent = (data) => {
    if (data.length) {
      setPromotionStatus(data[0].value)
    } else {
      setPromotionStatus("inactive")
    }
  }

   const handleError = (err) => {
    console.log(err)
  }


  useEffect(() => {
     getPageContent("status-promotion", (d) => handlePromoStatusContent(d), handleError)
    !isServer && window.addEventListener('resize', handleResize)
    setIsMedium(!isServer ? window.innerWidth <= 1200 : false)
    
  }, [])

 


  if (location.pathname.includes('printview')) {
    return null;
  } else return (
    <div id="header" className="main-header">
      <a id="logo" href="/">
        <img src="/images/logo.png" alt="Kellands Real Estate" />
      </a>
      {isMobile ?
        <div id="mobile-nav" className="main-navigation-mobile column">
          <ul className="main-nav-mobile-buttons row">
            <Search
              isMobile={isMobile}
              isMedium={isMedium}
              location={headerLocation}
              setLocation={setHeaderLocation}
              setSearchQuery={props.setSearchQuery}
              clearSearchQuery={props.clearSearchQuery}
              setClearSearchQuery={props.setClearSearchQuery}
            />
            <nav id="mobile-nav-li" className="mobile-menubar"  onClick={() => openNav(!navOpen)}>
              <i className="fa fa-bars"></i>
            </nav>
          </ul>
          {navOpen &&
            <ul className="mobile-navigation column">
              <li cl>
                <a className={`main-nav-mobile ${headerLocation.pathname === '/property' ? 'selected' : ''}`}
                  href="/property">
                  For Sale
              </a>
              </li>
              <li>
                <a className={`main-nav-mobile ${headerLocation.pathname === '/property/sold' ? 'selected' : ''}`}
                  href="/property/sold">
                  Sold
              </a>
              </li>
              <li>
                <a className={`main-nav-mobile ${headerLocation.pathname === '/rentals' ? 'selected' : ''}`}
                  href="/rentals">
                  Rentals
              </a>
              </li>
              <li>
                <a className={`main-nav-mobile ${headerLocation.pathname.includes('/ourpeople') ? 'selected' : ''}`}
                  href="/ourpeople">
                  Meet the team
              </a>
              </li>
               {status === "active" && (
                <li>
                  <a className={`main-nav-mobile ${headerLocation.pathname.includes('/promotion') ? 'selected' : ''}`}
                    href="/promotion">
                    Te Arai Promotion
                  </a>
              </li>
               )}  
            </ul>}
        </div>
        :
        <nav id="regular-nav">
          <ul className="main-navigation navigation">
            <li>
              <a className={`main-nav ${headerLocation.pathname === '/property' ? 'selected' : ''}`}
                href="/property">
                For Sale
              </a>
            </li>
            <li>
              <a className={`main-nav ${headerLocation.pathname === '/property/sold' ? 'selected' : ''}`}
                href="/property/sold">
                Sold
              </a>
            </li>
            <li>
              <a className={`main-nav ${headerLocation.pathname === '/rentals' ? 'selected' : ''}`}
                href="/rentals">
                Rentals
              </a>
            </li>
            <li>
              <a className={`main-nav ${headerLocation.pathname.includes('/ourpeople') ? 'selected' : ''}`}
                href="/ourpeople">
                Meet the team
              </a>
            </li>
             {status === "active" && (
               <li>
                <a className={`main-nav ${headerLocation.pathname.includes('/promotion') ? 'selected' : ''}`}
                  href="/promotion">
                  Te Arai Promotion
                </a>
              </li> 
             )}
            <Search
              isMobile={isMobile}
              isMedium={isMedium}
              location={headerLocation}
              setLocation={setHeaderLocation}
              setSearchQuery={props.setSearchQuery}
              clearSearchQuery={props.clearSearchQuery}
              setClearSearchQuery={props.setClearSearchQuery}
            />
          </ul>
        </nav>
      }
      <div>     
         <div className="slogan">
        Homes to love.
      </div>
      </div>

    </div>
  )
}

export default Header