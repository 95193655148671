import React from 'react'
import DragDropItem from './DragDropItem'
import { DropTarget } from 'react-dnd'
import { getArrObjCopy } from '../getCopies'

const DragDropContainer = props => {

  const moveItem = (dragIndex, hoverIndex) => {
  }

  const updateOrder = (ogIndex, newIndex) => {
    let dragItem = props.data[ogIndex]
    let newData = getArrObjCopy(props.data)
    newData.splice(ogIndex, 1)
    newData.splice(newIndex, 0, dragItem)
    props.setData(newData)
  }

  const { connectDropTarget } = props

  return connectDropTarget(
    <div className="kpod-drag-container">
      {props.data.length && props.data.map((item, i) => {
        return (
          <DragDropItem
            item={item}
            index={i}
            propertyId={props.propertyId || ''}
            dragType={props.dragType}
            updateOrder={updateOrder}
            moveItem={(dragIndex, hoverIndex) => moveItem(dragIndex, hoverIndex)} />
        )
      })}
    </div>
  )
}

const itemTarget = {
  drop(props, monitor, component) {
    const { id } = props
    const sourceObj = monitor.getItem()
    return {
      listId: id
    }
  }
}

export default
  DropTarget("item", itemTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }))(DragDropContainer)
