import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { isServer } from './isServer'
import { getData } from './api'
import { Helmet } from "react-helmet"

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

const AboutTeam = props => {

  const [data, setData] = useState([])

  useEffect(() => {
    handleGetData()
  }, [])

  function handleGetData(params) {
    let url = `agents`
    getData(url, handleSetData)
  }

  const handleSetData = (data, count) => {
    setData(data)
  }

  const openTel = (num) => {
    // TO DO - should not open agent profile 
    if (!isServer) {
      (window.location.href = "tel:" + num)
    }
  }

  return (
    <div className="main-body">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content="Our Team | Kellands Real Estate" />
        <title>Our Team | Kellands Real Estate</title>
        {/* <meta property="og:description" content="" /> */}
        {/* <meta property="og:image" content="" />  */}
      </Helmet>
      <div className="column">
        <h1 className="main-page-header">
          The Team
        </h1>
        <div className="listings-container">
          {data.map(agent => {
            return (
              <Link to={`/ourpeople/staff/${agent.displayName.replace(/ /g, '-')}`}
                // TO DO: remove Id - get agent by name
                className="agent-listing"
                agent={agent}>
                <img className="agent-img agent-thumbnail-img"
                  src={`${cloudFrontURL}/users/${agent.id}/images/${agent.storageKey}`}
                  alt="" />
                <div className="agent-listing-details">
                  <div className="agent-listing-name">
                    {agent.displayName}
                  </div>
                  <div className="agent-listing-numbers">
                    <div className="row">
                      <div className="agent-listing-number-header">
                        M
                    </div>
                      <div className="lighttext"
                        onClick={() => openTel(agent.mobile)}>
                        {agent.mobile}
                      </div>
                    </div>
                    <div className="row">
                      <div className="agent-listing-number-header">
                        P
                    </div>
                      <div className="lighttext"
                        onClick={() => openTel(agent.phone)}>
                        {agent.phone}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

// export default withStyles(styles)(AboutTeam)
export default AboutTeam