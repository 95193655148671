import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import {
  getData,
  postData,
  patchData,
  patchUserStatus,
  getPreSignedUrl,
  uploadImg,
  updateUserImage,
  updatePassword
} from '../api'

import MCETextEditor from './MCETextEditor.jsx'
import { Alert, AlertTitle } from '@material-ui/lab'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'


let processAlerts = [];
let processCount = 0;

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'
// const cloudFrontURL = 'https://kellands-website-assets-dev.s3.ap-southeast-2.amazonaws.com'


const UserForm = props => {
  let history = useHistory()
  const passwordRef = useRef(null);
  const resetPasswordRef = useRef(null);
  const { id } = useParams()
  const [newId, setNewId] = useState(id)
  const [storageKey, setStorageKey] = useState(false)

  const [tab, setTab] = useState(1)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [jobTitle, setJobTitle] = useState('Sales ')
  const [phone, setPhone] = useState('')
  const [mobile, setMobile] = useState('')
  const [agentCodeRealNZ, setAgentCodeRealNZ] = useState('')
  const [officeCodeRealNZ, setOfficeCodeRealNZ] = useState('')
  const [showProfile, setShowProfile] = useState(true)



  const [description, setDescription] = useState('')

  const [displayName, setDisplayName] = useState('')

  const [roles, setUserRoles] = useState([])
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)
  const [hasNewPassword, setHasNewPassword] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordsMatch, setNewPasswordsMatch] = useState(true)

  const [status, setStatus] = useState('active')
  const [originalStatus, setOgStatus] = useState('')

  const [image, setImage] = useState(null)
  const [imageUploaded, setImageUploaded] = useState(false)

  const [submitted, setSubmitted] = useState(false)
  const [submitSuccess, setFormSuccess] = useState(false)

  const [alertsUpdated, setAlertsUpdated] = useState(false)

  const [position, setPosition] = useState(0)

  const [error, setError] = useState(false)
  const [formReady, setFormReady] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitStatus, setSubmitStatus] = useState({
    images: false,
  })

  useEffect(() => {
    if (props.isEdit) {
      let url = `admin/users/${id}`
      getData(url, handleSetData, handleSubmitError)
      props.setSubPage('Edit salesperson')
    } else {
      props.setSubPage('Add new salesperson')
    }
    if (newId && !props.isEdit) {
      handleCreateUserSuccess()
    }
  }, [props, id, newId])

  useEffect(() => {
    if (!formReady && username.length && email.length && roles.length && (password.length || props.isEdit)) {
      setFormReady(true)
    } 
    else if (formReady && (!username.length || !email.length || !roles.length)) {
            setFormReady(false)
    } else if (formReady && !password.length) {
        if (!props.isEdit) {
            setFormReady(false)
        }
      }
  }, [password, username, email, roles.length, formReady])

     useEffect(() => {
    if (submitting && !props.isEdit) {
      const allFalse = Object.values(submitStatus).every(value => value === false);
      if (allFalse) {
          history.push(`/account/users/edit/${newId}`);
      }
    }
  }, [submitting, submitStatus, history]); 


  const handleSetData = (res) => {
    setAgentCodeRealNZ(res.agentCodeRealNZ)
    setOfficeCodeRealNZ(res.officeCodeRealNZ)
    setDisplayName(res.displayName)
    setDescription(res.description)
    setEmail(res.email.replace(/ /g, ''))
    setFirstName(res.firstName)
    setLastName(res.lastName)
    setJobTitle(res.jobTitle)
    setMobile(res.mobile)
    setPhone(res.phone)
    setShowProfile(res.showProfile)
    setUsername(res.username)
    setStatus(res.status)
    setOgStatus(res.status)
    setStorageKey(res.storageKey)
    setUserRoles(res.roles.split("|"))
    setPosition(res.position)
    setImage({ file: { name: res.imageName }, url: `${cloudFrontURL}/users/${id}/images/${res.storageKey}` })
    // setImage(res.imageName)
    // what if already array?

    // agentStatus: "notapplicable"
    // createdAt: "2009-10-01T10:00:00Z"
    // id: 1
    // imageName: null
    // lastLogin: "2020-11-29T23:04:03Z"
    // roles: "administrator"
    // status: "active"
    // storageKey: null
    // updatedAt: "2020-11-17T02:59:06.348557Z"
  }

  useEffect(() => {

  }, [props.dataFilter])

  // const handleInput = (e, state, setStateCallback) => {
  //   setStateCallback(e.target.value)
  // }

  const handleUploadFile = () => {
    const inputFile = document.getElementById('userimgupload').files[0]
    let url = URL.createObjectURL(inputFile)
    setImage({ file: inputFile, url: url })
    setImageUploaded(true)
  }

  const handleUserRoles = (role) => {
    // Shallow copy 
    let newRoles = [...roles]
    let idx = newRoles.indexOf(role)
    if (idx > -1) {
      newRoles.splice(idx, 1);
    } else {
      newRoles.push(role)
    }
    setUserRoles(newRoles)
  }

  const setNewAlert = (id, status, message, statusCode) => {
    let newAlert = {
      id: id,
      status: status,
      statusCode: statusCode,
      message: message
    }
    const pushAlert = async () => {
      return processAlerts.push(newAlert)
    }

    const spliceAlert = async (index) => {
      return processAlerts.splice(index, newAlert)
    }

    let matchIdx;
    processAlerts.forEach((process, idx) => {
      if (process.id === id) {
        // && !id.includes('upload')
        matchIdx = idx;
      }
    })

    if (matchIdx >= 0) {
      spliceAlert(matchIdx).then(r => {
        processCount++;
        alertsUpdated !== processCount && setAlertsUpdated(processCount)
      })
    } else {
      pushAlert().then(r => {
        processCount++;
        alertsUpdated !== processCount && setAlertsUpdated(processCount)
      })
    }
  }

  const deleteAlert = (id) => {
    const spliceAlert = async (index) => {
      return processAlerts.splice(index, 1)
    }

    let matchIdx;
    processAlerts.forEach((process, idx) => {
      if (process.id === id) {
        matchIdx = idx;
      }
    })
    if (matchIdx >= 0) {
      spliceAlert(matchIdx).then(r => {
        processCount++;
        setAlertsUpdated(processCount)
      })
    }
  }

  const handleSubmit = (tab) => {
       
    if (handleValidateFields()) {
      let formValues = {
        2: {
          "firstName": firstName,
          "lastName": lastName,
          "jobTitle": jobTitle,
          "phone": phone,
          "mobile": mobile,
          // "agentCodeRealNZ": agentCodeRealNZ,
          // "officeCodeRealNZ": officeCodeRealNZ,
          "showProfile": showProfile,
          "displayName": firstName + ' ' + lastName,
          "agentStatus": roles.includes('agent') ? status : "notapplicable",
          "status": status,
          "email": email,
          "position": position,
          "imageName": image ? image.file.name : '',
          "roles": roles
        },
        3: { "description": description },
        5: { "username": username, "password": password }
      }

      if (props.isEdit) {
        formValues[2].storageKey = storageKey
      }
      let data;
      // if (tab === 1) {
      data = { ...formValues[2], ...formValues[3], ...formValues[5] }
      // } else {
      // data = formValues[tab]
      // }
      setSubmitted(true)
      props.isEdit
        ? patchData(`admin/users/${id}`, data,
          handleUpdateUserSuccess,
          (r) => handleUpdateUserError(r))
        : postData('admin/users', data,
          (r) => setNewId(r.id),
          (r) => handleCreateUserError(r))
    }
  }

  const handleValidateFields = () => {
    console.log("handleValidateFields", !props.isEdit, !username.length)
    if (!props.isEdit ) {
      // let uuid = uuidv4()
      // let psw = Math.random().toString(36).substring(7)
      // setUsername(uuid)
      // setPassword(psw)
      if (validatePassword(password)) {
        return true
      }
      else {
        setPasswordError(true)
        passwordRef.current.focus();
        passwordRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return false
      }
      
    } else if (props.isEdit && hasNewPassword) {
      if (newPasswordsMatch) {
        if (password.length && validatePassword(password)) {
          return true
        } else {
          resetPasswordRef.current.focus();
          resetPasswordRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
           setPasswordError(true)
          return false
        }
      } else {
        setNewPasswordsMatch(false)
        return false
      }
    } else {
      return true
    }
  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    })
  }

  const handleUpdateUserSuccess = () => {
    handleEditSubmitProcess()
    let message = `Updated user information for ${username}`
    return setNewAlert('user_update', 'success', message, '')
  }

  const handleUpdateUserError = (r) => {
    handleEditSubmitProcess()
    let message = `Error updating user ${username}`
    return setNewAlert('user_update', 'error', message, r.status)
  }

  const handleCreateUserSuccess = () => {
    handleSubmitProcess()
    let message = `Created new user ${username}`
    return setNewAlert('user_create', 'success', message, '')
  }

  const handleCreateUserError = () => {
    let message = `Error creating new user ${username}`
    return setNewAlert('user_create', 'error', message, '')
  }

  const handleEditSubmitProcess = () => {
    handlePatchUserStatus()
    handleImageUpload()
    handleUpdatePassword()
  }

  const handleSubmitProcess = () => {
    handleImageUpload()
  }

  // First callback on user post / patch
  const handlePatchUserStatus = () => {
    let type;
    status === 'active' ? type = 'activate' : type = 'deactivate'
    if (originalStatus !== status) {
      handlePatchStatusSuccess('', originalStatus)
      patchUserStatus(id, type,
        (r) => handlePatchStatusSuccess(r, originalStatus),
        (r) => handlePatchStatusError(r, status))
    }
  }

  const handlePatchStatusSuccess = (r, ogStatus) => {
    setOgStatus(status)
    handleImageUpload()
    let message = `Updated user status from ${ogStatus} to ${status}`
    return setNewAlert('status_update', 'success', message, '')
  }

  const handlePatchStatusError = (r, ogStatus) => {
    let message = `Error updating user status from ${ogStatus} to ${status}`
    return setNewAlert('status_update_error', 'error', message, r.status)
  }

  // Second callback on user post / patch
  const handleImageUpload = (res) => {
    setSubmitStatus({
        images: imageUploaded
      })
     setSubmitting(true)

    setError(false)
    setSubmitted(false)
    if (imageUploaded) {
      let preSignedBody = {
        "id": Number(newId),
        "idType": "users",
        "objectName": image.file.name,
        "objectType": "images",
        "objectContentType": `image/${image.file.name.split('.').pop()}`
      }
      // Add pre signed body data
      getPreSignedUrl(preSignedBody, (r) => addImgToBucket(r), handleSubmitError)
    } else {
      // handleFormSuccess(res)
    }
  }

  const addImgToBucket = (res) => {
    let url = res.preSignedURL
    let fileType = image.file.name.split('.').pop()
    const reader = new FileReader()
    reader.readAsDataURL(image.file)
    uploadImg(image.file, fileType, url,
      (r) => handleImageUploadSuccess(r, image),
      (r) => handleImageUploadError(r, image))
  }


  const handleImageUploadSuccess = (r, image) => {
    handleAssignImage(r, image)
    let message = `Uploaded new image ${image.file.name}`
    return setNewAlert('image_upload', 'success', message, '')
  }

  const handleImageUploadError = (r, image) => {
    let message = `Error uploading new image ${image.file.name}`
    return setNewAlert('image_upload', 'error', message, r.status)
  }

  const handleAssignImage = (res, image) => {
    let imageInfo = {
      "imageName": image.file.name,
      "storageKey": image.file.name,
    }
    let url = `${id}/image`
    updateUserImage(url, imageInfo,
      (r) => handleImageAssignSuccess(r, image),
      (r) => handleImageAssignError(r, image))
  }

  const handleImageAssignSuccess = (r, image) => {
    let message = `Assigned new image ${image.file.name} to agent`
    setSubmitStatus(prevState => ({
    ...prevState,
      images: false, 
    }));
    return setNewAlert('image_assign', 'success', message, '')
  }

  const handleImageAssignError = (r, image) => {
    let message = `Error assigning new image ${image.file.name} to agent`
    return setNewAlert('image_assign', 'error', message, r.status)
  }

  // const handleFormSuccess = (res) => {
  //   setFormSuccess(true)
  //   submitted && setSubmitted(false)
  //   setError(false)
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // }

  const removeUserImage = () => {
    setImage({ file: '', url: '' })
    setImageUploaded(false)
    // TO DO: REMOVE IMAGE API
  }

  const handleSubmitError = (err) => {
    // let message = ``
    // return setNewAlert('general_error', 'success', message, '')
    // setError(err.error_description || err.response.body.error_description[0])
  }

  const handleUpdatePassword = () => {
    if (hasNewPassword && newPasswordsMatch) {
      let creds = {
        "oldPassword": password,
        "newPassword": newPassword
      }
      updatePassword(id, creds, handleUpdatePasswordSuccess, handleUpdatePasswordError)
    }
  }

  const handleUpdatePasswordSuccess = (r) => {
    let message = `Successfully updated the password for ${username} `
    return setNewAlert('image_assign', 'success', message, '')
  }

  const handleUpdatePasswordError = (r) => {
    let message = `Error updating the password for ${username}`
    return setNewAlert('image_assign', 'error', message, r.status)
  }


  const handleSetNewPassword = (str) => {
    // Sets in state
    setHasNewPassword(true)
    setNewPassword(str)
  }

  const handleSetNewPasswordConfirm = (str) => {
    if (str !== newPassword) {
      setNewPasswordsMatch(false)
    } else {
      setNewPasswordsMatch(true)
    }
  }

  const handleProfileShowtoggle = (val) => {
    setShowProfile(!val)
  }

  const validatePassword = (str) => {
  // * characters with a mix of numbers, letters and special characters
  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return regex.test(str);
}

  

  return (
    <div className="kpod-form-container">
      <div className="alerts-container">
        {processAlerts.map((alert, index) => {
          return (
            // severity="warning"
            // severity="info"
            // severity="success"
            <div className="alert-item" key={alert.id}>
              <Alert severity={alert.status} key={alert.id + alert.status}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small">
                    <CloseIcon fontSize="inherit" onClick={() => deleteAlert(alert.id)} />
                  </IconButton>
                }>
                <AlertTitle className="alert-title">
                  {alert.status + ' '}
                  {alert.statusCode ? alert.statusCode : ''}
                </AlertTitle>
                {alert.message}
              </Alert>
            </div>
          )
        })}
      </div>
      {/* <div className="kpod-form-tabs"> */}
        {/* <div className="kpod-form-tab" onClick={() => setTab(1)}>
          Quick Entry
        </div> */}
        {/* <div className="kpod-form-tab" onClick={() => setTab(2)}>
          General Details
        </div>
        <div className="kpod-form-tab" onClick={() => setTab(3)}>
          Description
        </div>
        <div className="kpod-form-tab" onClick={() => setTab(4)}>
          Image
        </div>
        <div className="kpod-form-tab" onClick={() => setTab(5)}>
          User Detail
        </div> */}
        {/* <div className="kpod-form-tab" onClick={() => setTab(6)}>
          Log
        </div> */}
      {/* </div> */}
      <div className="kpod-form-outer">
        <div className="kpod-form-sm-menu column">
          Salesperson Status
            <select name="Salesperson Status"
            onChange={(e) => setStatus(e.target.value.toLowerCase())}>
            <option selected={status === 'active'}
              value="Active">
              Active
            </option>
            <option selected={status === 'inactive'}
              value="Inactive">
              Inactive
            </option>
          </select>
        </div>
        <div className="kpod-form-inner">
          <div className="kpod-form-subheader">
            {submitSuccess ?
              <div className="success-container">
                Successfully {props.isEdit ? 'Updated ' : 'Created '}
                User {displayName ? displayName : firstName + ' ' + lastName}
              </div> :
              props.isEdit ? 'Edit ' + displayName : 'Create New User'}
          </div>
          {(tab === 1 || tab === 2) &&
            <div className="kpod-form-item">
              <div className="kpod-form-header">
                General Details
            </div>
              <div className="kpod-form-inner">
                Username *
                <input type="text"
                  className="kpod-form-input"
                  required="true"
                  placeholder="New username"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}>
                </input>
                <div className="kpod-form-infotext kpod-info-user">
                  The username used for logging in.
                </div>
                {!props.isEdit && 
                <div className="kpod-form-inner">
                  Password *
                  <input type="password"
                    className="kpod-form-input"
                    required="true"
                    defaultValue=""
                    placeholder="Password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}>
                  </input>
                  <div  ref={passwordRef} className={`kpod-form-infotext kpod-info-user ${passwordError && "kpod-password-error"}`}>
                    Password must be at least 8 characters long and contain a mixture of letters, numbers and special characters
                  </div>
                </div>
                }
          
                First Name
                <input type="text"
                  className="kpod-form-input"
                  required="true"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}>
                </input>
                Last Name
                <input type="text"
                  className="kpod-form-input"
                  required="true"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}>
                </input>
                Job Title
                <select name="Job Title"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}>
                  <option value="Sales">Sales</option>
                  <option value="Sales Support">Sales Support</option>
                  <option value="Sales Manager">Sales Manager</option>
                  <option value="AREINZ">AREINZ</option>
                  <option value="Principal">Principal</option>
                  <option value="Managing Director">Managing Director</option>
                  <option value="Sales Assistant">Sales Assistant</option>
                  <option value="Director">Director</option>
                  <option value={`Finance & Admin Manager`}>{`Finance & Admin Manager`}</option>
                </select>
                <div className="kpod-form-spacer"></div>
                Phone DDI
                <input type="text"
                  className="kpod-form-input"
                  required="true"
                  placeholder="Phone DDI"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}>
                </input>
                Phone Mobile
                <input
                  type="text"
                  className="kpod-form-input"
                  required="true"
                  placeholder="Phone Mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}>
                </input>
                Email *
                <input
                  type="text"
                  className="kpod-form-input"
                  required="true"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}>
                </input>
                Agent Code Real Estate
                <input
                  type="text"
                  className="kpod-form-input"
                  required="true"
                  placeholder="Agent Code RealEstate"
                  value={agentCodeRealNZ}
                  onChange={(e) => setAgentCodeRealNZ(e.target.value)}>
                </input>
                Office Code Real Estate
                <input
                  type="text"
                  className="kpod-form-input"
                  required="true"
                  placeholder="Office Code RealEstate"
                  value={officeCodeRealNZ}
                  onChange={(e) => setOfficeCodeRealNZ(e.target.value)}>
                </input>
                <div className="kpod-form-spacer"></div>
                <div className="row">
                  <label for="publichide">Hide from public?</label>
                  <input
                    type="checkbox"
                    id="publichide"
                    placeholder="Hide From Public?"
                    checked={!showProfile}
                    // onChange={(e) => setShowProfile(e.target.value === 'on' ? true : false)}>
                    onChange={(e) => handleProfileShowtoggle(e.target.checked)}>
                  </input>
                </div>
                <div className="kpod-form-spacer"></div>

              </div>
              {/* <button className="kpod-form-button" onClick={() => handleSubmit(2)}>Save</button> */}
            </div>}
          {(tab === 1 || tab === 3) &&
            <div className="kpod-form-item">
              <div className="kpod-form-header">
                Description
            </div>
              <MCETextEditor
                description={description}
                setDescription={(desc) => setDescription(desc)}
              />
              <div className="kpod-form-inner">
              </div>
              {/* <button className="kpod-form-button" onClick={() => handleSubmit(3)}>Save</button> */}
            </div>}
          {(tab === 1 || tab === 4) &&
            <div className="kpod-form-item">
              <div className="kpod-form-header">
                Image
                </div>
              <div className="row med-row">
                <div className="column">
                  Salesperson Image
                  <div className="kpod-form-inner">
                    <div className="kpod-form-infotext kpod-info-user">
                      Add a salesperson image by using the field above.
                      An existing image will be overwritten when adding a new image.
                      Once an image is uploaded, click on the preview image to view the full size image.
                      Use the red cross icon to delete.
                    </div>
                    <input type="file" placeholder=""
                      id="userimgupload"
                      key="imgupload"
                      name="imgupload"
                      accept="image/*"
                      onChange={() => handleUploadFile()}>
                    </input>
                  </div>
                </div>
                {image ?
                  <img src={image.url} className="kpod-user-img" alt="" />
                  : <img className="kpod-user-img" src={
                    `${cloudFrontURL}/users/${id}/images/${storageKey}`}
                    onError={(e) => { e.target.src = '/images/image-default-agent.png' }}
                    alt="" />
                }
                <i className="fa fa-times-circle functions-btn"
                  aria-hidden="true"
                  style={{
                    padding: '5px',
                    marginTop: '-5px',
                    color: '#707070',
                    cursor: 'pointer',
                    fontSize: '22px'
                  }}
                  onClick={() => removeUserImage()}
                ></i>
              </div>
              {/* <button className="kpod-form-button" onClick={() => handleSubmit(4)}>Save</button> */}
            </div>}
          {(tab === 1 || tab === 5) &&
            <div className="kpod-form-item form-lastitem">
            <div className="kpod-form-header">
                User Detail
              </div>
                User Role *
              <div className="column">
                <div className="row kpod-form-checkbox">
                  <label for="isAdministrator">Administrator</label>
                  <input
                    type="checkbox"
                    id="isAdministratorr"
                    checked={roles.find(ag => ag === 'administrator')}
                    placeholder="Hide From Public?"
                    onChange={() => handleUserRoles('administrator')}>
                  </input>
                </div>
                <div className="row kpod-form-checkbox">
                  <label for="isAgent">Agent</label>
                  <input
                    type="checkbox"
                    id="isAgent"
                    checked={roles.find(ag => ag === 'agent')}
                    placeholder="Hide From Public?"
                    onChange={() => handleUserRoles('agent')}>
                  </input>
                </div>
                <div className="row kpod-form-checkbox">
                  <label for="isMember">Member</label>
                  <input
                    type="checkbox"
                    id="isMember"
                    checked={roles.find(ag => ag === 'member')}
                    placeholder="Hide From Public?"
                    onChange={() => handleUserRoles('member')}>
                  </input>
                </div>
              </div>
              {(localStorage.getItem('user') === username && props.isEdit )?
                <div className="kpod-form-inner">
                  <div className="kpod-form-header">
                    Reset Password
                  </div>
                  Current Password:
                  <input type="password"
                    className="kpod-form-input"
                    required="true"
                    defaultValue=""
                    placeholder="Password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}>
                  </input>
                  {newPassword.length && !password.length ? <div
                    style={{
                      color: '#df1212',
                      marginBottom: '12px',
                      fontSize: '14px'
                    }}>
                    Please fill in current password
                    </div>
                    : <div />}
                    New Password:
                  <input type="password"
                    className="kpod-form-input"
                    autocomplete="off" required="true"
                    defaultValue=""
                    placeholder="Password"
                    onChange={(e) => handleSetNewPassword(e.target.value)}>
                  </input>
                   <div ref={resetPasswordRef} className={`kpod-form-infotext kpod-info-user ${passwordError && "kpod-password-error"}`}>
                    Password must be at least 8 characters long and contain a mixture of letters, numbers and special characters
                  </div>
                  Repeat New Password:
                  <input type="password"
                    className="kpod-form-input"
                    autocomplete="off"
                    required="true"
                    placeholder="Repeat Password"
                    onChange={(e) => handleSetNewPasswordConfirm(e.target.value)}>
                  </input>
                  {!newPasswordsMatch && <div
                    style={{
                      color: '#df1212',
                      marginBottom: '12px',
                      fontSize: '14px'
                    }}>
                    Passwords do not match
                    </div>}
                  <div className="kpod-form-infotext kpod-info-user">
                    To set a new password, please enter the new password in the first field.
                    Then retype the password for verification in the second field called password confirmation.
              </div>
                </div>
                : <div />}
              <div className="kpod-button-container row">
                <button disabled={!formReady}  className={`kpod-form-button ${!formReady ? 'disabled' : ''}`} onClick={() => handleSubmit(tab)}>
                  {submitted && !error ? 'Saving...' : 'Save Changes'}
                </button>
                {/* <button className="kpod-form-button" onClick={() => handleDeactivateUser(tab)}>
                  Deactivate User
                </button> */}
              </div>
            </div>}

        </div>
      </div>
    </div >
  )
}

export default UserForm