import React from 'react';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const PropertyNotFound = props => {

  return (
    <div className="property-main column">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content='Property Not Found | Kellands Real Estate'/>
        <title>Property Not Found | Kellands Real Estate</title>
      </Helmet>
      <img className="notfound-img" src='/images/404_propertynotfound.png' alt="" />
      <div className="bold notfound-text center">
        Oops... Property not found!
      </div>
      <p className="center"> We can't find the property you're looking for.</p>
      <Link className="notfound-link center" to="/">
        Return to our homepage
      </Link>
    </div>
  )
}

export default PropertyNotFound;