import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { isServer } from './isServer'
import { getPageContent } from './api'

const Footer = props => {
  const location = useLocation()
  const [footerContent, setFooterContent] = useState('')
  // const [error, setError] = useState('')

  useEffect(() => {
    getFooterContent()
  }, [])

  const getFooterContent = () => {
    // TO DO -> update 'footer'
    getPageContent('footer', (data) => setFooterContent(data[0].value))
  }

  const openExternalWindow = () => {
    if (!isServer) {
      window.open('/disclaimer', '', 'width=600,height=400,left=200,top=200');
    }
  }
  if (location.pathname.includes('printview')) {
    return null;
  } else return (
    <div className="footer">
      <div className="about-container">
        <div className="footer-header">About Kellands</div>
        <p className="about-text"
          dangerouslySetInnerHTML={{ __html: footerContent }}>
        </p>
      </div>
      <div className="company-container">
        <div className="footer-header">Company</div>
        <Link className="link" to="/contact" title="Contact Kellands Real Estate">Contact Us</Link>
        <Link className="link" to="/privacy" title="Kellands Real Estate Privacy Policy">Privacy</Link>
        {/* <Link className="link" to="chart" target={"_blank"} to="/disclaimer">Disclaimer</Link> */}
        <div className="link" onClick={() => openExternalWindow()}>Disclaimer</div>
        <Link className="link" to='/rea' title="REA">REA 2008</Link>
        <Link className="link" to='/account/login' title="Kellands Real Estate Agent Login">K-Pod Login</Link>
      </div>
      <div className="social-container">
        <a className="fa fa-facebook" rel="noreferrer" href="https://www.facebook.com/kellandsrealestate/" target="_blank"> </a>
        <a className="fa fa-twitter" rel="noreferrer" href="https://twitter.com/kellands" target="_blank"> </a>
        <a className="fa fa-linkedin" rel="noreferrer" href="https://www.linkedin.com/company/sales-executive?trk=ppro_cprof" target="_blank"> </a>
      </div>
    </div>
  )
}

export default Footer