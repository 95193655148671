import React, { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'

import {
  DatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

const Reports = props => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [category, selectCategory] = useState('All')
  const [type, selectType] = useState('All')
  const [status, selectStatus] = useState('All')


  const requestGetReport = () => {

  }

  return (
    <div className="kpod-container">
      <h1 className="kpod-main-title bold">
        Select a report to show
      </h1>
      <div className="column">
        <div className="kpod-reports column">
          <div className="kpod-form-input-header">
            Start Date
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker value={startDate} onChange={(e) => setStartDate(e)} format="dd/MM/yyyy" />
          </MuiPickersUtilsProvider>
        </div>

        <div className="kpod-reports column">
          <div className="kpod-form-input-header">
            End Date
          </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker value={endDate} onChange={(e) => setEndDate(e)} format="dd/MM/yyyy" />
          </MuiPickersUtilsProvider>
        </div>

        <div className="kpod-reports column">
          <div className="kpod-form-input-header">
            Category
          </div>
          <select className="select" name="reportscategory">
            <option value="All"
              onClick={() => selectCategory('All')}
              selected={category === 'All'}
            >
              All
            </option>
            <option value="Homes"
              onClick={() => selectCategory('Homes')}
              selected={category === 'Homes'}
            >
              Homes
            </option>
            <option value="Apartments"
              onClick={() => selectCategory('Apartments')}
              selected={category === 'Apartments'}
            >
              Apartments
            </option>
            <option value="Projects"
              onClick={() => selectCategory('Projects')}
              selected={category === 'Projects'}
            >
              Projects
            </option>
            <option value="Commercial"
              onClick={() => selectCategory('Commercial')}
              selected={category === 'Commercial'}
            >
              Commercial
            </option>
            <option value="Coastal"
              onClick={() => selectCategory('Coastal')}
              selected={category === 'Coastal'}
            >
              Coastal
            </option>
            <option value="Waiheke Island"
              onClick={() => selectCategory('Waiheke Island')}
              selected={category === 'Waiheke Island'}
            >
              Waiheke Island
            </option>
            <option value="Lifestyle"
              onClick={() => selectCategory('Lifestyle')}
              selected={category === 'Lifestyle'}
            >
              Lifestyle
            </option>
            <option value={`Queenstown & Surrounds`}
              onClick={() => selectCategory(`${'Queenstown & Surrounds'}`)}
              selected={category === `${'Queenstown & Surrounds'}`}
            >
              {'Queenstown & Surrounds'}
            </option>
          </select>
        </div>

        <div className="kpod-reports column">
          <div className="kpod-form-input-header">
            Type
          </div>
          <select className="select" name="reportstype">
            <option value="All"
              onClick={() => selectType('All')}
              selected={type === 'All'}
            >
              All
            </option>
            <option value="By Negotiation"
              onClick={() => selectType('By Negotiation')}
              selected={type === 'By Negotiation'}
            >
              By Negotiation
            </option>
            <option value="Price"
              onClick={() => selectType('Price')}
              selected={type === 'Price'}
            >
              Price
            </option>
            <option value="Auction"
              onClick={() => selectType('Auction')}
              selected={type === 'Auction'}
            >
              Auction
            </option>
            <option value="Tender"
              onClick={() => selectType('Tender')}
              selected={type === 'Tender'}
            >
              Tender
            </option>
            <option value="Set Sale"
              onClick={() => selectType('Set Sale')}
              selected={type === 'Set Sale'}
            >
              Set Sale
            </option>
            <option value="International Tender"
              onClick={() => selectType('International Tender')}
              selected={type === 'International Tender'}
            >
              International Tender
            </option>
            <option value="Internation Set Sale"
              onClick={() => selectType('Internation Set Sale')}
              selected={type === 'Internation Set Sale'}
            >
              Internation Set Sale
            </option>
          </select>
        </div>

        <div className="kpod-reports column">
          <div className="kpod-form-input-header">
            Status
          </div>
          <select className="select" name="reportsstatus">
            <option value="All"
              onClick={() => selectStatus('All')}
              selected={status === 'All'}
            >
              All
            </option>
            <option value="Live"
              onClick={() => selectStatus('Live')}
              selected={status === 'Live'}
            >
              Live
            </option>
            <option value="Sold"
              onClick={() => selectStatus('Sold')}
              selected={status === 'Sold'}
            >
              Sold
            </option>
            <option value="Withdrawn"
              onClick={() => selectStatus('Withdrawn')}
              selected={status === 'Withdrawn'}
            >
              Withdrawn
            </option>
            <option value="Archived"
              onClick={() => selectStatus('Archived')}
              selected={status === 'Archived'}
            >
              Archived
            </option>
          </select>
        </div>

        <div className="right">
          <button
            className="kpod-form-button right"
            onClick={() => requestGetReport()}>
            Get Report
              </button>
        </div>
      </div>
    </div >
  )

}

export default Reports