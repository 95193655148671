import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { getPageContent } from './api'
import complaintsPdf from '../static/files/Complaints-Procedure.pdf'
import REAConductPdf from '../static/files/REA_Code-of-Conduct-2012-WEB.pdf'

const REA = props => {
  const [content, setContent] = useState('')

  useEffect(() => {
    getContent()
  }, [])

  const getContent = () => {
    getPageContent('reaa', (data) => setContent(data[0].value))
  }

  return (
    <div className="main-body column">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content='REA | Kellands Real Estate'/>
        <title>REA | Kellands Real Estate</title>
      </Helmet>
      <h1 className="main-page-header">
        REA 2008
      </h1>
      <div className="main-text row">
        <div className="rea-container">
          <p className="about-text"
            dangerouslySetInnerHTML={{ __html: content }}>
          </p>
          <h3>Downloadable files</h3>
          <div className="reaa-download-file">
            <a className="lighttext" href={REAConductPdf} download>
              <img className="pdf-icon" src='/images/pdficon.gif' alt="conduct.pdf" />
              REA_Code-of-Conduct-2012-WEB.pdf
            </a>
          </div>
          <div className="rea-download-file">
            <a className="lighttext" href={complaintsPdf} download>
              <img className="pdf-icon" src='/images/pdficon.gif' alt="complaints.pdf" />
              Complaints-Procedure.pdf
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default REA