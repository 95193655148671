import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { openEmail } from './utls'
import { isServer } from './isServer'
import WhatsOnListing from './WhatsOnListing'

const WhatsOnMobile = props => {
  const [mobileNavOpen, seMobileNavOpen] = useState(false)

  const [initialLoad, setInitialLoad] = useState(false)
  const [touchControls, setTouchControls] = useState({
    dir: 'none',
    swipeType: 'none',
    startX: 0,
    startY: 0,
    startTime: 0,
    distX: 0,
    distY: 0,
    threshold: 150, //required min distance traveled to be considered swipe
    restraint: 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime: 500, // maximum time allowed to travel that distance
    elapsedTime: 0,
    touchReleased: false
  })

  useEffect(() => {
    setInitialLoad(true);
  }, [])

  useEffect(() => {
    if (touchControls.touchReleased) {
      if (touchControls.dir !== 'up' && touchControls.dir !== 'down') {
        if (touchControls.dir === 'left') {
          props.page !== props.pagesCount && props.setPage(props.page + 1)
        } else if (touchControls.dir === 'right') {
          props.page !== 0 && props.setPage(props.page - 1)
        }
      }
    }
  }, [touchControls])


  const handleTouchStart = (e) => {
    // e.preventDefault()
    var touchobj = e.changedTouches[0]
    setTouchControls({
      dir: 'none',
      swipeType: 'none',
      startX: touchobj.pageX,
      startY: touchobj.pageY,
      startTime: new Date().getTime(),
      distX: 0,
      distY: 0,
      threshold: 0, //required min distance traveled to be considered swipe
      restraint: 0, // maximum distance allowed at the same time in perpendicular direction
      allowedTime: 0, // maximum time allowed to travel that distance
      elapsedTime: 0,
      touchReleased: false
    })
  }

  const handleTouchMove = (e) => {
    // e.preventDefault()
    var touchobj = e.changedTouches[0]

    let distX = touchobj.pageX - touchControls.startX // get horizontal dist traveled by finger while in contact with surface
    let distY = touchobj.pageY - touchControls.startY // get vertical dist traveled by finger while in contact with surface
    let dir = 'none'
    if (Math.abs(distX) > Math.abs(distY)) { // if distance traveled horizontally is greater than vertically, consider this a horizontal movement
      dir = (touchControls.distX < 0) ? 'left' : 'right'
    }
    else { // else consider this a vertical movement
      dir = (touchControls.distY < 0) ? 'up' : 'down'
    }
    setTouchControls({
      dir: dir,
      swipeType: 'none',
      startX: touchobj.pageX,
      startY: touchobj.pageY,
      startTime: new Date().getTime(),
      distX: distX,
      distY: distY,
      threshold: touchControls.threshold, //required min distance traveled to be considered swipe
      restraint: touchControls.restraint, // maximum distance allowed at the same time in perpendicular direction
      allowedTime: touchControls.allowedTime, // maximum time allowed to travel that distance
      elapsedTime: touchControls.elapsedTime,
      touchReleased: false
    })
  }

  const handleTouchEnd = (e) => {
    // e.preventDefault()
    var touchobj = e.changedTouches[0]
    let elapsedTime = new Date().getTime() - touchControls.startTime // get time elapsed
    let swipeType;
    if (elapsedTime <= touchControls.allowedTime) { // first condition for awipe met
      if (Math.abs(touchControls.distX) >= touchControls.threshold
        && Math.abs(touchControls.distY) <= touchControls.restraint) { // 2nd condition for horizontal swipe met
        swipeType = touchControls.dir // set swipeType to either "left" or "right"
      }
      else if (Math.abs(touchControls.distY) >= touchControls.threshold
        && Math.abs(touchControls.distX) <= touchControls.restraint) { // 2nd condition for vertical swipe met
        swipeType = touchControls.dir // set swipeType to either "top" or "down"
      }
    }
    setTouchControls({
      dir: touchControls.dir,
      swipeType: swipeType,
      startX: touchobj.pageX,
      startY: touchobj.pageY,
      startTime: touchControls.startTime,
      distX: touchControls.distX,
      distY: touchControls.distY,
      threshold: touchControls.threshold, //required min distance traveled to be considered swipe
      restraint: touchControls.restraint, // maximum distance allowed at the same time in perpendicular direction
      allowedTime: touchControls.allowedTime, // maximum time allowed to travel that distance
      elapsedTime: elapsedTime,
      touchReleased: true
    })
  }

  // Fix initial load bug
  // Class names mixed up on initial server side render of CSS files
  // setInitialLoad state update on mount rerenders component
  if (initialLoad) {
    return (
      <div className="whatson-container">
        <div className="center-text column">
          <div className="main-page-header">
            Whats on?
        </div>
          <div className="whatson-inner column">
            <div id="mobile-nav" className="mobile-sub-nav-container column center-text"
              onMouseEnter={() => seMobileNavOpen(true)}
              onMouseLeave={() => seMobileNavOpen(false)}>
              <div onClick={() => seMobileNavOpen(!mobileNavOpen)}>
                {!mobileNavOpen ?
                  <div className="row mobile-sub-nav-header">
                    <div className="fa fa-bars" style={{
                      color: '#ec008c',
                      fontSize: '22px',
                      marginRight: '14px'
                    }}></div>
                    <div className="mobile-sub-nav-header-text">
                      {props.nav === 'openhomes'
                        ? 'Open Homes'
                        : props.nav === 'auctions'
                          ? 'Auctions'
                          : props.nav === 'new'
                            ? 'New Listings'
                            : ''}
                    </div>
                  </div>
                  : <div />}
              </div>
              {mobileNavOpen ?
                <div className="mobile-sub-nav-body column" name="filter">
                  <div className="row mobile-sub-nav-header">
                    {props.nav === 'openhomes' ?
                      <div className="fa fa-check" style={{
                        color: '#ec008c',
                        fontSize: '18px',
                        marginRight: '14px',
                        width: '18px'
                      }}></div>
                      : <div className="mobile-icon-placeholder" />}
                    <div value="openhomes"
                      className={`mobile-sub-nav-option
                      ${props.nav === 'openhomes' ? 'bold' : ''}`}
                      onClick={props.handleGetOpenHomes}
                      selected={props.nav === 'openhomes'}>
                      Open Homes
                  </div>
                  </div>
                  <div className="row mobile-sub-nav-header">
                    {props.nav === 'auctions' ?
                      <div className="fa fa-check" style={{
                        color: '#ec008c',
                        fontSize: '18px',
                        marginRight: '14px',
                        width: '18px'
                      }}></div>
                      : <div className="mobile-icon-placeholder" />}
                    <div value="auctions"
                      className={`mobile-sub-nav-option
                      ${props.nav === 'auctions' ? 'bold' : ''}`}
                      onClick={props.handleGetAuctions}
                      selected={props.nav === 'auctions'}>
                      Auctions
                </div>
                  </div>
                  <div className="row  mobile-sub-nav-header">
                    {props.nav === 'new' ?
                      <div className="fa fa-check" style={{
                        color: '#ec008c',
                        fontSize: '18px',
                        marginRight: '14px',
                        width: '18px'
                      }}></div>
                      : <div className="mobile-icon-placeholder" />}
                    <div value="new"
                      className={`mobile-sub-nav-option
                      ${props.nav === 'new' ? 'bold' : ''}`}
                      onClick={props.handleGetNewListings}
                      selected={(props.nav === 'new')}>
                      New Listings
                   </div>
                  </div>
                </div>
                : <div />
              }
            </div>
            <div className="whatson-listings-container">
              {props.nav === 'openhomes' ?
                props.previewOpenHomes.length ?
                  <div className="transition-slide-outer row">
                    <div className={`fa fa-chevron-circle-left whatson-prevnext-icon
                    ${props.page === 0
                        ? 'prevnext-icon-disabled'
                        : ''}`}
                      onClick={() => props.setPage(props.page - 1)}
                      style={{ fontSize: '32px' }}>
                    </div>
                    <div className="transition-slide-inner row"
                      style={{
                        width: (194 * (props.listingCount)) + 'px',
                        minWidth: (194 * (props.listingCount)) + 'px'
                      }}>
                      <div id="openhome-transition" className="row"
                        style={{ left: (props.page >= 0 ? props.page * -194 + 'px' : '0px') }}>
                        {props.previewOpenHomes.map((openhome, index) => {
                          return (
                            <div key={index} className="row"
                              onTouchMove={(e) => handleTouchMove(e)}
                              onTouchStart={(e) => handleTouchStart(e)}
                              onTouchEnd={(e) => handleTouchEnd(e)}>
                              {openhome.isDateItem ?
                                <div className='whatson-item'>
                                  <div className="whatson-listing-content whatson-date-item">
                                    <div className="whatson-date-text">
                                      <div className="whatson-date-sm">
                                        {openhome.date.split(' ')[0]}
                                      </div>
                                      <div className="whatson-date-lg">
                                        {openhome.date.split(' ')[1]}
                                      </div>
                                      <div className="whatson-date-sm">
                                        {openhome.date.split(' ')[2]}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="whatson-item">
                                  <a href={`/property/view/${openhome.listing.url}`}>
                                    <WhatsOnListing
                                      displayType={'small'}
                                      type={'openhome'}
                                      key={openhome.listing.id}
                                      date={openhome.dayOn}
                                      property={openhome.listing} />
                                  </a>
                                </div>
                              }
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>
                    <div className={`fa fa-chevron-circle-right whatson-prevnext-icon
                    ${props.page === props.pagesCount
                        ? 'prevnext-icon-disabled'
                        : ''}`}
                      onClick={() => props.setPage(props.page + 1)}
                      style={{ fontSize: '32px' }}>
                    </div>
                  </div>
                  : <div className="main-text center-text">
                    Currently no items available
                  </div>
                :
                props.nav === 'auctions' ?
                  props.previewAuctions.length ?
                    <div className="transition-slide-outer row">
                      <div className={`fa fa-chevron-circle-left whatson-prevnext-icon
                      ${props.page === 0
                          ? 'prevnext-icon-disabled'
                          : ''}`}
                        onClick={() => props.setPage(props.page - 1)}
                        style={{ fontSize: '32px' }}>
                      </div>
                      <div className="transition-slide-inner row"
                        style={{
                          width: (194 * (props.listingCount) + 'px'),
                          minWidth: (194 * (props.listingCount)) + 'px'
                        }}>
                        <div id="auction-transition" className="row"
                          style={{ left: ((props.page * (-194)) + 'px') }}>
                          {props.previewAuctions.map((auction, index) => {
                            return (
                              <div className="row" key={index}
                                onTouchMove={(e) => handleTouchMove(e)}
                                onTouchStart={(e) => handleTouchStart(e)}
                                onTouchEnd={(e) => handleTouchEnd(e)}>
                                {auction.isDateItem ?
                                  <div className='whatson-item'>
                                    <div className="whatson-listing-content whatson-date-item">
                                      <div className="whatson-date-text">
                                        <div className="whatson-date-sm">
                                          {auction.date.split(' ')[0]}
                                        </div>
                                        <div className="whatson-date-lg">
                                          {auction.date.split(' ')[1]}
                                        </div>
                                        <div className="whatson-date-sm">
                                          {auction.date.split(' ')[2]}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div className="whatson-item">
                                    <a href={`/property/view/${auction.listing.url}`}>
                                      <WhatsOnListing
                                        displayType={'small'}
                                        type={'auction'}
                                        key={auction.listing.id}
                                        date={auction.listing.date}
                                        property={auction.listing} />
                                    </a>
                                  </div>}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      <div className={`fa fa-chevron-circle-right whatson-prevnext-icon
                      ${props.page === props.pagesCount
                          ? 'prevnext-icon-disabled'
                          : ''}`}
                        onClick={() => props.setPage(props.page + 1)}
                        style={{ fontSize: '32px' }}>
                      </div>
                    </div>
                    : <div className="center-text">
                      Currently no items available
                    </div>
                  :
                  props.nav === 'new' &&
                    props.previewListings.length ?
                    <div className="transition-slide-outer row">
                      <div className={`fa fa-chevron-circle-left whatson-prevnext-icon
                       ${props.page === 0
                          ? 'prevnext-icon-disabled'
                          : ''}`}
                        onClick={() => props.setPage(props.page - 1)}
                        style={{ fontSize: '32px' }}>
                      </div>
                      <div className="transition-slide-inner row"
                        style={{
                          width: (194 * (props.listingCount)) + 'px',
                          minWidth: (194 * (props.listingCount)) + 'px'
                        }}>
                        <div id="newlisting-transition" className="row"
                          onTouchMove={(e) => handleTouchMove(e)}
                          onTouchStart={(e) => handleTouchStart(e)}
                          onTouchEnd={(e) => handleTouchEnd(e)}
                          style={{ left: ((props.page * (-194)) + 'px') }} >
                          {props.previewListings.map((listing, index) => {
                            return (
                              <a key={index} href={`/property/view/${listing.url}`}>
                                <WhatsOnListing
                                  displayType={'small'} key={listing.id} property={listing} />
                              </a>
                            )
                          })
                          }
                        </div>
                      </div>
                      <div className={`fa fa-chevron-circle-right whatson-prevnext-icon
                       ${props.page === props.pagesCount
                          ? 'prevnext-icon-disabled' : ''}`}
                        onClick={() => props.setPage(props.page + 1)}
                        style={{ fontSize: '32px' }}>
                      </div>
                    </div>
                    : <div className="center-text main-text">
                      Currently no items available
                    </div>
              }
            </div>
          </div>
          <div className="whatson-contact-row column">
            <div className="mobile-whatson-contact-info" onClick={() => props.openTel('+64 9 300 4001')}>
              <div className="fa-stack"
                style={{ marginRight: '5px' }}>
                <div className="fa fa-circle-thin fa-stack-2x"></div>
                <div className="fa fa-phone fa-stack-1x"></div>
              </div>
            +64 9 300 4001
          </div>
            {/* <Link to='/contact'> */}
              <div className="mobile-whatson-contact-info" onClick={() => openEmail('info@kellands.co.nz', isServer, `Contact Request`, "")}>
                <div className="fa fa-envelope-o fa-lg"
                  style={{ marginRight: '5px' }}></div>
              Email Enquiries
          </div>
            {/* </Link> */}
            <Link to={props.nav === 'new' ? '/property' : `/${props.nav}`}>
              <div className="whatson-viewall mobile-whatson-contact-info">
                <div className="row center-item">
                  <div className="fa fa-caret-right"
                    style={{
                      color: '#ec008c',
                      fontSize: '22px',
                      marginLeft: '-5px',
                      marginRight: '5px'
                    }}></div>
                  <div className="center-text center-item whatson-text">
                    {props.nav === 'openhomes'
                      ? 'View All Open Homes'
                      : props.nav === 'new'
                        ? 'View All New Listings'
                        : props.nav === 'auctions'
                          ? 'View All Auctions'
                          : ''}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div >
    )
  } else {
    return (<div />)
  }
}

export default WhatsOnMobile
