import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"

import Slideshow from './Slideshow.jsx'

import { getData } from './api.js'
import WhatsOn from './WhatsOn.jsx'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

const Home = props => {

  const [featured1, setFeatured1] = useState([])
  const [featured2, setFeatured2] = useState(null)

  useEffect(() => {
    let featureUrl = `properties?featured=true&status=live`
    getData(featureUrl, (data) => handleFeatured(data))
  }, [])

  const handleFeatured = (featuredList) => {
    if (featuredList.length) {
      if (featuredList.length > 1) {
        let end = featuredList.length - 1
        let first = Math.floor((Math.random() * (end - 0 + 1) + 0))
        let second = randomExcluded(0, end, first);
  
        let featured1Slideshow = handleSlideshowData(featuredList[first])
        let featured2Slideshow = handleSlideshowData(featuredList[second])
  
        setFeatured1(featured1Slideshow)
        setFeatured2(featured2Slideshow)
  
      } else {
        let featured1Slideshow = handleSlideshowData(featuredList[0])
        setFeatured1(featured1Slideshow)
      }
    } 
  }

  const randomExcluded = (start, end, excluded) => {
    var n = excluded
    // eslint-disable-next-line
    while (n == excluded)
      n = Math.floor((Math.random() * (end - start + 1) + start));
    return n;
  }

  const handleSlideshowData = (d) => {
    let newData = {}
    newData.bedrooms = d.bedrooms
    newData.bathrooms = d.bathrooms
    newData.images = []
    newData.showAddress = d.showAddress

    d.images.sort((a, b) => {
      return a.position - b.position;
    })

    for (let i = 0; i < 7; i++) {
      d.images.map(img => {
        if (img.position === i + 1) {
          let url = `${cloudFrontURL}/properties/${d.id}/images/${img.storageKey}`
          newData.images.push(url)
        }
        return true
      })
    }

    newData.garages = d.garages
    newData.offstreetParking = d.offstreetParking
    newData.address = d.address
    newData.mainHeading = d.mainHeading.replace("&amp;", "&")
    newData.floorArea = d.floorArea
    newData.landArea = d.landArea
    newData.url = d.url
    return newData
  }

  return (
    <div className="home-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kellands Real Estate | Kellands Real Estate</title>
        <meta property="og:title" content="Kellands Real Estate | Kellands Real Estate" />
        <meta name="description"
          content="Houses, Apartments and Townhouses for sale in Auckland. Freemans Bay, Ponsonby, St Mary’s Bay, Herne Bay, Westmere, Grey Lynn, Parnell, Waiheke Island." />
        <meta property="og:description"
          content="Houses, Apartments and Townhouses for sale in Auckland. Freemans Bay, Ponsonby, St Mary’s Bay, Herne Bay, Westmere, Grey Lynn, Parnell, Waiheke Island." />
        <meta property="og:image" content={`${process.env.RAZZLE_PUBLIC_DIR + '/K-triangles.png'}`} /> 
      </Helmet>
      <Slideshow data={featured1} propertyPage={false} />
      <WhatsOn />
      {featured2 ?
        <Slideshow data={featured2} propertyPage={false} />
      : <div />}
    </div>
  )
}

export default Home